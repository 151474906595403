import React, { Component } from 'react';
import HeaderIndex from "../../components/support/header";
import FooterIndex from "../_includes/footer/FooterSanaHeader";
import Spinner from '../utils/Spinner'
import Cart from "../_includes/lastProduits/Cart"
import User from "../_includes/login/Login"
import { getPages } from '../../actions/pagesAction'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet';
import $ from 'jquery';
import Slider from "react-slick";

class About extends Component {
	componentDidMount() {
		$('html,body').animate({
			scrollTop: 0
		}, 'slow');

		this.props.getPages()
		$("body.body").remove("body-lock");

		$(".cart").on('click', function () {
			$("header .top-header").toggleClass("dark");
			return false;
		});
		$(".account, .account-offine").on('click', function () {
			$(".top-header").toggleClass("dark");
			return false;
		});
		$(".menu").on('click', function () {
			$(".top-header").toggleClass("dark");
			return false;
		});
		$('li a.Menu').addClass("activeMenu")
		const s = document.createElement('script');
		s.type = 'text/javascript';
		s.async = true;
		s.innerHTML = `
        jQuery(function () {
        $(".menu-my-account").idTabs({});
      });
    `;
		document.body.appendChild(s);
	}
	render() {
		const { pages } = this.props;
		let displayFooter;
		let displayContent, content, displayCareer, displayPeople, displayChrono, titleAbout, titleCareer, titlePeople, titleChrono;
		const settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
		};
		if (pages.pageData === undefined || pages.pageData === null || pages.loading) {
			displayContent = <Spinner />
			displayCareer = <Spinner />
			displayPeople = <Spinner />
			displayChrono = <Spinner />
		} else {
			let foundBanners = false;
			let foundFooter = false;
			let foundContent = false;

			displayFooter = pages && pages.pageData.map((page, i) => {
				if (pages.slug !== null) {
					let founded = (page.slug.indexOf("support-about") > -1);
					if (founded) {
						foundFooter = true;
						return (
							<FooterIndex
								key={i}
								footer={page}
							/>
						)
					}
				}
			})
			displayContent = pages && pages.pageData.map((page, i) => {

				if (pages.slug !== null) {
					let founded = (page.slug.indexOf("support-about") > -1)
					if (founded) {
						let description = page.content;
						titleAbout = (
							<div className="top-list col-md-12 container-center bg-blanc no-padd padd-b60" dangerouslySetInnerHTML={{ __html: page.about_us.text.replace("<p>", "").replace("</p>", "") }}>

							</div>
						)
						titleCareer = (
							<div className="top-list col-md-12 container-center bg-blanc no-padd padd-b60" dangerouslySetInnerHTML={{ __html: page.career.text.replace("<p>", "").replace("</p>", "") }}>

							</div>
						)
						titlePeople = (
							<div className="top-list col-md-12 container-center bg-blanc no-padd padd-b60" dangerouslySetInnerHTML={{ __html: page.people.text.replace("<p>", "").replace("</p>", "") }}>

							</div>
						)
						titleChrono = (
							<div className="top-list col-md-12 container-center bg-blanc no-padd padd-b60" dangerouslySetInnerHTML={{ __html: page.chronology.text.replace("<p>", "").replace("</p>", "") }}>

							</div>
						)

						content = page.about_us && page.about_us.content_fluide && page.about_us.content_fluide.map((item) =>
							item.acf_fc_layout === "text_content" ?
								item.content.map((result) => {
									return (<div className="col-md-10 container-center">

										<div className="col-md-5 float-left">
											<p dangerouslySetInnerHTML={{ __html: result.title }}></p>
										</div>
										{
											result && result.text && result.text.map((txt) => {
												return (<div className="col-md-7 float-left">
													<p dangerouslySetInnerHTML={{ __html: txt.content }}></p>
												</div>)
											})
										}
									</div>)

								})

								:
								/* item.acf_fc_layout == "image" ?
								<div className="col-md-12 short-story bg-noir">
									<div className="col-md-12 img-auto">
										<img src={item.image} alt="Welt" />
									</div>
								</div>
								: */
								item.acf_fc_layout === "slider" ?
									<div className="col-md-12 block-slide-image">
										<Slider {...settings}>
											{item && item.images & item.images.map((image, i) => {
												return (
													<img src={image} width="100%" />
												)
											}
											)}

										</Slider>
									</div>
									:
									""
						)
						displayCareer = page.career && page.career.content_fluide && page.career.content_fluide.map((item) =>
							item.acf_fc_layout === "text_content" ?
								item.content.map((result) => {
									return (<>
										<div className="col-md-5 float-left">
											<p dangerouslySetInnerHTML={{ __html: result.title }}></p>
										</div>
										{
											result.text.map((txt) => {
												return (<div className="col-md-7 float-left">
													<p dangerouslySetInnerHTML={{ __html: txt.content }}></p>
												</div>)
											})
										}
									</>)

								})

								:
								item.acf_fc_layout === "slider" ?
									<div className="col-md-12 block-slide-image">
										<Slider {...settings}>
											{item.images.map((image, i) => {
												return (
													<img src={image} width="100%" />
												)
											}
											)}

										</Slider>
									</div>
									:
									""
						)
						displayPeople = page.people && page.people.content_fluide && page.people.content_fluide.map((item) =>
							item.acf_fc_layout === "text_content" ?
								item.content.map((result) => {
									return (<>
										<div className="col-md-5 float-left">
											<p dangerouslySetInnerHTML={{ __html: result.title }}></p>
										</div>
										{
											result.text.map((txt) => {
												return (<div className="col-md-7 float-left">
													<p dangerouslySetInnerHTML={{ __html: txt.content }}></p>
												</div>)
											})
										}
									</>)

								})

								:
								item.acf_fc_layout === "slider" ?
									<div className="col-md-12 block-slide-image">
										<Slider {...settings}>
											{item.images.map((image, i) => {
												return (
													<img src={image} width="100%" />
												)
											}
											)}

										</Slider>
									</div>
									:
									""
						)
						displayChrono = page.chronology && page.chronology.content_fluide && page.chronology.content_fluide.map((item) =>
							item.acf_fc_layout === "text_content" ?
								item.content.map((result) => {
									return (<>
										<div className="col-md-5 float-left">
											<p dangerouslySetInnerHTML={{ __html: result.title }}></p>
										</div>
										{
											result.text.map((txt) => {
												return (<div className="col-md-7 float-left">
													<p dangerouslySetInnerHTML={{ __html: txt.content }}></p>
												</div>)
											})
										}
									</>)

								})
								:
								item.acf_fc_layout === "slider" ?
									<div className="col-md-12 block-slide-image">
										<Slider {...settings}>
											{item.images.map((image, i) => {
												return (
													<img src={image} width="100%" />
												)
											}
											)}

										</Slider>
									</div>
									:
									""
						)
					}

				}

			})

			if (!foundFooter) {
				displayFooter = <FooterIndex />
			}
		}

		return (
			<>
				<Helmet>
					<title>About – WK®</title>
					<meta name="Support-About" content="About – WK®" />
				</Helmet>
				<HeaderIndex />

				<div className="col-md-12 clearfix scroll-top-div">
					<div className="col-md-10 container-center tab-list">
						<div className="menu-account">
							<ul className="d-flex-row menu-my-account">
								<li><a href="#about-us">About us</a></li>
								<li><a href="#career">Career</a></li>
								<li><a href="#people">People</a></li>
								<li><a href="#chronolgy">Chronology</a></li>
							</ul>
						</div>
					</div>
					{/* Informations and Specificities */}
					<div className="col-md-12 list-account" id="about-us">
						<div className="top-list col-md-10 container-center bg-blanc">
							<div>
								{titleAbout}
							</div>
							{content}
						</div>
					</div>
					{/* Informations and Specificities */}
					{/* Career  */}
					<div className="col-md-12 list-account" id="career">
						<div className="top-list col-md-10 container-center bg-blanc">
							{titleCareer}
						</div>
						<div className="col-md-10 container-center bg-blanc padding-t-50 padding-b-50">
							<div className="col-md-10 container-center">
								{displayCareer}
							</div>
						</div>
					</div>
					{/* Career  */}
					{/* People  */}
					<div className="col-md-12 list-account" id="people">
						<div className="top-list col-md-10 container-center bg-blanc">
							{titlePeople}
						</div>
						<div className="col-md-10 container-center bg-blanc padding-t-50 padding-b-50">
							<div className="col-md-10 container-center">
								{displayPeople}
							</div>
						</div>
					</div>
					{/* People  */}
					{/* Chronolgy  */}
					<div className="col-md-12 list-account" id="chronolgy">
						<div className="top-list col-md-10 container-center bg-blanc">
							{titleChrono}
						</div>
						<div className="col-md-10 container-center bg-blanc padding-t-50 padding-b-50">
							<div className="col-md-10 container-center">
								{displayChrono}
							</div>
						</div>
					</div>
					{/* Chronolgy  */}
				</div>
				<Cart />
				<User />
				<FooterIndex />
			</>)
	}
}
About.propTypes = {
	getPages: PropTypes.func.isRequired,
}
const mapStateToprops = (state) => ({
	pages: state.pages,
})

export default connect(mapStateToprops, { getPages })(About)
