export const sort = (items) => {
	return items.sort((a, b) => a.id - b.id)
}
// Save redux's state in local storage
export function saveToLocalStorage(state) {
	try {
		const serializedState = JSON.stringify(state)
		localStorage.setItem('state', serializedState)
	} catch (error) {
		console.log(error)
	}
}

export function loadFromLocalStorage() {
	try {
		const serializedState = localStorage.getItem('state')
		if (serializedState === null) return undefined
		return JSON.parse(serializedState)
	} catch (error) {
		console.log(error)
		return undefined
	}
}

export function getCurrentDate(separator = '') {

	let newDate = new Date()
	let date = newDate.getDate();
	let month = newDate.getMonth() + 1;
	let year = newDate.getFullYear();

	return newDate / 1000
}

export default sort