import React, { Component } from 'react';
import HeaderIndex from "./header";
import FooterIndex from "../_includes/footer/FooterSanaHeader";
import Spinner from '../utils/Spinner'
import Cart from "../_includes/lastProduits/Cart"
import User from "../_includes/login/Login"
import { getPages } from '../../actions/pagesAction'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet';
import $ from 'jquery';

class Informations extends Component {
	componentDidMount() {
		$('html,body').animate({
      scrollTop: 0
    }, 'slow');

		this.props.getPages()

		$(".cart, .account, .account-offine, .menu").on('click', function () {
			$(".top-header").addClass("dark")
			return false
		})
		$('li a.Menu2').addClass("activeMenu")

		const s = document.createElement('script');
		s.type = 'text/javascript';
		s.async = true;
		s.innerHTML = `
        jQuery(function () {
        $(".menu-my-account").idTabs({});
      });
    `;
		document.body.appendChild(s);

	}
	render() {
		const { pages } = this.props;
		var displayFooter;
		var displayContent, content, displayTerm, displayStockist, displayColophon;

		if (pages.pageData === undefined || pages.pageData === null || pages.loading) {
			displayContent = <Spinner />
			displayTerm= <Spinner />
			displayStockist= <Spinner />
			displayColophon= <Spinner />
		} else {
			let foundFooter = false;
			displayFooter = pages && pages.pageData.map((page, i) => {
				if (pages.slug !== null) {
					let founded = (page.slug.indexOf("support-information") > -1);
					if (founded) {
						foundFooter = true;
						return (
							<FooterIndex
								key={i}
								footer={page}
							/>
						)
					}
				}
			})

			displayContent = pages && pages.pageData.map((page, i) => {

				if (pages.slug !== null) {
					let founded = (page.slug.indexOf("support-information") > -1)
					if (founded) {
							content= page.info.map((item)=>{
								return(
									<>
									<div className="content-informations">
										<div className="col-md-5 float-left">
											<p dangerouslySetInnerHTML={{__html: item.title}}></p>
										</div>
										<div className="col-md-7 float-left">
											{item.text.map((text)=> <p dangerouslySetInnerHTML={{__html: text.content}}></p>)}
										</div>
									</div>
									</>
								)

						})

							displayTerm= page.terms_and_conditions.map((item)=>{
								return(
									<>
									<div className="content-informations">
										<div className="col-md-5 float-left">
											<p dangerouslySetInnerHTML={{__html: item.title}}></p>
										</div>
										<div className="col-md-7 float-left">
											{item.text.map((text)=> <p dangerouslySetInnerHTML={{__html: text.content}}></p>)}
										</div>
									</div>
									</>
								)

						})

							displayStockist= page.stockist.map((item)=>{
								return(
									<>
									<div className="content-informations">
										<div className="col-md-5 float-left">
											<p dangerouslySetInnerHTML={{__html: item.title}}></p>
										</div>
										<div className="col-md-7 float-left">
											{item.text.map((text)=> <p dangerouslySetInnerHTML={{__html: text.content}}></p>)}
										</div>
									</div>
									</>
								)

						})

							displayColophon= page.colophon.map((item)=>{
								return (
									<>
									<div className="content-informations">
										<div className="col-md-5 float-left">
											<p dangerouslySetInnerHTML={{__html: item.title}}></p>
										</div>
										<div className="col-md-7 float-left">
											{item.text.map((text)=> <p dangerouslySetInnerHTML={{__html: text.content}}></p>)}
										</div>
									</div>
									</>
								)

						})
						let description = page.info;
					}
				}
			})

		}

		return (
			<>
				<Helmet>
					<title>Informations – WK®</title>
					<meta name="Support-Informations" content="Informations – WK®" />
				</Helmet>
				<HeaderIndex />
				<div className="col-md-12 clearfix scroll-top-div">
					<div className="col-md-10 container-center tab-list">
						<div className="menu-account">
							<ul className="d-flex-row menu-my-account">
								<li><a href="#info">Info</a></li>
								<li><a href="#terms_Conditions">Terms &amp; Conditions</a></li>
								<li><a href="#stockist">Privacy policy</a></li>
								<li><a href="#colophon">Colophon</a></li>
							</ul>
						</div>
					</div>
					{/* Informations and Specificities */}
					<div className="col-md-12 list-account" id="info">
						<div className="col-md-10 container-center bg-blanc padding-t-50 padding-b-50">
							<div className="col-md-10 container-center">
								{content}
							</div>
						</div>
					</div>
					{/* Informations and Specificities */}

					{/* Terms & Conditions */}
					<div className="col-md-12 list-account" id="terms_Conditions">
						<div className="col-md-10 container-center bg-blanc padding-t-50 padding-b-50">
							<div className="col-md-10 container-center">
								{displayTerm}
							</div>
						</div>

					</div>
					{/* Terms & Conditions */}



					{/* Stockist */}
					<div className="col-md-12 list-account" id="stockist">
						<div className="col-md-10 container-center bg-blanc padding-t-50 padding-b-50">
							<div className="col-md-10 container-center">
								{displayStockist}
							</div>

						</div>
					</div>
					{/* Stockist */}


					{/* Colophon */}
					<div className="col-md-12 list-account" id="colophon">
						<div className="col-md-10 container-center bg-blanc padding-t-50 padding-b-50">
							<div className="col-md-10 container-center">
								{displayColophon}
							</div>
						</div>
					</div>
					{/* Colophon */}
				</div>


				<FooterIndex />
				<Cart />
				<User />
			</>)
	}
}
Informations.propTypes = {
	getPages: PropTypes.func.isRequired,

}
const mapStateToprops = (state) => ({
	pages: state.pages,

})

export default connect(mapStateToprops, { getPages })(Informations)
