import { PAYMENT_LOADING, GET_PAYMENT } from "./types";
import API, { subUrl } from "../utils/api";
export const getPayment = () => (dispatch) => {
  dispatch(setPaymentLoading());
  API.get(subUrl.getPublishableKey)
    .then((res) => {
      dispatch({
        type: GET_PAYMENT,
        payload: res.data,
      });
    })
    .catch((error) => console.log(error));
};
export const setPaymentLoading = () => {
  return {
    type: PAYMENT_LOADING,
  };
};
