import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PropTypes } from "prop-types";
import React, { Component } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.min.css';
import { changeAddress, deleteAdress, editAdress, getAdresses, newAdress } from '../../../actions/adressesActions';
import { newCheckout } from '../../../actions/checkoutsActions';
import { getCurrencyData } from '../../../actions/currencyAction';
import { getPayment } from '../../../actions/paymentAction';
import CheckoutForm from './CheckoutForm';






const InjectedCheckoutForm = () => (
  <ElementsConsumer>
    {({ stripe, elements, cart }) => (
      <CheckoutForm stripe={stripe} elements={elements} cart={cart} />
    )}
  </ElementsConsumer>
);

Modal.setAppElement('#root')
class Checkout extends Component {
  constructor(props) {
    super(props)
  }
  


  render() {
    const {  payment } = this.props;
    let stripePromise = null;
      if(payment !== null && !payment.loading ){
        stripePromise = loadStripe(payment.payment.stripe_publishable_key);
        }
   
    return (
      <Elements stripe={stripePromise} cart='ok' >
          <InjectedCheckoutForm cart='ok' />
      </Elements>
    )
  }

}

Checkout.protoTypes = {
	getPayment: PropTypes.func.isRequired
};
const mapStateToProps = (state) => ({
  cart: state.cart,
  adresses: state.adresses,
  auth: state.auth,
  checkedAddress: state.adresses.checkedAddress,
  currencies    : state.currencies,
  payment       : state.payment,
  orders        : state.checkouts,
})
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item) => {
      dispatch({ type: 'ADD', payload: item })
    },
    removeFromCart: (item) => {
      dispatch({ type: 'REMOVE', payload: item })
    },
    removeAllFromCart: (item) => {
      dispatch({ type: 'REMOVE_ALL', payload: item })
    },
    getAdresses: () => dispatch(getAdresses()),
    getCurrencyData: () => dispatch(getCurrencyData()),
    initializeCarte: () => dispatch({ type: 'INIALIZE_CART' }),
    getPayment : () => dispatch(getPayment()),
    setOnSuccess: (item) => {
			dispatch({ type: 'SET_ON_SUCESS', payload: item })
			
		},
    changeAddress: (address) => dispatch(changeAddress(address)),
    newAdress: (adressData) => newAdress(adressData),
    newCheckout: (checkoutData) => dispatch(newCheckout(checkoutData)),
    editAdress: (adressData) => editAdress(adressData),
    deleteAdress: (index, adress) => dispatch(deleteAdress(index, adress))

  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Checkout)
