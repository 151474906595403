export default {
  en: {
    "home.welcome"         : "Hello",
    "home.newrelease"      : "New release",
    "home.Camouflage"      : "Camouflage",
    "home.Scroll"          : "Scroll to discover",
    "home.new"             : "New",
    "home.link.more"       : "See more",
    "home.link.showmore"   : "Show me more",
    "home.menu.shop"       : "Shop",
    "home.menu.journal"    : "journal",
    "home.menu.typeface"   : "typeface",
    "home.Currency"        : "Currency"  ,
    "error.required"       : "Please enter the required fields",
    "error.valid"          : "Please enter a valid fields",
    "error.phone"          : "Please enter a valid phone number",
    "error.email"          : "Please enter a valid email address",
    "error.password"       : "Password must be contain at least 6 characters",
    "error.confirmPassword": "password confirm does not match",
  },
  fr: {
    "home.welcome"         : "Bonjour",
    "home.newrelease"      : "Nouvelle version",
    "home.Camouflage"      : "Camouflage",
    "home.Scroll"          : "Défilez pour découvrir",
    "home.new"             : "Nouveau",
    "home.link.more"       : "Voir plus",
    "home.link.showmore"   : "Montre m'en plus",
    "home.menu.shop"       : "Boutique",
    "home.menu.journal"    : "journal",
    "home.Currency"        : "Devise",
    "error.required"       : "Veuillez remplir tous les champs",
    "error.valid"          : "Les données saisies ne sont pas valides",
    "error.email"          : "adresse email n'est pas valide",
    "error.password"       : "Votre mot de passe doit comporter 6 caractères minimum",
    "error.confirmPassword": "Mots de passes non identiques",  
  },
  it: {
    "home.welcome"      : "Buongiorno",
    "home.newrelease"   : "Nuova versione",
    "home.Camouflage"   : "Camuffare",
    "home.Scroll"       : "Scorri per scoprire",
    "home.new"          : "nuovo",
    "home.link.more"    : "Vedi altro",
    "home.link.showmore": "Fammi vedere di piu",
    "home.menu.shop"    : "Negozio",
    "home.menu.journal" : "rivista",
  },
  de: {
    "home.welcome"      : "Hallo",
    "home.newrelease"   : "Nieuwe uitgave",
    "home.Camouflage"   : "Camouflage",
    "home.Scroll"       : "Scroll om te ontdekken",
    "home.new"          : "nieuwe",
    "home.link.more"    : "Bekijk meer",
    "home.link.showmore": "Toon mij meer",
    "home.menu.shop"    : "Winkel",
    "home.menu.journal" : "dagboek",
  }

  
  };
  