import React, { Component } from 'react';
import { BrowserRouter as  Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

class FullPost extends Component {
	render() {
		const post = this.props.post
		return (
			<div className="articles col-md-12 relative">
				<Link to={{
					pathname: `/newspaper/${post.slug}`,
					post
				}}>
					<div className="img-window">
						{post != null &&
							<img src={post.featured_image.url} alt={post.featured_image.alt} />
						}
					</div>
					<div className="book-articles col-md-12">
						<div className="col-md-10 container-center">
							<div className="book">
								<div className="content">
									<div className="title">{post != null && post.title}</div>
									{post != null && <div dangerouslySetInnerHTML={{ __html: post.excerpt }}></div>}
								</div>
								<FormattedMessage id="home.link.more" defaultMessage="See more"/> ↗
              </div>
						</div>
					</div>
				</Link>
			</div>
		);
	}
}

export default FullPost;
