import React from 'react';
import './Modal.css';
const modal = (props) => {
	return (
		<div className={props.show ? "modal-wrapper active" : " modal-wrapper inactive"}>
			<div className="modal-content">
				{props.children}
			</div>
		</div>

	)
}
export default modal;