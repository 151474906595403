import axios from 'axios';
import $ from 'jquery';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changePassword, verifyUser } from '../../../actions/authActions';
import Card from '../../_includes/lastProduits/Cart';

class ResetPassword extends Component {
	constructor() {
		super();
		this.state = {
			username : '',
			key      : '',
			response : null,
			password : '',
			password2: '',
		}
	}
	async componentDidMount() {
		$('html,body').animate({
      scrollTop: 0
    }, 'slow');

		const newUser = {
			key: this.props.match.params.key,
			username: this.props.match.params.user
		}
		const { data } = await axios.post(`${process.env.REACT_APP_BaseURL}/wp-json/custom-routes/v1/password/reset`, newUser)
		this.setState(({
			response: data
		}))
		console.log("responseeess",newUser)
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}
	onSubmit = (e) => {
		e.preventDefault();
		const newUser = {
			password : this.state.password,
			password2: this.state.password2,
			key      : this.props.match.params.key,
			username : this.props.match.params.user
		}

		if (this.state.password !== this.state.password2) {
			alert('password confirm does not match')
			return
		} else {
			this.props.changePassword(newUser)
			this.props.history.push('/home');
		}
	}

	render() {
		let change;
		console.log(this.state.response)
		 if (this.state.response != null && this.state.response.status) {
			if (this.state.response.status == 200) {
				console.log(this.state.response)
				change = (
					<div className="simple-form">
						<div className="label">{this.props.match.params.id}</div>
						<form onSubmit={this.onSubmit} className="identification">
							<div className="form-text">
								<input type="password" placeholder="Password" name="password" onChange={this.onChange}/>
								<input type="password" placeholder="Repeat Password" name="password2" onChange={this.onChange}/>
							</div>
							<div className="form-submit">
								<button type="submit" >Save</button>
							</div>
						</form>
					</div>
				)
			}
			else {
				this.props.history.push('/home');
			}
		}

		return (
			<div className="reset-password">
				{change}
				<Card/>
			</div>
		);
	}
}

ResetPassword.protoTypes = {
	verifytUser: PropTypes.func.isRequired,
	changePassword: PropTypes.func.isRequired,
}
const mapStateToProps = (state) => ({
	auth: state.auth,
})

const mapDispatchToProps = (dispatch) => {
	return {
		verifyUser: (newUser) => dispatch(verifyUser(newUser))
	}
}
export default connect(mapStateToProps, { mapDispatchToProps, changePassword })(ResetPassword)
