
import {GET_PRODUCTS,GET_PRODUCTS_PAGE,PRODUCT_LOADING,GET_PRODUCT_BY_ID, ADD_PRODUCT_ON_PRODUCTS_PAGE} from '../actions/types'

const initialState = { 
    products: null,
    product:null,
    loading: false,
    someProducts: null,
    total:0
}

export default function (state= initialState, action)  {
    switch (action.type) {
        case PRODUCT_LOADING:
            return {
                ...state,
                loading: true
            };
        case GET_PRODUCTS:{
            return {
                ...state,
                products: action.payload,
                loading: false
            };  } 
        case GET_PRODUCTS_PAGE:
            return{
                ...state,
                someProducts: action.payload.data,
                products: action.payload.data,
                total : action.payload.headers["x-wp-total"],
                loading: false
            }; 
        case ADD_PRODUCT_ON_PRODUCTS_PAGE:
                return{
                    ...state,
                    someProducts: action.payload.data,
                    products: action.payload.data,
                    total : action.payload.headers["x-wp-total"],
                    loading: false
                };    
        case GET_PRODUCT_BY_ID:
            return{
                ...state,
                product:action.payload,
                loading:false
            }    
        default: 
           return state
    }

}