import axios from 'axios';

export default axios.create({
	baseURL: `${process.env.REACT_APP_BaseURL}`,
});

export const subUrl = {
	adresses      : `/wp-json/custom-routes/v1/customer/addresses`,
	updateAdress  : `/wp-json/custom-routes/v1/customer/addresses`,
	updateUser    : `/wp-json/custom-routes/v1/customer/profil`,
	register      : '/wp-json/jwt-auth/v1/register',
	reset         : '/wp-json/jwt-auth/v1/forget-password',
	login         : '/wp-json/jwt-auth/v1/token',
	banners       : '/wp-json/custom-routes/v1/banners',
	categories    : '/wp-json/custom-routes/v1/product/categories',
	orders        : '/wp-json/custom-routes/v1/customer/orders',
	customer      : '/wp-json/custom-routes/v1/customer',
	currencies    : '/wp-json/custom-routes/v1/currencies/supported',
	fouters       : '/wp-json/custom-routes/v1/footers',
	headers       : '/wp-json/custom-routes/v1/pages',
	posts         : '/wp-json/custom-routes/v1/posts',
	products      : '/wp-json/custom-routes/v1/products',
	productsOnPage: '/wp-json/custom-routes/v1/products?amount=',
	socialmedias  : '/wp-json/custom-routes/v1/social-media',
	faq           : '/wp-json/custom-routes/v1/faq',
	sendOrder     : '/wp-json/custom-routes/v1/customer/orders',
	payOrder: '/wp-json/custom-routes/v1/customer/orders/checkout',
	paymentIntent     : '/wp-json/custom-routes/v1/customer/payment/intent',
	version       : '/wp-json/custom-routes/v1/version',
	postCategories: '/wp-json/custom-routes/v1/posts/categories',
	verify        : '/wp-json/custom-routes/v1/password/reset',
	newPassword   : '/wp-json/custom-routes/v1/password/new',
	newParrainage : '/wp-json/custom-routes/v1/parrainage/user',
	pays          : '/wp-json/custom-routes/v1/pays/list',
	vignette      : '/wp-json/custom-routes/v1/vignette',
	status        : '/wp-json/custom-routes/v1/status',
	zones         : '/wp-json/custom-routes/v1/zones',
	brands         : '/wp-json/custom-routes/v1/brands',
	peoples         : '/wp-json/custom-routes/v1/peoples',
	tags         : '/wp-json/custom-routes/v1/tags',
	shipping      : '/wp-json/custom-routes/v1/shipping',
	updateOrders  : '/wp-json/custom-routes/v1/customer/shipping',
	customerPoint  : '/wp-json/custom-routes/v1/customer/points',
	getFavorit      : '/wp-json/custom-routes/v1/customer/favorits',
	postFavorit      : '/wp-json/custom-routes/v1/customer/favorits',
	getOrder      : '/wp-json/custom-routes/v1/customer/order',
	getDownload      : '/wp-json/custom-routes/v1/customer/orders/download',
	getInvoice     : '/wp-json/custom-routes/v1/customer/orders/invoice',
	getPrimePoints      : '/wp-json/custom-routes/v1/prime/points',
	putOrder      : '/wp-json/custom-routes/v1/customer/orders',
	getPublishableKey : '/wp-json/custom-routes/v1/publishable/key',
	getProductLength : '/wp-json/custom-routes/v1/products/total'
}