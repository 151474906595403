import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducers from './reducers'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'


  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ["auth", "cart", "favorites", "translation","currencies", "checkouts", "currentProduct", "version"]
  }
  
  const persistedReducer = persistReducer(persistConfig, rootReducers)
  
  export default () => {
    let composeTools = compose(applyMiddleware(thunk))  

    if (process.env.NODE_ENV == 'development' ) {
        composeTools = compose(
            applyMiddleware(thunk),
            // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )  
    }

    let store = createStore(persistedReducer, {}, composeTools)
    let persistor = persistStore(store)
    return { store, persistor }
}
