import React, { Component } from 'react';
import { getProducts, getTypeFaceOnPage } from '../../actions/productsActions';
import { connect } from 'react-redux';

import ProductPaginate from '../_includes/lastProduits/productPaginate';
import Modal from '../_includes/lastProduits/modal/Modal';
import isEmpty from '../../validation/is-empty';
import OverviewProduct from '../_includes/lastProduits/OverviewProduct'
import HeaderPage from "../_includes/headersPage/HeaderPage"
import FooterIndex from "../_includes/footer/FooterIndex";
import Spinner from '../utils/Spinner'
import HeaderIndexTypefaces from "../_includes/header/IndexTypafaces";
import { getPages } from '../../actions/pagesAction';
import { getCategories } from '../../actions/categorieAction'
import LoginIndex from "../_includes/login/Login";
import Cart from "../_includes/lastProduits/Cart"
import { Helmet } from 'react-helmet';
import IndexSection from '../indexSection';
import $ from 'jquery'

class TypeFaceShop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: '',
      isShowing: false,
      value: 10,
      loadModal: false,
      filtre: '',
      color: ''
    };
  }

  componentDidMount() {
    $('html,body').animate({
      scrollTop: 0
    }, 'slow');
    $("body").removeClass("body-lock")
    $(".top-header").removeClass("dark")
    this.props.getCategories();
    this.props.getPages()
    this.props.getTypeFaceOnPage(4, 1, 'all')

    $(".layout-2").on('click', function () {
      $('.view-produits').removeClass("view-layout-4");
      $('.view-produits').addClass("view-layout-2");
      return false;
    });

    $(".layout-4").on('click', function () {
      $('.view-produits').remove("view-layout-2");
      $('.view-produits').addClass("view-layout-4");
      return false;
    });


    $(".account, .account-offine").on('click', function () {
      $(".top-header").toggleClass("dark");
      return false;
    });
    $(".menu").on('click', function () {
      $(".top-header").toggleClass("dark");
      return false;
    });
  }
  selected = (event) => {
    this.setState(({
      fitre: '3'
    }))
    this.props.getTypeFaceOnPage(4, 1, event.target.value)
  }

  openModalHandler = () => {
    document.body.classList.add("no-sroll")
    this.setState({
      isShowing: true, loadModal: true
    });

    setTimeout(() => {
      this.setState({ loadModal: false })
    }, 1000);

  }

  closeModalHandler = () => {

    this.setState({
      isShowing: false
    });
    document.body.classList.remove("no-sroll")

  }
  _displayFavoriteImage(product) {
    var sourceImage = require('../../assets/images/heart-like.png')
    if (this.props.favorites.favorites.findIndex(item => item.id === product.id) !== -1) {
      sourceImage = require('../../assets/images/heart-dislike.png')
    }
    return (
      <img
        src={sourceImage}
        width="17"
        height="17"
      />
    )
  }

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber })
    this.props.getTypeFaceOnPage(3, pageNumber);
  }

  componentDidUpdate(props, state) {
    if (state.filtre) {
      this.props.getTypeFaceOnPage(3, 3)
    }
  }


  render() {
    const { posts, pages } = this.props;
    let displayedHeaders;
    let displayFooter;
    let bannerView;

    const simplePost = [];
    const inlinePost = [];
    const fullPost = [];

    if (pages.pageData === undefined || pages.pageData === null || pages.loading) {
      displayedHeaders = <Spinner />
    } else {
      let foundHeader = false;
      let foundBanners = false;
      let foundFooter = false;
      displayedHeaders = pages && pages.pageData.map((page, i) => {
        if (pages.slug !== null) {
          const founded = (page.slug.indexOf("typeface") > -1);
          if (founded) {
            foundHeader = true;
            this.state.color = page.colorBackground
            return (
              <HeaderPage
                key={i}
                header={page}
                scroll={page.colorBackground}
              />
            )
          }
        }
      })
      displayFooter = pages.pageData.map((page, i) => {
        if (pages.slug !== null) {
          let founded = (page.slug.indexOf("typeface") > -1);
          if (founded) {
            foundFooter = true;
            return (
              <FooterIndex
                key={i}
                footer={page}
              />
            )
          }
        }
      })
      bannerView = pages.pageData.map((page, i) => {
        if (pages.slug !== null) {
          let founded = (page.slug.indexOf("typeface") > -1)
          if (founded) {
            if (page.banner_1.background_image != false) {
              foundBanners = true;
              return (
                <img key={i} src={page.banner_1.background_image.url} alt="Welt" />
              )
            }
          }
        }
      })

      if (!foundHeader) {
        displayedHeaders = <HeaderPage />
      }
      if (!foundFooter) {
        displayFooter = <FooterIndex />
      }
      if (!foundBanners) {
        bannerView = ""
      }
    }

    const { products, currentProduct } = this.props;
    let fullProduct, total;
    if (products.someProducts !== null) {
      total = products.total;
      fullProduct = products.someProducts.map((product, i) => {
        return (
          <ProductPaginate
            key={i}
            id={i}
            products={product}
            addToCart={this.props.addToCart}
            removeFromCart={this.props.removeFromCart}
            toggleFavorite={this.props.toggleFavorite}
            favorites={this.props.favorites.favorites}
            getCurentProduct={this.props.getCurentProduct}
            openModal={this.openModalHandler}
            cartItem={this.props.cart.cartItems.filter(cartItem => cartItem.id === product.id)[0]}
            currencyChecked={this.props.currencies.currencyChecked}
          />
        )
      })
    }

    const insideHeaderIndexTypefaces = (
      <>
        <div className="shop-top col-md-12 relative clearfix align-center">

        </div>
      </>
    );
    return (
      <>
        <Helmet>
          <title>TYPE.WELTKERN®</title>
          <meta name="Type Face" content="TYPE.WELTKERN®" />
        </Helmet>
        {displayedHeaders}
        <HeaderIndexTypefaces insideHeaderIndexTypefaces={insideHeaderIndexTypefaces} col={this.state.color} />
        <div className="menu-type-active"><IndexSection /></div>
        {/* //// Cacher ce bloc tant que OUR FONTS IN USE n'est pas terminé (priorité 2)
        <div class="bloc-type-page typefacetexte"><p class="text text--xmedium uppercase"><a href="#">Our fonts in use ↗</a></p></div>

        <div className="col-md-12 overflow-h align-center bg-corps">
            {fullPost.length > 0 &&
              fullPost.slice(1, 2).map((post, i) => {
                return (
                  <FullPost
                    key={i}
                    post={post}
                  />
                )
              }
              )
            }
          </div>
          <ProductsListing />
          <SimplePost />
          <div class="col-md-12 banner">
            <p class="text text--xmedium uppercase">submit your t.wk® fonts use and get w points ↗ </p>
          </div>

        <div className="col-md-12 clearfix">
          <div className="col-md-12 padding-t-b-l-r-8 overflow-h align-center view-produits view-layout-2" >
            {fullProduct}
          </div>


        </div>
        <div class="btn-shop-now"><span className="loadmore">Load more</span></div>
      */}


        <Modal
          className="modal"
          show={this.state.isShowing}
          close={this.closeModalHandler}>

          <div className="modal-body clearfix quick-purchase">
            <span className="close-modal-btn" onClick={this.closeModalHandler}>×</span>

            {(!isEmpty(currentProduct.currentProduct) && !this.state.loadModal) ?
              <OverviewProduct
                currentProduct={currentProduct.currentProduct}
                addToCart={this.props.addToCart}
                addRangeValueToReducer={this.props.addRangeValueToReducer}
                cart={this.props.cart}
                getFavorite={this.props.getFavorite}
                toggleFavorite={this.props.toggleFavorite}
                favorites={this.props.favorites.favorites}
                removeFromCart={this.props.removeFromCart}
                cartItem={this.props.cart.cartItems.filter(cartItem => cartItem.id === currentProduct.currentProduct.id)[0]}
                currencyChecked={this.props.currencies.currencyChecked}
              />
              : <Spinner />
            }
          </div>
        </Modal>
        {displayFooter}
        <LoginIndex />
        <Cart />
      </>)
  }
}
const mapStateToProps = (state) => {
  return ({
    cart: state.cart,
    countproducts: state.products,
    favorites: state.favorites,
    products: state.products,
    currentProduct: state.currentProduct,
    currencies: state.currencies,
    pages: state.pages,
    categories: state.categorie,
  })
}
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item) => {
      dispatch({ type: 'ADD', payload: item })
    },
    addRangeValueToReducer: (item) => {
      dispatch({ type: 'GET_RANGE_VALUE', payload: item })
    },
    removeFromCart: (item) => {
      dispatch({ type: 'REMOVE', payload: item })
    },
    getCurentProduct: (item) => {
      dispatch({ type: 'GET_CURRENT_PRODUCT', payload: item })
    },
    toggleFavorite: (item) => {
      dispatch({ type: 'TOGGLE_FAVORITE', payload: item })
    },
    getCategories: () => dispatch(getCategories()),
    getProducts: () => dispatch(getProducts()),
    getPages: () => dispatch(getPages()),
    //getFavorite: () => dispatch(getFavorite()),
    getTypeFaceOnPage: (perPage, paramater, order) => dispatch(getTypeFaceOnPage(perPage, paramater, order)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TypeFaceShop)
