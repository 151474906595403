import React, { Component } from "react";

export class Informations extends Component {
  constructor() {
    super();
    this.state = {
      emailEmpty: false,
      pwdEmpty: false,
      pwd2Empty: false,
      match: false,
      ValidMail: false,
      passLength: false,
      valid: false,
    };
  }

  _validateEmail(email) {
    var regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(String(email).toLowerCase());
  }
  continue = (e) => {
    e.preventDefault();
    const { values } = this.props;
    if (!values.email) {
      this.setState({ emailEmpty: true });
    }
    if (!values.password) {
      this.setState({ pwdEmpty: true });
    }
    if (!values.password2) {
      this.setState({ pwd2Empty: true });
    }
    if (!this._validateEmail(values.email)) {
      this.setState({ ValidMail: true });
    }
    if (values.password !== values.password2) {
      this.setState({ match: true });
    }
    if (values.password.length < 6) {
      this.setState({ passLength: true });
    }

    if (
      values.email &&
      values.password.length > 6 &&
      values.password === values.password2 &&
      this._validateEmail(values.email) &&
      values.password2 &&
      values.password
    )
      //console.log(values);
      this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  valid1 = () => {
    const { values, onChange } = this.props;
    console.log(values.email);
    //if(this.state.email && this.state.password && this.state.password2)
  };

  render() {
    const { values, onChange } = this.props;
    let valid = false;
    if (
      !values.email &&
      values.password.length < 6 &&
      values.password !== values.password2 &&
      !this._validateEmail(values.email) &&
      !values.password2 &&
      !values.password
    )
      valid = true;
    return (
      <div className="clearboth register__informations">
        <h4>1.Informations</h4>

        <div className="col-md-12">
          <div className="register__informations">
            <div className="form-wrapper informations__email col-md-12">
              <input
                defaultValue={values.email}
                required="required"
                type="email"
                id="email"
                name="email"
                className="form-text"
                placeholder="Your email address*"
                onChange={onChange}
                autoFocus="true"
              />
              <div className="erreur">
                {this.state.emailEmpty == false ? (
                  ""
                ) : (
                  <p>Please fill in this field</p>
                )}
              </div>
              <div className="erreur">
                {this.state.ValidMail == false ? (
                  ""
                ) : (
                  <p>Please enter a valid email address</p>
                )}
              </div>
            </div>
            <div className="form-wrapper informations__password col-md-12">
              <input
                defaultValue={values.password}
                required
                type="password"
                id="password"
                name="password"
                className="form-text"
                placeholder="Create a password*"
                onChange={onChange}
              />
              <div className="erreur">
                {this.state.pwdEmpty == false ? (
                  ""
                ) : (
                  <p>Please fill in this field</p>
                )}
              </div>
              <div className="erreur">
                {this.state.match == false ? (
                  ""
                ) : (
                  <p>Password confirm does not match</p>
                )}
              </div>
              <div className="erreur">
                {this.state.passLength == false ? (
                  ""
                ) : (
                  <p>Password must be contain at least 6 characters</p>
                )}
              </div>
            </div>
            <div className="form-wrapper informations__password--repeat col-md-12">
              <input
                defaultValue={values.password2}
                required="true"
                type="password"
                name="password2"
                id="password2"
                className="form-text"
                placeholder="Confirm your password*"
                onChange={onChange}
              />
              <div className="erreur">
                {this.state.pwd2Empty == false ? (
                  ""
                ) : (
                  <p>Please fill in this field</p>
                )}
              </div>
            </div>

            <button
              color="primary"
              class={`btnForm1 float-right ${
                !values.email || !values.password || !values.password2
                  ? "btnRegister"
                  : ""
              }`}
              variant="contained"
              onClick={this.continue}
              disabled={!values.email || !values.password || !values.password2}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Informations;
