import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import API, { subUrl } from '../../utils/api';

class SimplePost extends Component {
	constructor(props) {
		super(props)
		this.state = {
			id: null,
			type: '#'
		}
	}
	componentDidMount() {
		// if(this.props.post && this.props.post != undefined && this.props.post && this.props.post.external_url[0] && this.props.post.external_url[0].external_url.external_link == false)
		// API
		// .get(subUrl.products + '?id=' + this.props.post.external_url[0].external_url.interne.ID)
		// .then(res => {
		// 	this.setState({id : res.data[0].slug})
		// 	this.setState({type : res.data[0].choice_product})
		// })
		// .catch(error => console.log(error))
	}
	render() {
		let slug = '';
		let choice_product = '';
		let linkAcrtion='';
		let custom_url = true;
		const post = this.props.post
		if(this?.props?.post?.external_url?.post_url){
			slug = this?.props?.post?.external_url?.post_url?.post_name;
			choice_product = this?.props?.post?.external_url?.product_type;
			linkAcrtion = choice_product === "Typeface" ? '/shop/detail-typeface/' : '/shop/detail-product/'
			custom_url = false;
		}
		
		
		return (

			<div className={`articles simple  ${post.post_template} padding-l-r-4 display-inline-block`}>
				{custom_url ? 
				<a href={post.external_url}>
					<div className="img-l-full">
						{post != null &&
							<img src={post.featured_image.url} alt={post.featured_image.alt} />
						}
					</div>
					<div className="description">
						<div className="category-link">
							<div className="category">{post != null && post.categories.length > 0 ? post.categories[0].id.name : ''}</div></div>
						<h2 className="title-medium">{post != null && post.title}</h2>
						{post != null && <div dangerouslySetInnerHTML={{ __html: post.excerpt }}></div>}
					</div>
				</a>
				:
				<Link to={{
					pathname: `${linkAcrtion}${slug}` ,
				}}>
					<div className="img-l-full">
						{post != null &&
							<img src={post.featured_image.url} alt={post.featured_image.alt} />
						}
					</div>
					<div className="description">
						<div className="category-link">
							<div className="category">{post != null && post.categories.length > 0 ? post.categories[0].id.name : ''}</div></div>
						<h2 className="title-medium">{post != null && post.title}</h2>
						{post != null && <div dangerouslySetInnerHTML={{ __html: post.excerpt }}></div>}
					</div>
				</Link>
				}
			</div>
		);
	}
}

export default SimplePost;