

import React, { useEffect } from "react";
import styled from "styled-components";


const Font = ({ product, fontName, group, faceOptions, fontStyle, fontWeight }) => {
	useEffect(() => {
		if (product != null) {
			const font = product.typefaces.layout_1_opt
			const font2 = product.typefaces.layout_2_opt
			const font3 = product.typefaces.layout_3_opt
		//console.log("myprops", product)
		const s = document.createElement('script');
		s.type = 'text/javascript';
		s.async = true;
		s.innerHTML = `
		jQuery(function () {
			$(".menu-my-account").idTabs({});
			$('.show').closest('li').show();
			$(".hide").each(function () {
			$(this).closest('li').hide();
		})

			$(document).on("click", ".remove", function () {
				$(this).closest(".elts-style").remove();
			})

			$('.button-add').click(function () {
				//we select the box clone it and insert it after the box
				$('.box.template').clone()
					.show()
					.removeClass("template")
					.insertAfter(".box:last");

				jQuery("#slider-range-max").slider({
					range: "max",
					min: 0,
					max: 1000,
					value: 0,
					slide: function (event, ui) {
						jQuery("#amount").val(ui.value);
					}
				});
				jQuery("#amount").val(jQuery("#slider-range-max").slider("value"));

				jQuery("#font-size-range, #font-size-range2").slider({
					range: "max",
					min: 6,
					max: 120,
					value: 36,
					slide: function (event, ui) {
						var valFS = ui.value;
						var contentStyle = jQuery(this).parents('.elts-style').children('.content-style').children('p');
						contentStyle.css({
							"font-size": +valFS + "px"
						});
					}
				});

				jQuery(".premier #font-size-range").slider({
					range: "min",
					min: ${font?.font_size_min},
					max: ${font?.font_size_max},
					value: ${font?.font_size_default},
					step:1,
					slide: function (event, ui) {
						var valFS = ui.value;
						//console.log(ui.value);
						var contentStyle = jQuery(this).parents('.elts-style').children('.content-style').children('p');
						contentStyle.css({
							"font-size": +valFS + "px"
						});
					}
					

				});

				$('.premier #font-size-range, .premier #font-size-range').unbind('click');

				jQuery(".deuxieme #font-size-range2").slider({
					range: "max",
					min: ${font2?.font_size_min},
					max: ${font2?.font_size_max},
					value: ${font2?.font_size_default},
					step:1,
					slide: function (event, ui) {
						var valFS = ui.value;
						var contentStyle = jQuery(this).parents('.elts-style').children('.content-style').children('p');
						contentStyle.css({
							"font-size": +valFS + "px"
						});
					}
				});

				jQuery(".troisieme #font-size-range2").slider({
					range: "max",
					min: ${font3?.font_size_min},
					max: ${font3?.font_size_max},
					value: ${font3?.font_size_default},
					step:1,
					slide: function (event, ui) {
						var valFS = ui.value;
						var contentStyle = jQuery(this).parents('.elts-style').children('.content-style').children('p');
						contentStyle.css({
							"font-size": +valFS + "px"
						});
					}
				});

				jQuery("#letter-spacing-range, #letter-spacing-range2").slider({
					range: "max",
					min: 0,
					max: 1000,
					value: 500,
					step:1,
					slide: function (event, ui) {
						var valFS = ui.value / 100;
						var contentStyle = jQuery(this).parents('.elts-style').children('.content-style').children('p');
						contentStyle.css({
							"letter-spacing": +valFS + "px"
						});
					}
				});

				jQuery(".premier #letter-spacing-range").slider({
					range: "max",
					min: ${font?.letter_min * 10},
					max: ${font?.letter_max * 10},
					value: 0,
					step:1,
					slide: function (event, ui) {
						var valFS = ui.value / 100;
						var contentStyle = jQuery(this).parents('.elts-style').children('.content-style').children('p');
						contentStyle.css({
							"letter-spacing": +valFS + "px"
						});
					}
				});

				jQuery(".deuxieme #letter-spacing-range2").slider({
					range: "max",
					min: ${font2?.letter_min * 10},
					max: ${font2?.letter_max * 10},
					value: 0,
					step:1,
					slide: function (event, ui) {
						var valFS = ui.value / 100;
						var contentStyle = jQuery(this).parents('.elts-style').children('.content-style').children('p');
						contentStyle.css({
							"letter-spacing": + valFS + "px"
						});
					}
				});

				jQuery(".troisieme #letter-spacing-range2").slider({
					range: "max",
					min: ${font3?.letter_min * 10},
					max: ${font3?.letter_max * 10},
					value: 0,
					step:1,
					slide: function (event, ui) {
						var valFS = ui.value / 100;
						var contentStyle = jQuery(this).parents('.elts-style').children('.content-style').children('p');
						contentStyle.css({
							"letter-spacing": +valFS + "px"
						});
					}
				});

				jQuery("#line-height-range, #line-height-range2").slider({
					range: "max",
					min: 10,
					max: 100,
					value: 20,
					step:1,
					slide: function (event, ui) {
						var valFS = ui.value;
						var contentStyle = jQuery(this).parents('.elts-style').children('.content-style').children('p');
						contentStyle.css({
							"line-height": "calc( 0.05em * " + valFS + ")"
						});
					}
				});

				jQuery(".premier #line-height-range, .premier #line-height-range2").slider({
					range: "max",
					min: 10,
					max: 100,
					value: 20,
					step:1,
					slide: function (event, ui) {
						var valFS = ui.value;
						var contentStyle = jQuery(this).parents('.elts-style').children('.content-style').children('p');
						contentStyle.css({
							"line-height": "calc( 0.05em * " + valFS + ")"
						});
					}
				});

				jQuery(".deuxieme #line-height-range, .deuxieme #line-height-range2").slider({
					range: "max",
					min: 10,
					max: 100,
					value: 20,
					step:1,
					slide: function (event, ui) {
						var valFS = ui.value;
						var contentStyle = jQuery(this).parents('.elts-style').children('.content-style').children('p');
						contentStyle.css({
							"line-height": "calc( 0.05em * " + valFS + ")"
						});
					}
				});

				jQuery(".troisieme #line-height-range, .troisieme #line-height-range2").slider({
					range: "max",
					min: 10,
					max: 100,
					value: 20,
					step:1,
					slide: function (event, ui) {
						var valFS = ui.value;
						var contentStyle = jQuery(this).parents('.elts-style').children('.content-style').children('p');
						contentStyle.css({
							"line-height": "calc( 0.05em * " + valFS + ")"
						});
					}
				});

				

				jQuery('select').on('change', function () {
					var contentStyle = jQuery(this).parents('.elts-style').children('.content-style').children('p');
					contentStyle.css({
						"font-family": this.value
					});
				});

				jQuery(".styles-transform").on("click", "a", function () {
					//console.log(jQuery(this).attr('class'));
					var ClassTransfrom = jQuery(this).attr('class');
					jQuery('.styles-collections').css({
						"text-transform": ClassTransfrom
					});
					jQuery('.styles-transform a').removeClass('active');
					jQuery(this).addClass('active');
				});

				jQuery(".feature-transform a").on("click", function () {
					//console.log(jQuery(this).children('a'));
					var ClassTransfrom = jQuery(this).attr('class');
					jQuery('.resultat').toggleClass(ClassTransfrom)
					jQuery(this).children('a').toggleClass('active');

				});

				jQuery(".elts-style").each(function () {

					var alignText = jQuery(this).children('.control-font').children('.col-md-12').children('.align-text-transform').children('.align').children('a');
					var texTransform = jQuery(this).children('.control-font').children('.col-md-12').children('.align-text-transform').children('.text-transform').children('a');
					var fontFeature = jQuery(this).children('.control-font').children('.col-md-12').children('.font-feature').children('a');
					var childT = jQuery(this).children('.content-style');
					var selectFont = jQuery(this).children('.control-font').children('.col-md-12').children('.select-font').children('select');

					selectFont.on('change', function () {
						console.log('Hello')
					});

					alignText.on('click', function () {
						//console.log(selectFont)
						//console.log(alignText)
						var ClassAlign = jQuery(this).attr('class');
						var ParentClassAlign = jQuery(this).parent('.align');
						var ParentClassAlignA = ParentClassAlign.children('a');
						childT.css({
							"text-align": ClassAlign
						})
						ParentClassAlignA.removeClass('active');
						jQuery(this).addClass('active');
					});

					texTransform.on('click', function () {
						var ClassTransfrom = jQuery(this).attr('class');
						childT.css({
							"text-transform": ClassTransfrom
						})
						jQuery('.text-transform a').removeClass('active');
						jQuery(this).addClass('active');
					});

					fontFeature.on('click', function () {
						var ClassfontFeature = jQuery(this).attr('class');
						childT.toggleClass(ClassfontFeature)
						jQuery(this).toggleClass('active');
					});

				});

				jQuery('.removeStyle').on('click', function () {
					jQuery(this).parent().parent().find('.content-style p').removeAttr("style");
					jQuery(this).parent().parent().find('.content-style').removeAttr("style");
					jQuery(this).parent().parent().find('.content-style').removeClass("SS01");
					jQuery(this).parent().parent().find('.content-style').removeClass("SS02");
					jQuery(this).parent().parent().find('.content-style').removeClass("SS03");
					jQuery(this).parent().parent().find('.content-style').removeClass("SS04");
					jQuery(this).parent().parent().find('.font-feature a').removeClass("active");
					jQuery(this).parent().parent().find("#font-size-range2").slider("value", 36)
					jQuery(this).parent().parent().find("#letter-spacing-range, #letter-spacing-range2").slider("value", 500)
					jQuery(this).parent().parent().find("#line-height-range, #line-height-range2").slider("value", 58)
					return false;
				});

				jQuery('.premier .removeStyle').on('click', function () {
					jQuery(".premier #font-size-range").slider("value", ${font?.font_size_default})
					jQuery("#letter-spacing-range, #letter-spacing-range2").slider("value", ${font?.letter_default * 10})
					jQuery(".premier #line-height-range, .premier #line-height-range2").slider("value", ${font?.line_default})
					jQuery(".premier .content-style p").css({"font-family" : "${product.typefaces.font_family_layout_1.title}"})
				});
				jQuery('.deuxieme .removeStyle').on('click', function () {
					jQuery(".deuxieme #font-size-range2").slider("value", 60)
					jQuery("#letter-spacing-range, #letter-spacing-range2").slider("value", 500)
					jQuery(".deuxieme #line-height-range, .deuxieme #line-height-range2").slider("value", 62)
					jQuery(".deuxieme .content-style p").css({"font-family" : "${product.typefaces.font_family_layout_2.title}"})

				});
				jQuery('.troisieme .removeStyle').on('click', function () {
					jQuery(".troisieme #font-size-range2").slider("value", 130)
					jQuery("#letter-spacing-range, #letter-spacing-range2").slider("value", 500)
					jQuery(".troisieme #line-height-range, .troisieme #line-height-range2").slider("value", 124)
					jQuery(".troisieme .content-style p").css({"font-family" : "${product.typefaces.font_family_layout_3.title}"})

				});

				

				jQuery(".list-typeface").each(function () {
					var inputC = jQuery(this).children('input');
					var idC = inputC.attr("id");
					if (jQuery(this).children('input').is(":checked")) {
						jQuery("label." + idC).addClass('checked');
					} else {
						jQuery("label." + idC).removeClass('checked');
					}
					inputC.change(function () {
						if (jQuery(this).is(":checked")) {
							jQuery("label." + idC).addClass('checked');
						} else {
							jQuery("label." + idC).removeClass('checked');
						}
					});
				});


				function moveUp(item) {
					var prev = item.prev();
					if (prev.length == 0)
						return;
					prev.css('z-index', 999).css('position', 'relative').animate({ top: item.height() }, 250);
					item.css('z-index', 1000).css('position', 'relative').animate({ top: '-' + prev.height() }, 300, function () {
						prev.css('z-index', '').css('top', '').css('position', '');
						item.css('z-index', '').css('top', '').css('position', '');
						item.insertBefore(prev);
					});
				}
				setTimeout(function () {
					jQuery(".list-typeface").each(function () {
						var inputC = jQuery(this).children('input');
						var idC = inputC.attr("id");
						inputC.change(function () {
							if (jQuery(this).is(":checked")) {
								jQuery("label." + idC).addClass('checked');
							} else {
								jQuery("label." + idC).removeClass('checked');
							}
						});
					});
				}, 2000);
				function moveDown(item) {
					var next = item.next();
					if (next.length == 0)
						return;
					next.css('z-index', 999).css('position', 'relative').animate({ top: '-' + item.height() }, 250);
					item.css('z-index', 1000).css('position', 'relative').animate({ top: next.height() }, 300, function () {
						next.css('z-index', '').css('top', '').css('position', '');
						item.css('z-index', '').css('top', '').css('position', '');
						item.insertAfter(next);
					});
				}

				//$(".FieldContainer").sortable({ items: ".elts-style", distance: 10 });
				$('.order button').click(function () {
					var btn = $(this);
					var val = btn.val();
					if (val == 'up')
						moveUp(btn.parents('.elts-style'));
					else
						moveDown(btn.parents('.elts-style'));
				});
			}).trigger("click");

			$("#myInput").on("input", function () {
				// Print entered value in a div box
				$(".result").text($(this).val());
			});
			$("#example1").on("select", function () {
				var id = $(this).attr("id");
				alert(id);
			});

			$(document).on("click", ".button-remove", function () {
				$(this).closest(".box").remove();
			});

		});
        `;
		document.body.appendChild(s);
		}
	},[product])
return (
    		<Details.Layout className="col-md-12 short-story" id="layout" fontSize={product?.typefaces?.layout_1_opt?.font_size_default} letter={product?.typefaces?.layout_1_opt?.letter_default} line={product?.typefaces?.layout_1_opt?.line_default} fontSize2={product?.typefaces?.layout_2_opt?.font_size_default} letter2={product?.typefaces?.layout_2_opt?.letter_default} line2={product?.typefaces?.layout_2_opt?.line_default} fontSize3={product?.typefaces?.layout_3_opt?.font_size_default} letter3={product?.typefaces?.layout_3_opt?.letter_default} line3={product?.typefaces?.layout_3_opt?.line_default}>
						<div className="col-md-12 layout__contents">
							<div className="col-md-10 container-center list-account layout__title">
								<div className="top-list">
									Discover and try {fontName}
								</div>
							</div>

							<div className="col-md-12 layout__content">
								<div className="font-style">
									{/* -------------- Début contenu éditable -------------- */}
									<div className="elts-style flex-reverse premier">
										<div className="control-font col-md-12 editable-font">
											<div className="select-font">
												<select>
													{group.map((group) => {
														return (<>
															{faceOptions[group].map((option) => {
																return (<>
																	<option value={option.value.file.woff.title} selected = {product?.typefaces?.font_family_layout_1?.title === option.value.file.woff.title ? 'selected' : ''}>{group} {option.value.name} </option>
																</>)
															})}

														</>
														)
													})
													}

												</select>
											</div>
											{/* -------------- Font size cursor -------------- */}
											<div className="font-size">
												<div className="col-md-12">
													<div id="font-size-range" className="slider-range-max" />
												</div>
											</div>
											{/* -------------- Letter spacing cursor -------------- */}
											<div className="letter-spacing">
												<div className="col-md-12">
													<div id="letter-spacing-range" className="slider-range-max" />
												</div>
											</div>
											{/* -------------- Line height cursor -------------- */}
											<div className="line-height">
												<div className="col-md-12">
													<div id="line-height-range" className="slider-range-max" />
												</div>
											</div>

											{/* Style Font */}
											<div className="col-md-12 overflow-h">
												<div className="align-text-transform">
													{/* -------------- Text align -------------- */}
													<div className="align">
														{/* -------------- Text justified (left) -------------- */}
														<a href="#0" className="left active">
															{/*?xml version="1.0" encoding="UTF-8"?*/}
															<svg width="21px" height="20px" viewBox="0 0 21 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
																{/* Generator: Sketch 53.2 (72643) - https://sketchapp.com */}
																<title>Group</title>
																<desc>Created with Sketch.</desc>
																<g id="——-TYPEFACE" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
																	<g id="PRODUIT-OK-Copy-21" transform="translate(-130.000000, -3142.000000)" fill="#FFFFFF">
																		<g id="Group-13" transform="translate(130.000000, 3090.000000)">
																			<g id="Group" transform="translate(0.000000, 52.000000)">
																				<path d="M0,10.5247783 L0,9.47527094 L20.9852217,9.47527094 L20.9852217,10.5247783 L0,10.5247783 Z M0,5.80300493 L0,4.75369458 L13.6402956,4.75369458 L13.6402956,5.80300493 L0,5.80300493 Z M0,15.2463054 L0,14.1969951 L13.6402956,14.1969951 L13.6402956,15.2463054 L0,15.2463054 Z M0,1.08142857 L0,0.0319211823 L20.9852217,0.0319211823 L20.9852217,1.08142857 L0,1.08142857 Z M0,19.9680788 L0,18.9185714 L20.9852217,18.9185714 L20.9852217,19.9680788 L0,19.9680788 Z" id="Fill-5" />
																			</g>
																		</g>
																	</g>
																</g>
															</svg>
														</a>
														{/* -------------- Text justified to left -------------- */}
														{/* -------------- Text centered -------------- */}
														<a href="#0" className="center">
															{/*?xml version="1.0" encoding="UTF-8"?*/}
															<svg width="21px" height="20px" viewBox="0 0 21 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
																{/* Generator: Sketch 53.2 (72643) - https://sketchapp.com */}
																<title>Fill 5</title>
																<desc>Created with Sketch.</desc>
																<g id="——-TYPEFACE" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
																	<g id="PRODUIT-OK-Copy-21" transform="translate(-181.000000, -3142.000000)" fill="#FFFFFF">
																		<g id="Group-13" transform="translate(130.000000, 3090.000000)">
																			<g id="Group" transform="translate(51.000000, 52.000000)">
																				<path d="M0,10.5247783 L0,9.47527094 L20.9852217,9.47527094 L20.9852217,10.5247783 L0,10.5247783 Z M4.19699507,5.80300493 L4.19699507,4.75369458 L16.7880296,4.75369458 L16.7880296,5.80300493 L4.19699507,5.80300493 Z M4.19699507,15.2463054 L4.19699507,14.1969951 L16.7880296,14.1969951 L16.7880296,15.2463054 L4.19699507,15.2463054 Z M0,1.08142857 L0,0.0319211823 L20.9852217,0.0319211823 L20.9852217,1.08142857 L0,1.08142857 Z M0,19.9680788 L0,18.9185714 L20.9852217,18.9185714 L20.9852217,19.9680788 L0,19.9680788 Z" id="Fill-5" />
																			</g>
																		</g>
																	</g>
																</g>
															</svg>
														</a>
														{/* -------------- Text centered -------------- */}
														{/* -------------- Text justified to right -------------- */}
														<a href="#0" className="right">
															{/*?xml version="1.0" encoding="UTF-8"?*/}
															<svg width="21px" height="20px" viewBox="0 0 21 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
																{/* Generator: Sketch 53.2 (72643) - https://sketchapp.com */}
																<title>Group</title>
																<desc>Created with Sketch.</desc>
																<g id="——-TYPEFACE" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
																	<g id="PRODUIT-OK-Copy-21" transform="translate(-232.000000, -3142.000000)" fill="#FFFFFF">
																		<g id="Group-13" transform="translate(130.000000, 3090.000000)">
																			<g id="Group" transform="translate(102.000000, 52.000000)">
																				<path d="M0,10.5247783 L0,9.47527094 L20.9852217,9.47527094 L20.9852217,10.5247783 L0,10.5247783 Z M7.34472906,5.80300493 L7.34472906,4.75369458 L20.9852217,4.75369458 L20.9852217,5.80300493 L7.34472906,5.80300493 Z M7.34472906,15.2463054 L7.34472906,14.1969951 L20.9852217,14.1969951 L20.9852217,15.2463054 L7.34472906,15.2463054 Z M0,1.08142857 L0,0.0319211823 L20.9852217,0.0319211823 L20.9852217,1.08142857 L0,1.08142857 Z M0,19.9680788 L0,18.9185714 L20.9852217,18.9185714 L20.9852217,19.9680788 L0,19.9680788 Z" id="Fill-7" />
																			</g>
																		</g>
																	</g>
																</g>
															</svg>
														</a>
													</div>
													{/* -------------- Text transform -------------- */}
													<div className="text-transform">
														<a href="#0" className="initial active">Aa</a>
														<a href="#0" className="uppercase">AA</a>
														<a href="#0" className="lowercase">aa</a>
													</div>
												</div>
												{/* -------------- Stylistic sets -------------- */}
												<div className="font-feature">
													{product != null && product.typefaces != null && product.typefaces.font_feature != false ? product.typefaces.font_feature.map((font) => {
														return (<>
															<a href="#0" className={font.post_title}>{font.post_title}</a>
														</>)
													}) : ''}
												</div>
											</div>
											<a href="#0" className="removeStyle" />
										</div>
										{/* -------------- Rendu  -------------- */}
										<div className="content-style col-md-10" >
											<p contentEditable="true" suppressContentEditableWarning={true} style={{ fontFamily: product != null && product && product.typefaces && product.typefaces.font_family_layout_1 && product.typefaces.font_family_layout_1.title, fontWeight: fontWeight, fontStyle: fontStyle }}>{ product != null && product && product.typefaces && product.typefaces != false && product.typefaces.font_layout_1 && product.typefaces.font_layout_1}</p>
										</div>

										<div className="render">
											<div className="remove col-md-1">
												<a href="#0" />
											</div>

											<div className="order">
												<button value="up" className="up">Up</button>
												<button value="down" className="down">Down</button>
											</div>
										</div>
									</div>


									{/* -------------- Fin contenu éditable -------------- */}
									{/* -------------- Début contenu éditable -------------- */}
									<div className="elts-style box template flex-reverse">
										<div className="control-font col-md-12 editable-font">
											<div className="select-font">
												<select>
													{group.map((group) => {
														return (<>
															{faceOptions[group].map((option) => {
																return (<>
																	<option value={option.value.file.woff.title} selected = {product?.typefaces?.font_family_layout_1?.title === option.value.file.woff.title ? 'selected' : ''}>{group} {option.value.name} </option>
																</>)
															})}

														</>
														)
													})
													}

												</select>
											</div>
											{/* Font size Font */}
											<div className="font-size">
												<div className="col-md-12">
													<div id="font-size-range2" className="slider-range-max" />
												</div>
											</div>
											{/* Font size Font */}
											{/* Line height Font */}
											<div className="letter-spacing">
												<div className="col-md-12">
													<div id="letter-spacing-range2" className="slider-range-max" />
												</div>
											</div>
											{/* Line height Font */}
											{/* Line height Font */}
											<div className="line-height">
												<div className="col-md-12">
													<div id="line-height-range2" className="slider-range-max" />
												</div>
											</div>
											{/* Line height Font */}
											{/* Style Font */}
											<div className="col-md-12 overflow-h">
												<div className="align-text-transform">
													<div className="align">
														<a href="#0" className="left active">
															{/*?xml version="1.0" encoding="UTF-8"?*/}
															<svg width="21px" height="20px" viewBox="0 0 21 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
																<title>Group</title>
																<desc>Created with Sketch.</desc>
																<g id="——-TYPEFACE" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
																	<g id="PRODUIT-OK-Copy-21" transform="translate(-130.000000, -3142.000000)" fill="#FFFFFF">
																		<g id="Group-13" transform="translate(130.000000, 3090.000000)">
																			<g id="Group" transform="translate(0.000000, 52.000000)">
																				<path d="M0,10.5247783 L0,9.47527094 L20.9852217,9.47527094 L20.9852217,10.5247783 L0,10.5247783 Z M0,5.80300493 L0,4.75369458 L13.6402956,4.75369458 L13.6402956,5.80300493 L0,5.80300493 Z M0,15.2463054 L0,14.1969951 L13.6402956,14.1969951 L13.6402956,15.2463054 L0,15.2463054 Z M0,1.08142857 L0,0.0319211823 L20.9852217,0.0319211823 L20.9852217,1.08142857 L0,1.08142857 Z M0,19.9680788 L0,18.9185714 L20.9852217,18.9185714 L20.9852217,19.9680788 L0,19.9680788 Z" id="Fill-5" />
																			</g>
																		</g>
																	</g>
																</g>
															</svg>
														</a>
														<a href="#0" className="center">
															{/*?xml version="1.0" encoding="UTF-8"?*/}
															<svg width="21px" height="20px" viewBox="0 0 21 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
																<title>Fill 5</title>
																<desc>Created with Sketch.</desc>
																<g id="——-TYPEFACE" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
																	<g id="PRODUIT-OK-Copy-21" transform="translate(-181.000000, -3142.000000)" fill="#FFFFFF">
																		<g id="Group-13" transform="translate(130.000000, 3090.000000)">
																			<g id="Group" transform="translate(51.000000, 52.000000)">
																				<path d="M0,10.5247783 L0,9.47527094 L20.9852217,9.47527094 L20.9852217,10.5247783 L0,10.5247783 Z M4.19699507,5.80300493 L4.19699507,4.75369458 L16.7880296,4.75369458 L16.7880296,5.80300493 L4.19699507,5.80300493 Z M4.19699507,15.2463054 L4.19699507,14.1969951 L16.7880296,14.1969951 L16.7880296,15.2463054 L4.19699507,15.2463054 Z M0,1.08142857 L0,0.0319211823 L20.9852217,0.0319211823 L20.9852217,1.08142857 L0,1.08142857 Z M0,19.9680788 L0,18.9185714 L20.9852217,18.9185714 L20.9852217,19.9680788 L0,19.9680788 Z" id="Fill-5" />
																			</g>
																		</g>
																	</g>
																</g>
															</svg>
														</a>
														<a href="#0" className="right">
															{/*?xml version="1.0" encoding="UTF-8"?*/}
															<svg width="21px" height="20px" viewBox="0 0 21 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
																<title>Group</title>
																<desc>Created with Sketch.</desc>
																<g id="——-TYPEFACE" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
																	<g id="PRODUIT-OK-Copy-21" transform="translate(-232.000000, -3142.000000)" fill="#FFFFFF">
																		<g id="Group-13" transform="translate(130.000000, 3090.000000)">
																			<g id="Group" transform="translate(102.000000, 52.000000)">
																				<path d="M0,10.5247783 L0,9.47527094 L20.9852217,9.47527094 L20.9852217,10.5247783 L0,10.5247783 Z M7.34472906,5.80300493 L7.34472906,4.75369458 L20.9852217,4.75369458 L20.9852217,5.80300493 L7.34472906,5.80300493 Z M7.34472906,15.2463054 L7.34472906,14.1969951 L20.9852217,14.1969951 L20.9852217,15.2463054 L7.34472906,15.2463054 Z M0,1.08142857 L0,0.0319211823 L20.9852217,0.0319211823 L20.9852217,1.08142857 L0,1.08142857 Z M0,19.9680788 L0,18.9185714 L20.9852217,18.9185714 L20.9852217,19.9680788 L0,19.9680788 Z" id="Fill-7" />
																			</g>
																		</g>
																	</g>
																</g>
															</svg>
														</a>
													</div>
													<div className="text-transform">
														<a href="#0" className="initial active">Aa</a>
														<a href="#0" className="uppercase">AA</a>
														<a href="#0" className="lowercase">aa</a>
													</div>
												</div>
												<div className="font-feature">
													{product != null && product && product.typefaces && product.typefaces.font_feature && product.typefaces.font_feature != false ? product && product.typefaces && product.typefaces.font_feature && product.typefaces.font_feature.map((font) => {
														return (<>
															<a href="#0" className={font.post_title}>{font.post_title}</a>
														</>)


													}) : ''}
												</div>
											</div>
											{/* Style Font */}
											<a href="#0" className="removeStyle" />
										</div>
										<div className="content-style">
											<p contentEditable="true" style={{ fontFamily: product != null && product && product.typefaces && product.typefaces.fonts__collections && product.typefaces.fonts__collections != false && product.typefaces.fonts__collections[0] && product.typefaces.fonts__collections[0].style[0].file.woff.title, fontWeight: fontWeight, fontStyle: fontStyle }} suppressContentEditableWarning={true}>
												{ product != null && product && product.typefaces && product.typefaces != false && product.typefaces.font_layout_2 && product.typefaces.font_layout_2}
                                                </p>
										</div>
										<div className="render">
											<div className="remove col-md-1">
												<a href="#0" />
											</div>

											<div className="order">
												<button value="up" className="up">Up</button>
												<button value="down" className="down">Down</button>
											</div>
										</div>
									</div>
									<div className="elts-style box flex-reverse deuxieme">
										<div className="control-font col-md-12 editable-font">
											<div className="select-font">
												<select>
													{group.map((group) => {
														return (<>
															{faceOptions[group].map((option) => {
																return (<>																	
																	<option value={option.value.file.woff.title} selected = {product?.typefaces?.font_family_layout_2?.title === option.value.file.woff.title ? 'selected' : ''}>{group} {option.value.name} </option>
																</>)
															})}

														</>
														)
													})
													}

												</select>
											</div>
											{/* Font size Font */}
											<div className="font-size">
												<div className="col-md-12">
													<div id="font-size-range2" className="slider-range-max" />
												</div>
											</div>
											{/* Font size Font */}
											{/* Line height Font */}
											<div className="letter-spacing">
												<div className="col-md-12">
													<div id="letter-spacing-range2" className="slider-range-max" />
												</div>
											</div>
											{/* Line height Font */}
											{/* Line height Font */}
											<div className="line-height">
												<div className="col-md-12">
													<div id="line-height-range2" className="slider-range-max" />
												</div>
											</div>
											{/* Line height Font */}
											{/* Style Font */}
											<div className="col-md-12 overflow-h">
												<div className="align-text-transform">
													<div className="align">
														<a href="#0" className="left active">
															{/*?xml version="1.0" encoding="UTF-8"?*/}
															<svg width="21px" height="20px" viewBox="0 0 21 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
																<title>Group</title>
																<desc>Created with Sketch.</desc>
																<g id="——-TYPEFACE" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
																	<g id="PRODUIT-OK-Copy-21" transform="translate(-130.000000, -3142.000000)" fill="#FFFFFF">
																		<g id="Group-13" transform="translate(130.000000, 3090.000000)">
																			<g id="Group" transform="translate(0.000000, 52.000000)">
																				<path d="M0,10.5247783 L0,9.47527094 L20.9852217,9.47527094 L20.9852217,10.5247783 L0,10.5247783 Z M0,5.80300493 L0,4.75369458 L13.6402956,4.75369458 L13.6402956,5.80300493 L0,5.80300493 Z M0,15.2463054 L0,14.1969951 L13.6402956,14.1969951 L13.6402956,15.2463054 L0,15.2463054 Z M0,1.08142857 L0,0.0319211823 L20.9852217,0.0319211823 L20.9852217,1.08142857 L0,1.08142857 Z M0,19.9680788 L0,18.9185714 L20.9852217,18.9185714 L20.9852217,19.9680788 L0,19.9680788 Z" id="Fill-5" />
																			</g>
																		</g>
																	</g>
																</g>
															</svg>
														</a>
														<a href="#0" className="center">
															{/*?xml version="1.0" encoding="UTF-8"?*/}
															<svg width="21px" height="20px" viewBox="0 0 21 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
																<title>Fill 5</title>
																<desc>Created with Sketch.</desc>
																<g id="——-TYPEFACE" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
																	<g id="PRODUIT-OK-Copy-21" transform="translate(-181.000000, -3142.000000)" fill="#FFFFFF">
																		<g id="Group-13" transform="translate(130.000000, 3090.000000)">
																			<g id="Group" transform="translate(51.000000, 52.000000)">
																				<path d="M0,10.5247783 L0,9.47527094 L20.9852217,9.47527094 L20.9852217,10.5247783 L0,10.5247783 Z M4.19699507,5.80300493 L4.19699507,4.75369458 L16.7880296,4.75369458 L16.7880296,5.80300493 L4.19699507,5.80300493 Z M4.19699507,15.2463054 L4.19699507,14.1969951 L16.7880296,14.1969951 L16.7880296,15.2463054 L4.19699507,15.2463054 Z M0,1.08142857 L0,0.0319211823 L20.9852217,0.0319211823 L20.9852217,1.08142857 L0,1.08142857 Z M0,19.9680788 L0,18.9185714 L20.9852217,18.9185714 L20.9852217,19.9680788 L0,19.9680788 Z" id="Fill-5" />
																			</g>
																		</g>
																	</g>
																</g>
															</svg>
														</a>
														<a href="#0" className="right">
															{/*?xml version="1.0" encoding="UTF-8"?*/}
															<svg width="21px" height="20px" viewBox="0 0 21 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
																<title>Group</title>
																<desc>Created with Sketch.</desc>
																<g id="——-TYPEFACE" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
																	<g id="PRODUIT-OK-Copy-21" transform="translate(-232.000000, -3142.000000)" fill="#FFFFFF">
																		<g id="Group-13" transform="translate(130.000000, 3090.000000)">
																			<g id="Group" transform="translate(102.000000, 52.000000)">
																				<path d="M0,10.5247783 L0,9.47527094 L20.9852217,9.47527094 L20.9852217,10.5247783 L0,10.5247783 Z M7.34472906,5.80300493 L7.34472906,4.75369458 L20.9852217,4.75369458 L20.9852217,5.80300493 L7.34472906,5.80300493 Z M7.34472906,15.2463054 L7.34472906,14.1969951 L20.9852217,14.1969951 L20.9852217,15.2463054 L7.34472906,15.2463054 Z M0,1.08142857 L0,0.0319211823 L20.9852217,0.0319211823 L20.9852217,1.08142857 L0,1.08142857 Z M0,19.9680788 L0,18.9185714 L20.9852217,18.9185714 L20.9852217,19.9680788 L0,19.9680788 Z" id="Fill-7" />
																			</g>
																		</g>
																	</g>
																</g>
															</svg>
														</a>
													</div>
													<div className="text-transform">
														<a href="#0" className="initial active">Aa</a>
														<a href="#0" className="uppercase">AA</a>
														<a href="#0" className="lowercase">aa</a>
													</div>
												</div>
												<div className="font-feature">
													{product != null && product && product.typefaces && product.typefaces.font_feature && product.typefaces.font_feature != false ? product && product.typefaces && product.typefaces.font_feature && product.typefaces.font_feature.map((font) => {
														return (<>
															<a href="#0" className={font.post_title}>{font.post_title}</a>
														</>)


													}) : ''}
												</div>
											</div>
											{/* Style Font */}
											<a href="#0" className="removeStyle" />
										</div>
										<div className="content-style">
											<p contentEditable="true" style={{ fontFamily: product != null && product && product.typefaces && product.typefaces.font_family_layout_2 && product.typefaces.font_family_layout_2.title, fontWeight: fontWeight, fontStyle: fontStyle }} suppressContentEditableWarning={true}>
											{ product != null && product && product.typefaces && product.typefaces != false && product.typefaces.font_layout_2 && product.typefaces.font_layout_2}
                                                </p>
										</div>
										<div className="render">
											<div className="remove col-md-1">
												<a href="#0" />
											</div>

											<div className="order">
												<button value="up" className="up">Up</button>
												<button value="down" className="down">Down</button>
											</div>
										</div>
									</div>
									<div className="elts-style box flex-reverse troisieme">
										<div className="control-font col-md-12 editable-font">
											<div className="select-font">
												<select>
													{group.map((group) => {
														return (<>
															{faceOptions[group].map((option) => {
																return (<>
																	<option value={option.value.file.woff.title} selected = {product?.typefaces?.font_family_layout_3?.title === option.value.file.woff.title ? 'selected' : ''}>{group} {option.value.name} </option>
																</>)
															})}

														</>
														)
													})
													}

												</select>
											</div>
											{/* Font size Font */}
											<div className="font-size">
												<div className="col-md-12">
													<div id="font-size-range2" className="slider-range-max" />
												</div>
											</div>
											{/* Font size Font */}
											{/* Line height Font */}
											<div className="letter-spacing">
												<div className="col-md-12">
													<div id="letter-spacing-range2" className="slider-range-max" />
												</div>
											</div>
											{/* Line height Font */}
											{/* Line height Font */}
											<div className="line-height">
												<div className="col-md-12">
													<div id="line-height-range2" className="slider-range-max" />
												</div>
											</div>
											{/* Line height Font */}
											{/* Style Font */}
											<div className="col-md-12 overflow-h">
												<div className="align-text-transform">
													<div className="align">
														<a href="#0" className="left active">
															{/*?xml version="1.0" encoding="UTF-8"?*/}
															<svg width="21px" height="20px" viewBox="0 0 21 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
																<title>Group</title>
																<desc>Created with Sketch.</desc>
																<g id="——-TYPEFACE" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
																	<g id="PRODUIT-OK-Copy-21" transform="translate(-130.000000, -3142.000000)" fill="#FFFFFF">
																		<g id="Group-13" transform="translate(130.000000, 3090.000000)">
																			<g id="Group" transform="translate(0.000000, 52.000000)">
																				<path d="M0,10.5247783 L0,9.47527094 L20.9852217,9.47527094 L20.9852217,10.5247783 L0,10.5247783 Z M0,5.80300493 L0,4.75369458 L13.6402956,4.75369458 L13.6402956,5.80300493 L0,5.80300493 Z M0,15.2463054 L0,14.1969951 L13.6402956,14.1969951 L13.6402956,15.2463054 L0,15.2463054 Z M0,1.08142857 L0,0.0319211823 L20.9852217,0.0319211823 L20.9852217,1.08142857 L0,1.08142857 Z M0,19.9680788 L0,18.9185714 L20.9852217,18.9185714 L20.9852217,19.9680788 L0,19.9680788 Z" id="Fill-5" />
																			</g>
																		</g>
																	</g>
																</g>
															</svg>
														</a>
														<a href="#0" className="center">
															{/*?xml version="1.0" encoding="UTF-8"?*/}
															<svg width="21px" height="20px" viewBox="0 0 21 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
																<title>Fill 5</title>
																<desc>Created with Sketch.</desc>
																<g id="——-TYPEFACE" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
																	<g id="PRODUIT-OK-Copy-21" transform="translate(-181.000000, -3142.000000)" fill="#FFFFFF">
																		<g id="Group-13" transform="translate(130.000000, 3090.000000)">
																			<g id="Group" transform="translate(51.000000, 52.000000)">
																				<path d="M0,10.5247783 L0,9.47527094 L20.9852217,9.47527094 L20.9852217,10.5247783 L0,10.5247783 Z M4.19699507,5.80300493 L4.19699507,4.75369458 L16.7880296,4.75369458 L16.7880296,5.80300493 L4.19699507,5.80300493 Z M4.19699507,15.2463054 L4.19699507,14.1969951 L16.7880296,14.1969951 L16.7880296,15.2463054 L4.19699507,15.2463054 Z M0,1.08142857 L0,0.0319211823 L20.9852217,0.0319211823 L20.9852217,1.08142857 L0,1.08142857 Z M0,19.9680788 L0,18.9185714 L20.9852217,18.9185714 L20.9852217,19.9680788 L0,19.9680788 Z" id="Fill-5" />
																			</g>
																		</g>
																	</g>
																</g>
															</svg>
														</a>
														<a href="#0" className="right">
															{/*?xml version="1.0" encoding="UTF-8"?*/}
															<svg width="21px" height="20px" viewBox="0 0 21 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
																<title>Group</title>
																<desc>Created with Sketch.</desc>
																<g id="——-TYPEFACE" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
																	<g id="PRODUIT-OK-Copy-21" transform="translate(-232.000000, -3142.000000)" fill="#FFFFFF">
																		<g id="Group-13" transform="translate(130.000000, 3090.000000)">
																			<g id="Group" transform="translate(102.000000, 52.000000)">
																				<path d="M0,10.5247783 L0,9.47527094 L20.9852217,9.47527094 L20.9852217,10.5247783 L0,10.5247783 Z M7.34472906,5.80300493 L7.34472906,4.75369458 L20.9852217,4.75369458 L20.9852217,5.80300493 L7.34472906,5.80300493 Z M7.34472906,15.2463054 L7.34472906,14.1969951 L20.9852217,14.1969951 L20.9852217,15.2463054 L7.34472906,15.2463054 Z M0,1.08142857 L0,0.0319211823 L20.9852217,0.0319211823 L20.9852217,1.08142857 L0,1.08142857 Z M0,19.9680788 L0,18.9185714 L20.9852217,18.9185714 L20.9852217,19.9680788 L0,19.9680788 Z" id="Fill-7" />
																			</g>
																		</g>
																	</g>
																</g>
															</svg>
														</a>
													</div>
													<div className="text-transform">
														<a href="#0" className="initial active">Aa</a>
														<a href="#0" className="uppercase">AA</a>
														<a href="#0" className="lowercase">aa</a>
													</div>
												</div>
												<div className="font-feature">
													{product != null && product && product.typefaces && product.typefaces.font_feature && product.typefaces.font_feature != false ? product && product.typefaces && product.typefaces.font_feature && product.typefaces.font_feature.map((font) => {
														return (<>
															<a href="#0" className={font.post_title}>{font.post_title}</a>
														</>)


													}) : ''}
												</div>
											</div>
											{/* Style Font */}
											<a href="#0" className="removeStyle" />
										</div>
										<div className="content-style">
											<p contentEditable="true" style={{ fontFamily: product != null && product && product.typefaces && product.typefaces.font_family_layout_3 && product.typefaces.font_family_layout_3.title, fontWeight: fontWeight, fontStyle: fontStyle }} suppressContentEditableWarning={true}>
											{ product != null && product && product.typefaces && product.typefaces != false && product.typefaces.font_layout_3 && product.typefaces.font_layout_3}
                                                </p>
										</div>
										<div className="render">
											<div className="remove col-md-1">
												<a href="#0" />
											</div>

											<div className="order">
												<button value="up" className="up">Up</button>
												<button value="down" className="down">Down</button>
											</div>
										</div>
									</div>
									{/* Content editable */}

								</div>
								<div className="col-md-12"></div>
							</div>
							<a href="#0" className="button-add btn-copy">
								<img src="/images/btn-plus.svg" alt="Welt" />
							</a>
						</div>
					</Details.Layout>
)
}

const Details = {
	Content: styled.div`
    font-family: Lausanne600;
    `,
	Navbar: styled.div`
    height: 50px;
    .menu-account {
        ul {
            li {
                a {
                    display: flex;
                    justify-content: center;
                    padding-left: 0;
                    padding: 0 5px;
                }
            }
        }
    }
    `,
	/* ================================== Onglet 'ABOUT' ==================================*/
	About: styled.div`
    .about__intro { padding: 0; }
    .intro__author {
        float: left;
    }

    .intro__option1 {
        float: right;
        display: flex;
        padding: 14px 20px 14px 20px;
        .product__details {
            span {
                padding-right: 5px;
                color: #D8D8D8;
            }
        }
    }
    `,
	/* ================================== Onglet 'STYLES' ==================================*/
	Styles: styled.div``,
	StylesIntro: styled.div`
    .control-font { font-family: 'Lausanne400'; }
    .flex {
        display: flex;
        justify-content: space-between;
        padding-top: 6px;
        gap: 5px;
    }
    .align-text-transform {
        .align {
            padding-top: 0 !important;
            .text-transform {
                display: flex;
                flex-wrap: wrap;
                row-gap: 5px;
                a {
                    margin-right : 0;
                }
            }
        }
    }
    .color-theme-switcher {
        .switch {
            position: relative;
            display: inline-block;
            width: 72px;
            height: 36px;

            input {
                opacity: 0;
                width: 0;
                height: 0;
                &:checked + .slider {
                    background-color: none;
                    border: 1px solid black;
                    &:before {
                        -webkit-transform: translateX(36px);
                        -ms-transform: translateX(36px);
                        transform: translateX(36px);
                    }
                }

                &:focus + .slider { box-shadow: 0 0 1px #2196F3; }
            }

            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: none;
                border: 1px solid black;
                -webkit-transition: .4s;
                transition: .4s;

                &.round  {
                    border-radius: 36px;
                    &:before {
                        border-radius: 50%;
                    }
                }

                &:before {
                    position: absolute;
                    content: "";
                    height: 100%;
                    width: 34px;
                    left: 0;
                    bottom: 0;
                    background-color: #EBEBEB;
                    -webkit-transition: .4s;
                    transition: .4s;
                }
            }
        }
    }

    `,

	StylesOverview: styled.div`
    padding-bottom: 50px;
    min-height: 450px;

    &.theme-switch-dark{
        background: #141414;
        color: white !important;
        .theme-switch-dark--colors {
            div, ul, li, p  {
                color: white !important;
            }
        }
    }
    `,

	StylesFamily: styled.div`
    .details__content {
        padding: 4px 0;
        .list__bloc:first-child { height: 155px; }
        .list__bloc:nth-child(2) { height: 100px; }
        .list__bloc { font-family: Lausanne600; }

        .specific-font {
            padding: 0 5px 5px 5px;
            .col-md-10 { padding: 0; }
            .flex {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                padding-bottom: 10px;
                .my {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                }
            }
            .font-check {
                float: right;
                text-align: right;
            }

            .leftside,
            .rightside { height: 100%; }

            .rightside {
                text-align: right;
            }
        }

        .family__styles { 
            float: left;
            p { line-height: 25px; }
        }

        .font-select { line-height: 25px; }
        .font-select, .flex { margin-top: 3px; }

        .specific-font,
        .lab {height: 100%;}
    }
    `,

	StylesCollection: styled.div`
    .collection__name {
        margin: 0 auto;
        clear: both;
        padding-top: 40px;
    }
    .list-collections__name { margin: 0 auto; }
    .list-collections__fonts {
        .list-collections__font {
            clear: both;
            &:last-child {
                .font__example { 
                    margin-bottom: 0;
                }
            }
        }
        .font__example {
            padding: 0 0 12px 12px;
            margin: 3px 0;
            p { white-space: nowrap; }
        }

        .font__select {
            font-family: Lausanne600;
            label {
                &.sign {
                    display: flex;
                    flex-direction: row-reverse;
                    flex-wrap: wrap;
                    label {
                        display: flex;
                        align-self: flex-start;
                        margin-left: 10px;
                        margin-bottom: 3px;
                    }
                }
            }
        }
    }
    `,
	/* ================================== Onglet 'LAYOUT' ==================================*/
	Layout: styled.div`
    .layout__content {
        background: #F7F7F7;

        .editable-font {
            opacity: 0;
            &:hover { opacity: 1; }
            margin-top: 30px;
        }

        .flex-reverse {
            display: flex;
            flex-direction: column;
        }
    }
    .remove { float: left; }
	.premier{
		.content-style{
			p{
			padding-top: .25em;
			font-size:${(props) => props.fontSize || 240}px;
			line-height: 1em;
			letter-spacing:0px;
				*{
					font: inherit;
				}
			}
		}
	}
	.troisieme{
		.content-style{
			p{
				padding-top: .25em;
			font-size:${(props) => props.fontSize3 || 240}px;
			line-height: 1em;
			letter-spacing:0px;
				*{
					font: inherit;
				}
			}
		}
	}
	.deuxieme{
		.content-style{
			p{
			padding-top: .25em;
			font-size:${(props) => props.fontSize2 || 240}px;
			line-height: 1em;
			letter-spacing:0px;
				*{
					font: inherit;
				}
			}
		}
	}
    .content-style {
        margin: 0 auto;
        background: inherit;
		overflow: hidden auto;
    }
    .order {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-right: 12px;
    }
    .render {
        justify-content: space-between;
        width: 100%;
        display: flex;
    }
    `,
}

export default Font;