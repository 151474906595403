import { GET_VERSION,VERSION_LOADING} from '../actions/types'

const initialState = {
    version : null,
    loading: true
}
const versionReducer =( state = initialState, action)=> {
    switch(action.type) {
        case VERSION_LOADING:
            return {
                ...state,
                loading: true
            };
        case GET_VERSION:
            return {
                ...state,
                version: action.payload,
                loading: false
            }; 
        default :
            return state
    }
}
export default versionReducer