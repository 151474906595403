import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

class InlinePost extends Component {
	render() {
		const post = this.props.post
		return (
			<Link to={{
				pathname: `/newspaper/${post.slug}`,
				post
			}}>
				<div className="main-book col-md-10 container-center">
					<div className="book">
						<div className="content">
							<div className="title">{post != null && post.title}</div>
							{post != null && <div dangerouslySetInnerHTML={{ __html: post.excerpt }}></div>}
						</div>
						<FormattedMessage id="home.link.more" defaultMessage="See more"/> ↗
          </div>
				</div>
			</Link>
		);
	}
}

export default InlinePost;