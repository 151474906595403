import { combineReducers } from 'redux'
import authReducer from './authReducer'
import cartReducer from './cartReducer'
import productsReducer from './productsReducer'
import favoriteReducer from './favoriteReducers'
import categorieReducer from './categorieReducer'
import currentProduct from './currentProduct'
import translateReducer from './translateReducer'
import fouterReducers from './fouterReducers'
import bannerReducer from './bannerReducer'
import postReducer from './postReducer'
import pagesReducer from './pagesReducer'
import currencyReducer from './currencyReducer'
import socialMediaReducer from './socialMediaReducer'
import adressesReducer from './adressesReducer'
import usersReducer from './usersReducer'
import checkoutReducer from './checkoutReducer'
import versionReducer from './versionReducer'
import paymentReducer from './paymentReducer'
import categoriePostReducer from './categoriePostReducer'


export default combineReducers({
    auth          : authReducer,
    cart          : cartReducer,
    products      : productsReducer,
    favorites     : favoriteReducer,
    categorie     : categorieReducer,
    currentProduct: currentProduct,
    translation   : translateReducer,
    footerData    : fouterReducers,
    bannerObject  : bannerReducer,
    postObject    : postReducer,
    pages         : pagesReducer,
    currencies    : currencyReducer,
    medias        : socialMediaReducer,
    adresses      : adressesReducer,
    users         : usersReducer,
    checkouts     : checkoutReducer,
    version       : versionReducer,
    postCategories: categoriePostReducer,
    payment       : paymentReducer,

})
