import $ from 'jquery';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import { changeCurrency, getCurrencyData } from '../../../actions/currencyAction';
import { getMedias } from '../../../actions/socialMediaAction';
import Spinner from '../../utils/Spinner';
class MenuPrincipal extends Component {

  componentDidMount() {
    this.props.getCurrencyData()
    this.props.getMedias()
    $(".close").on('click', function () {
      $("body").toggleClass('body-lock');
      $("header.relative.menu-white").toggleClass('menu-show');
      $(".menu").removeClass('active');
    });
  }

  changeCurrency = (e) => {
    const currencies = this.props.currencies.currency
    const currentCurrency = currencies.filter(item => item.key == e.target.value)[0]
    this.props.changeCurrency(currentCurrency)
    setTimeout(() => {
      // window.location.reload()
    }, 500);
  }

  render() {
    const { currencies, medias } = this.props;
    let displayCurrencies, displaySocialMedia, displaySocialIcon;
    if (currencies.currency === null || currencies.loading || currencies.currencies == undefined) {
      displayCurrencies = <Spinner />
    }
    else {
      console.log(currencies.currencies)
      displayCurrencies = currencies.currency.map((currency, i) => {
        return (
          <span key={i}>
            <input type="radio" name="currency" id={i} value={currency.key} onChange={this.changeCurrency} checked={this.props.currencyChecked.key === currency.key} />
            {this.props.currencyChecked.key === currency.key ?
              <label for={i} style={{ background: '#EBEBEB', color: 'black' }} >{currency.key}</label> :
              <label for={i} style={{ background: 'black', color: '#EBEBEB', border: '1px solid #EBEBEB' }} >{currency.key}</label>
            }
          </span>
        )
      })
    }
    if (medias === null || medias.medias === null || medias.loading) {
      displaySocialMedia = <Spinner />
      displaySocialIcon = <Spinner />
    }
    else {
      displaySocialMedia = medias && medias.medias && medias.medias[0].lenght > 0 && medias.medias.map((media, i) => {
        if (media.slug === 'instagram') {
          return (<span key={i}>
            <a href={media.url} >{media.slug}</a><br />
          </span>)
        }
      })
      displaySocialIcon = medias && medias.medias[0] > 0 && medias.medias.map((media, i) => {
        if (media.slug !== 'instagram') {
          return (
            <a key={i} className={media.slug} href={media.url}>{media.slug}</a>
          )
        }
      })
    }

    return (
      <div className="menu-principal uppercase">
        <div className="menu-left">
          <ul className="text text--xlarge">
            <NavLink to="/home" activeClassName="active" className='close'>Home</NavLink>
            {/* <a href="/shop" activeClassName="active" className='close'>Shop</a> */}
            <NavLink to="/shop" activeClassName="active" className='close'>Shop</NavLink>
            <NavLink to="/typeface" activeClassName="active" className='close'>Typefaces</NavLink>
            {/*<NavLink to="/newspaper" activeClassName="active" className='close'>Journal</NavLink>*/}
            <NavLink to="/club" activeClassName="active" className='close'>Club</NavLink>
          </ul>
        </div>

        <div className="menu-right">
          <ul className="text text--xmedium">
            <NavLink to="/support">Support</NavLink>
            <NavLink to="/support_/about" className='close'>About us</NavLink>
            <NavLink to="/support_/informations" className='close' >Informations </NavLink>
            <NavLink to="/support_/download" className='close' >Download</NavLink>
            <NavLink to="/support_/updates" className='close' >Updates</NavLink>
            <NavLink to="/support_/contact" className='close' >Contact</NavLink>
            <NavLink to="/support_/faq" className='close' >Faq</NavLink>
          {  /*<NavLink to="/press" className='close' >Press</NavLink>
            <NavLink to="/brands" className='close' >Index</NavLink>*/}
          </ul>
        </div>
      </div>
    )
  }
}
MenuPrincipal.propTypes = {
  getCurrencyData: PropTypes.func.isRequired,
  changeCurrency : PropTypes.func.isRequired,
  getMedias      : PropTypes.func.isRequired
}
const mapStateToprops = (state) => ({
  currencies     : state.currencies,
  currencyChecked: state.currencies.currencyChecked,
  medias         : state.medias
})

export default connect(mapStateToprops, { getCurrencyData, changeCurrency, getMedias })(MenuPrincipal)
