import React, { Component } from 'react';
import MenuPrincipal from "../menu/principal";
import Panier from '../panier';
import MenuTop from "./menu/Index";
export default class HeaderIndex extends Component {
	render() {
		return (
			<header className={"col-md-12 relative menu-white " + this.props.col}>
				<div className="container-center col-md-10 relative">
					<div className={"top-header " + this.props.dark}>
						<MenuTop />
					</div>
					<MenuPrincipal />
					{this.props.insideHeader}
					{this.props.hasPanier === true ? <Panier /> : null}
				</div>
			</header>
		)
	}
}
