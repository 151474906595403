import React, { Component } from 'react';
import $ from 'jquery';

class CreateNewPassword extends Component {
	constructor() {
		super();
		this.state = {
			password: '',
			password2: '',
		}
	}
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}
	componentDidMount(){
		$('html,body').animate({
      scrollTop: 0
    }, 'slow');
	}
	onSubmit = (e) => {

		e.preventDefault();
		const newUser = {
			password: this.state.password,
			password2: this.state.password2,
		}

		if (this.state.password !== this.state.password2) {
			alert('password confirm does not match')
			return
		}

	}
	render() {
		return (
			<div className="reset-password">
				<button className="default-btn-cart" onClick={
					this.props.history.goBack}>Go back
                </button>
				<div className="simple-form">
					<div className="label">Reset Password</div>
					<form className="identification" onSubmit={this.onSubmit} >
						<div className="form-text">
							<input
								type="password"
								placeholder="New password"
								name="password"
								onChange={this.onChange}
							/>

						</div>
						<div className="form-text">
							<input
								type="password"
								placeholder="Confim password"
								name="password2"
								onChange={this.onChange}
							/>
						</div>
						<div className="form-submit">
							<button type="submit" >Create password</button>
						</div>
					</form>
				</div>
			</div>
		);
	}
}

export default CreateNewPassword;