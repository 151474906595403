import React, { Component } from "react";
import Moment from "react-moment";
import API, { subUrl } from "../../../utils/api";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
//import isEmpty from '../../../validation/is-empty';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
class TypeFaceOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urls: [],
    };
  }
  componentDidMount() {
    console.log(
      "itemmm",
      this.props.order.line_items.map(
        (item) =>
          item.fonts &&
          item.fonts[0] &&
          item.fonts.map((result) => result.value)
      )
    );
  }
  download(url) {
    const FileDownload = require("js-file-download");
    console.log(url);
    console.log("itemmm", this.props);
    let urls;
    const data = {
      order_id: this.props.order.order_id,
      product_id: url,
    };
    API.post(subUrl.getDownload, data)
      .then((res) => {
        this.setState({ url: res.data });
        if (res.data.complete != null)
        confirmAlert({
          title: "Download",
          message: "Are you sure you want to download this font?",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                window.open(res.data.complete, "_blank");
              },
            },
            {
              		label: 'No',
              	}
          ],
        });
        
        //FileDownload(res.data, "example.zip");

        urls = res.data;
        console.log("reponse", res.data);
      })
      .catch((error) => console.log(error));
    console.log(this.state.url);
    var link = document.createElement("a");

    link.setAttribute("download", null);
    link.style.display = "none";

    document.body.appendChild(link);

    /* this.state.url && this.state.url[0] && this.state.url.map((result)=>{
			link.setAttribute('href', result);
			link.click();
		}) */

    document.body.removeChild(link);
  }

  downloadByOne(url) {
    const FileDownload = require("js-file-download");
    if (url != null) window.open(url, "_blank");

    /* this.state.url && this.state.url[0] && this.state.url.map((result)=>{
					link.setAttribute('href', result);
					link.click();
				}) */
  }
  render() {
    let orderItems;
    if (this.props.order.line_items !== undefined) {
      orderItems = this.props.order.line_items.map((item) =>
        item.product_type === "Typeface" ? (
          <div className="list-licenses d-flex-row">
            <div className="col-md-2">
              <Link
                refresh="true"
                className="img-l-full"
                to={{
                  pathname:
                    item.product_type == "Typeface"
                      ? `/shop/detail-typeface/${item.slug}`
                      : `/shop/detail-product/${item.slug}`,
                  item,
                }}
              >
                <img
                  src={
                    item.galery && item.galery != false && item.galery != null
                      ? item.galery
                      : item.image.url
                  }
                  alt="Welt"
                />
              </Link>
            </div>
            <div className="col-md-10 d-flex-column-s-b">
              <div className="col-md-12">
                <div className="col-md-4 float-left">
                  <h3>{item.name}</h3>
                  <p className="license-select">
                    {item.family == ""
                      ? item.fonts_url &&
                        item.fonts_url[0] &&
                        item.fonts_url.map((result) => (
                          <span>{result.name}</span>
                        ))
                      : item.family}
                  </p>
                </div>
                <div className="col-md-5 float-left">
                  {item.licence &&
                  item.licence != null &&
                  item.licence.firstName ? (
                    <>
                      <p>License owner</p>
                      <p>
                        {item.licence.firstName} {item.licence.lastName}
                      </p>
                      <p>{item.licence.email}</p>
                      <p>{item.licence.address}</p>
                      <p>{item.licence.complememt}</p>
                      <p>
                        {item.licence.city} {item.licence.numero}
                      </p>
                      <p>{item.licence.state}</p>
                      <p>{item.licence.paye}</p>
                      <p>{item.licence.Company}</p>
                      <p>{item.licence.Website}</p>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-3 float-right">
                  <div className={`version ${item.licencesChecked !== 'Trial version' ? 'other-licences' : '' }`}>{item.licencesChecked}</div>
                </div>
              </div>
              <div className="col-md-12 text-bottom">
                <div className="col-md-4 float-left">
                  Licenses N°: {this.props.order.order_id}
                </div>
                <div className="col-md-5 float-left">
                  {console.log(this.props.order.date_created)}
                  From{" "}
                  <Moment format="DD MM YYYY">
                    {this.props.order.date_created}
                  </Moment>
                  {/* 24.02.2019 */}
                </div>
                {item.family == "Complete Family" ||
                item.family == "Half Family" ||
                item.family == "Essential" ? (
                  <div className="col-md-3 float-left">
                    <button
                      onClick={() => this.download(item.product_id)}
                      download
                      className="link-downlaod"
                    >
                      Download
                    </button>
                  </div>
                ) : (
                  <div className="col-md-3 float-left">
                    <Popup
                      trigger={
                        <button className="link-downlaod">Download</button>
                      }
                    >
                      {item.fonts_url &&
                        item.fonts_url[0] &&
                        item.fonts_url.map((result) => (
                          <>
                            <div className="list-title-font">
                              <div className="title-font">{result.name}</div>
                              <button
                                style={{ float: "rigth" }}
                                onClick={() =>
                                  this.downloadByOne(
                                    item.licencesChecked === "Trial version"
                                      ? result.trial
                                      : result.file
                                  )
                                }
                                download
                                className="link-downlaod"
                              >
                                Download
                              </button>
                            </div>
                          </>
                        ))}
                    </Popup>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )
      );
    }
    return (
      <div className="col-md-10 container-center my-order">{orderItems}</div>
    );
  }
}

export default TypeFaceOrders;
