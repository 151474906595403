
let initialState = {
  cartItems :[], 
  totalPrices: 0
}

const cartReducer  =(state=initialState, action)=> {
  let nextState, existing,ifExist, removeState, updatedPrice, removeAll, notExisting, totalPricesProduct;
  switch (action.type) {
    case 'GET_RANGE_VALUE' :
     
      notExisting = state.cartItems.filter(item => item.id !== action.payload.id || item.licenceChecked !== action.payload.licenceChecked   || JSON.stringify(item.featureProduct) !==  JSON.stringify(action.payload.featureProduct) )
      existing = state.cartItems.filter(state => state.id === action.payload.id && state.licenceChecked === action.payload.licenceChecked && JSON.stringify(state.featureProduct) ===  JSON.stringify(action.payload.featureProduct))[0]
      if (existing === undefined) {
        console.log(action.payload)
          nextState = [ ...notExisting,{ ...action.payload, quantity: 1, pointPrice:  action.payload.pointPrice }]
      }else{
        // update price per quantity
        console.log(action.payload)
         
          nextState = [...notExisting,{ ...existing, pointPrice:  action.payload.pointPrice}];
          
      }
      return {
          ...state,
          cartItems : nextState,

      }
      case 'ADD' :
        console.log(action.payload.id)
        notExisting = state.cartItems.filter(item => item.id !== action.payload.id || item.licenceChecked !== action.payload.licenceChecked  || JSON.stringify(item.featureProduct) !==  JSON.stringify(action.payload.featureProduct) )
        existing = state.cartItems.filter(state => state.id === action.payload.id && state.licenceChecked === action.payload.licenceChecked   && JSON.stringify(state.featureProduct) ===  JSON.stringify(action.payload.featureProduct) )[0]
        if (existing === undefined) {
          console.log(action.payload)
            totalPricesProduct = parseFloat(action.payload.price)
            nextState = [
                         ...notExisting,
                        { ...action.payload, quantity: 1,
                            totalPricesProduct: totalPricesProduct, pointPrice:  action.payload.pointPrice, 
                            gift_wrap: action.payload.typeface ? action.payload.gift_wrap : false,
                            licences: action.payload.licences ? action.payload.licences : null,
                            licenceChecked: action.payload.licenceChecked ? action.payload.licenceChecked : 'S',
                            newPrice: action.payload.newPrice ? action.payload.newPrice : 0,
                            newWeltPrice: action.payload.newWeltPrice ? action.payload.newWeltPrice : 0,
                            family: action.payload.family ? action.payload.family : ''
                        }
                    ]
            updatedPrice =  parseFloat(state.totalPrices) +  parseFloat(action.payload.price)
        }else{
          // update price per quantity
          if(action.payload.choice_product === "Typeface"){
            console.log(action.payload)
            totalPricesProduct = parseFloat(action.payload.price) * (existing.quantity)
            nextState = [ 
                  ...notExisting,
                  { ...existing, quantity:  existing.quantity , faceOption: action.payload.faceOption,  totalPricesProduct: totalPricesProduct,
                      pointPrice:  action.payload.pointPrice,
                      gift_wrap: action.payload.typeface ? action.payload.gift_wrap : false,
                      licences: action.payload.licences ? action.payload.licences : null,
                      licenceChecked: action.payload.licenceChecked ? action.payload.licenceChecked : 'S',
                      newPrice: action.payload.newPrice ? action.payload.newPrice : 0,
                      newWeltPrice: action.payload.newWeltPrice ? action.payload.newWeltPrice : 0,
                      family: action.payload.family ? action.payload.family : ''
                  }
                ];
            
            updatedPrice =  Math.round((parseFloat(state.totalPrices) +  parseFloat(action.payload.price)))

          }
          else {
            console.log(action.payload.licences)
            totalPricesProduct = parseFloat(action.payload.price) * (existing.quantity + 1)
            nextState = [ 
                  ...notExisting,
                  { ...existing, quantity:  existing.quantity + 1, faceOption: action.payload.faceOption,  totalPricesProduct: totalPricesProduct,
                      pointPrice:  action.payload.pointPrice,
                      gift_wrap: action.payload.typeface ? action.payload.gift_wrap : false,
                      licences: action.payload.licences ? action.payload.licences : null,
                      licenceChecked: action.payload.licenceChecked ? action.payload.licenceChecked : 'S',
                      newPrice: action.payload.newPrice ? action.payload.newPrice : 0,
                      newWeltPrice: action.payload.newWeltPrice ? action.payload.newWeltPrice : 0,
                      family: action.payload.family ? action.payload.family : ''
                  }
                ];
            
            updatedPrice =  Math.round((parseFloat(state.totalPrices) +  parseFloat(action.payload.price)))
          }
        }
        return {
            ...state,
            cartItems : nextState,
            totalPrices :  updatedPrice

        }
      case 'REMOVE' :
        notExisting = state.cartItems.filter(item => item.id !== action.payload.id || item.licenceChecked !== action.payload.licenceChecked  || JSON.stringify(item.featureProduct) !==  JSON.stringify(action.payload.featureProduct))
        existing = state.cartItems.filter(state => state.id === action.payload.id && state.licenceChecked === action.payload.licenceChecked && JSON.stringify(state.featureProduct) ===  JSON.stringify(action.payload.featureProduct))[0]
        if(existing.quantity == 1) {
          removeState = [...notExisting]
        }else{
          removeState = [...notExisting, {...existing , quantity: existing.quantity - 1 }]
        }
        updatedPrice = Math.round((parseFloat(state.totalPrices) -  parseFloat(existing.totalPricesProduct)))
      return {
          ...state,
          cartItems: removeState,
          totalPrices : updatedPrice
      }
      case 'REMOVE_ALL' : 
      notExisting = state.cartItems.filter(item => item.id !== action.payload.id || item.licenceChecked !== action.payload.licenceChecked  || JSON.stringify(item.featureProduct) !==  JSON.stringify(action.payload.featureProduct))
      existing = state.cartItems.filter(state => state.id === action.payload.id && state.licenceChecked === action.payload.licenceChecked && JSON.stringify(state.featureProduct) ===  JSON.stringify(action.payload.featureProduct))[0]
        removeAll =  [...notExisting]
        updatedPrice =    Math.round((state.totalPrices - (existing.quantity * existing.price))*100)/100
        return {
          ...state,
          cartItems: removeAll,
          totalPrices :  updatedPrice
        }
        case 'INIALIZE_CART' :
          return {
            ...state,
            cartItems: [],
            totalPrices: 0
          }

  }
  return state
}
export default cartReducer
