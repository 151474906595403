import classnames from "classnames";
import $ from "jquery";
import lodash from "lodash";
import React, { Component } from "react";
import InputRange from "react-input-range";
import { connect } from "react-redux";
import API, { subUrl } from "../../../utils/api";
import isEmpty from "../../../validation/is-empty";

class OverviewProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      color: "black",
      size: "large",
      model: "",
      edition: "",
      giftPrice: 0,
      giftChecked: false,
      giftChecked: null,
      primes: 0,
      produit: 1,
      favorites: [],
      max: 1,
    };
  }
  componentDidMount() {
    //this.props.getFavorite();
    const totalPoint =
      this.props.cart.cartItems.length != 0
        ? this.props.cart.cartItems.reduce((acc, current) => {
            return acc + current.points;
          }, 0)
        : 0;
    API.get(subUrl.customerPoint)
      .then((res) => {
        this.setState({ primes: res.data.point.prime - parseInt(totalPoint) });
        console.log("primes", res.data.point.prime - parseInt(totalPoint));
      })
      .catch((error) => console.log(error));

    this.props.cart.cartItems.length !== 0 &&
      this.props.cart.cartItems.map((cart) => {
        if (cart.id == this.props.currentProduct.id)
          this.setState({
            produit: cart.quantity,

            giftChecked: this.props.currentProduct.giftChecked,

            giftPrice: this.props.currentProduct.gift_wrap_price,
          });
      });
    if (
      this.props.currentProduct &&
      this.props.currentProduct.options &&
      this.props.currentProduct.options.only_welt_point
    ) {
      this.setState({
        value: this.props.currentProduct.welt_price,
        //max : this.props.currentProduct.quantity
      });
    } else {
      this.setState({
        value:
          this.props.cartItem != undefined
            ? this.props.cartItem.pointPrice.value
            : 0,
      });
    }
    $(".price-virgule").text(function () {
      return $(this).text().replace(".", ",");
    });
    $(".panier").on("click", function () {
      $(".cart").addClass("animate");
      setTimeout(() => {
        $(".cart").removeClass("animate");
      }, 2000);
    });

    $(".btn-panier.panier").on("click", function () {
      $(".close-modal-btn").click();
      if ($("body").hasClass("no-sroll") === true) {
        $("body").removeClass("no-sroll");
      }
    });
    API.get(subUrl.getFavorit)
      .then((res) => {
        this.setState({ favorites: res.data });
      })
      .catch((error) => console.log(error));
  }

  increment = () => {
    console.log(this.props.currentProduct.quantity, this.state.produit);
    if (
      this.state.produit < this.props.currentProduct.quantity ||
      this.props.currentProduct.backorder_check == "yes"
    )
      this.setState({ produit: this.state.produit + 1 });
  };

  decrement = () => {
    if (this.state.produit != 0)
      this.setState({ produit: this.state.produit - 1 });
  };

  _displayFavoriteImage(product) {
    var sourceImage = require("../../../assets/images/heart-like.svg");
    if (
      this.props.favorites &&
      this.props.favorites.findIndex((item) => item.id === product.id) !== -1
    ) {
      sourceImage = require("../../../assets/images/heart-dislike.svg");
    }
    return <img src={sourceImage} width="36" height="36" />;
  }

  _displayCartImage(product) {
    var sourceImage = require("../../../assets/images/cart2.svg");
    if (
      this.props.favorites &&
      this.props.favorites.findIndex((item) => item.id === product.id) !== -1
    ) {
      sourceImage = require("../../../assets/images/cart2.svg");
    }
    return <img src={sourceImage} width="17" height="17" />;
  }
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  toggleFavorite = (current) => {
    console.log(current.id);
    const id = {
      id: current.id,
    };
    API.post(subUrl.postFavorit, id)
      .then((res) => {
        this.props.getFavorite();
        this.forceUpdate();
      })
      .catch((error) => console.log(error));
  };

  handleGiftChecked = (event) => {
    if (!this.state.giftChecked) {
      this.setState({ giftPrice: event.target.value, giftChecked: true });
    } else {
      this.setState({ giftPrice: 0, giftChecked: false });
    }
  };
  render() {
    const product = this.props.products;
    const { isAuthenticated, user } = this.props.auth;
    const currentProduct = this.props.currentProduct;
    let options;
    let gift_wrap;
    const featureProduct = {
      model: this.state.model,
      color: this.state.color,
      size: this.state.size,
      edition: this.state.edition,
    };

    const productInCart = this.props.cartItem;
    let model, colors, size, images, getProduct, edition;
    if (currentProduct !== null && currentProduct !== undefined) {
      if (
        currentProduct.gallery_image.length > 0 &&
        typeof currentProduct.gallery_image === "object"
      ) {
        images = currentProduct.gallery_image.map((image, i) => {
          return (
            <div key={i} className="img-l-full">
              <img src={image.url} key={i} alt={image.alt} />{" "}
            </div>
          );
        });
      }
    } else {
      images = <p> empty </p>;
    }
    if (!isEmpty(currentProduct.model)) {
      model = currentProduct.model.map((models, i) => {
        return (
          <div key={i}>
            <input
              type="radio"
              name="model"
              defaultChecked={i < 1 && models.slug}
              value={models.slug}
              id={models.slug}
              onChange={this.onChange}
            />{" "}
            <label htmlFor={models.slug}> {models.name} </label>{" "}
          </div>
        );
      });
    } else {
      model = null;
    }
    if (!isEmpty(currentProduct.edition)) {
      edition = currentProduct.edition.map((edition, i) => {
        return (
          <div key={i}>
            <input
              type="radio"
              name="edition"
              defaultChecked={i < 1 && edition.slug}
              value={edition.slug}
              id={edition.slug}
              onChange={this.onChange}
            />{" "}
            <label htmlFor={edition.slug}> {edition.name} </label>{" "}
          </div>
        );
      });
    } else {
      edition = null;
    }
    if (!isEmpty(currentProduct.colors)) {
      colors = currentProduct.colors.map((colors, i) => {
        return (
          <div key={i}>
            <input
              type="radio"
              name="color"
              value={colors.color}
              defaultChecked={i < 1 && colors.color}
              id={colors.slug}
              onChange={this.onChange}
            />{" "}
            <label
              htmlFor={colors.slug}
              style={{ background: colors.color }}
              title={colors.name}
            >
              {" "}
            </label>{" "}
          </div>
        );
      });
    } else {
      colors = null;
    }
    if (!isEmpty(currentProduct.sizes)) {
      size = currentProduct.sizes.map((size, i) => {
        return (
          <option key={i} value={size.slug}>
            {" "}
            {size.name}{" "}
          </option>
        );
      });
    } else {
      size = null;
    }
    if (colors == null && size == null) {
      getProduct = this.props.cart.cartItems.filter(
        (cartItem) => cartItem.id === currentProduct.id
      )[0];
    } else {
      getProduct = this.props.cart.cartItems.filter(
        (cartItem) =>
          cartItem.id === currentProduct.id &&
          JSON.stringify(cartItem.featureProduct) ==
            JSON.stringify(featureProduct)
      )[0];
    }
    const handleAddBtn = classnames({
      qty: true,
      disabled: featureProduct.color == "" || featureProduct.size == "",
    });
    if (getProduct != null)
      options = lodash.groupBy(getProduct.faceOption, "group");

    let currentOption = [];
    if (currentProduct.typefaces != null)
      currentProduct.typefaces.fonts__collections.map((font) => {
        font.style.map((style, index) => {
          currentOption.push({
            group: font.title,
            id: index,
            value: style,
            isChecked: true,
          });
        });
      });

    currentOption = lodash.groupBy(currentOption, "group");

    const faceOption = [];
    if (currentProduct != null && currentProduct.typefaces != null)
      currentProduct.typefaces.fonts__collections.map((font) => {
        font.style.map((style, index) => {
          faceOption.push({
            group: font.title,
            id: index,
            value:
              options == undefined
                ? currentOption[font.title][index].value.name
                : options[font.title][index].value.name,
            isChecked:
              options == undefined
                ? true
                : options[font.title][index].isChecked,
            //price: currentProduct.typefaces != null && currentProduct[font.title] && currentProduct[font.title][index].isChecked ? currentProduct[font.title][index].value.price : 0,
            price: currentProduct.typefaces
              ? options == undefined
                ? currentOption[font.title][index].value.price
                : options[font.title][index].value.price
              : 0,
          });
        });
      });
    // Calcul prices , price: sum of selected fonts option

    const pointPrice = {
      value: this.state.value,
    };
    const ratePiont = this.state.value * 0.01;
    const ratePiontPrime =
      this.state.value * 0.01 * currentProduct.categorie_multiplier;
    let currentPrice =
      productInCart == undefined
        ? currentProduct.variant && currentProduct.variant.length > 0
          ? Math.round(
              (parseFloat(currentProduct.variant[0].price) *
                this.props.currencyChecked.value -
                ratePiont) *
                1 *
                100
            ) / 100
          : Math.round(
              ((currentProduct.price +
                (this.state.giftPrice != undefined &&
                  parseFloat(this.state.giftPrice))) *
                this.props.currencyChecked.value -
                ratePiont) *
                1 *
                100
            ) / 100
        : Math.round(
            ((productInCart.price +
              (this.state.giftPrice != undefined &&
                parseFloat(this.state.giftPrice))) *
              this.props.currencyChecked.value *
              productInCart.quantity -
              ratePiont) *
              100
          ) / 100;

    if (
      productInCart &&
      productInCart.variant &&
      productInCart.variant.length > 0
    )
      productInCart.variant.map((variant) => {
        variant.name.attribute_pa_edition ==
        productInCart.featureProduct.edition
          ? (currentPrice =
              Math.round(
                (parseFloat(variant.price) * this.props.currencyChecked.value -
                  ratePiont) *
                  1 *
                  100
              ) / 100)
          : variant.name.attribute_pa_color ==
            productInCart.featureProduct.color
          ? (currentPrice =
              Math.round(
                (parseFloat(variant.price) * this.props.currencyChecked.value -
                  ratePiont) *
                  1 *
                  100
              ) / 100)
          : (currentPrice =
              Math.round(
                (parseFloat(productInCart.variant[0].price) *
                  this.props.currencyChecked.value -
                  ratePiont) *
                  1 *
                  100
              ) / 100);
      });

    //currentPrice = currentPrice + parseInt(this.state.giftPrice)
    let initialWeltPrice =
      parseInt(currentPrice) * currentProduct.categorie_multiplier;

    return (
      <div className="modalBox col col-md-12">
        <div className="col col-md-6 imgLeft ">
          {currentProduct.featured_image != undefined ? (
            <img
              src={
                currentProduct.gallery_image.length == 0
                  ? currentProduct.featured_image.url
                  : currentProduct.gallery_image[0].url
              }
              style={{ width: "100%" }}
              alt={currentProduct.alt}
            />
          ) : null}
        </div>{" "}
        <div className="col col-md-6 white">
          <div className="details-info">
            <div className="marque">
              {" "}
              <ul>
                {" "}
                <li> {currentProduct.categories[0].name} </li>
              </ul>
              <ul>
                {" "}
                <li
                  className="blackTitle"
                  dangerouslySetInnerHTML={{ __html: currentProduct.name }}
                ></li>{" "}
              </ul>
            </div>
            <div className="option-gift">
              <div>
                {" "}
                {model !== null && (
                  <div className="col-md-12 licenses">
                    <p> Modèle </p> {model}{" "}
                  </div>
                )}{" "}
                {/* <div className="col-md-12 tagcolor">
									<p> Edition </p>
									<div className="boxmodel"><span>EA217</span></div>
									<div className="boxmodel boxmode2"><span>EA217</span></div>
								</div> */}
                {edition !== null && (
                  <div className="col-md-12 licenses">
                    <p> Edition </p>
                    {edition}
                  </div>
                )}
                {colors !== null && (
                  <div className="col-md-12 licenses color">
                    <p> Piètement </p> {colors}{" "}
                  </div>
                )}{" "}
                {size !== null && (
                  <div className="col-md-12 licenses">
                    <p> Revêtement </p>{" "}
                    <div className="select">
                      <select name="size" onChange={this.onChange}>
                        {size}
                      </select>{" "}
                    </div>{" "}
                  </div>
                )}{" "}
                {/* <div className="col-md-12 licenses">
									<p>Revêtement</p>
									<div className="select Pietement">
										<select className="selectpientement">
											<option value="Cuir">Cuir</option>
											<option value="Cuir">Cuir</option>
										</select>
									</div>
								</div> */}
                {/* <div className="col-md-12 licenses color two-lines">
									<p>Couleur de revêtement</p>
									<div className="content">
										<input type="radio" name="color_revetement" id="purple" />
										<label htmlhtmlFor="purple" style={{ background: '#985C45' }} title="Purple" />
										<input type="radio" name="color_revetement" id="mauve" />
										<label htmlhtmlFor="mauve" style={{ background: '#381E41' }} title="Mauve" />
										<input type="radio" name="color_revetement" id="orange" />
										<label htmlhtmlFor="orange" style={{ background: '#FFBB84' }} title="Orange" />
										<input type="radio" name="color_revetement" id="vert" />
										<label htmlhtmlFor="vert" style={{ background: '#415648' }} title="Vert" />
									</div>
								</div> */}
                {currentProduct.gift_wrap != null &&
                currentProduct.gift_wrap != 0 &&
                !isNaN(parseFloat(currentProduct.gift_wrap)) ? (
                  <div class="option-gift physique over">
                    <div class="col-md-12 option over">
                      <p class="small-txt">OPTIONS</p>
                      <input
                        type="checkbox"
                        name=""
                        id="option-gift"
                        checked={this.state.giftChecked}
                        onClick={this.handleGiftChecked}
                        value={currentProduct.gift_wrap}
                      />
                      <label for="option-gift">
                        Add Gift Wrap (+{this.props.currencyChecked.key}{" "}
                        {currentProduct.gift_wrap})
                      </label>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>{" "}
            </div>
          </div>{" "}
          <div className="footer-modal">
            <div className="col-md-12 clearfix">
              {" "}
              {/* <div className="monnaie-chf"><span>CHF</span>{productInCart == undefined ? currentProduct.price : productInCart.totalPricesProduct}</div> */}{" "}
              <div
                className={`monnaie-chf ${
                  currentProduct &&
                  currentProduct.options &&
                  currentProduct.options.only_welt_point
                    ? "disabled"
                    : ""
                }`}
              >
                {" "}
                <span> {this.props.currencyChecked.key} </span>{" "}
                <span>
                  {" "}
                  {currentPrice < 0 ? 0 : parseFloat(currentPrice).toFixed(2)}
                </span>
                {/* <span >
                  {
                  productInCart == undefined ? currentProduct.welt_price : productInCart.welt_price * this.props.cart.cartItems.filter(cartItem => cartItem.id === currentProduct.id)[0].quantity
                 }
              </span> */}{" "}
                {/* <span>W</span> */}{" "}
              </div>{" "}
              <div className="monnaie-welt">
                <label>+</label>
                {currentProduct &&
                currentProduct.options &&
                currentProduct.options.only_welt_point
                  ? getProduct == undefined
                    ? initialWeltPrice
                    : initialWeltPrice * getProduct.quantity
                  : this.state.value}
                <span>W</span> {/* <label>Selected </label> */}{" "}
              </div>{" "}
            </div>
            <div className="col-md-12 slide-range">
              <InputRange
                maxValue={
                  // !currentProduct.options.only_welt_point ?
                  productInCart == undefined
                    ? (currentProduct &&
                        currentProduct.welt_price != undefined &&
                        parseInt(currentProduct.welt_price) +
                          (this.state.giftPrice != undefined &&
                            parseFloat(this.state.giftPrice) * 100)) >=
                      this.state.primes
                      ? this.state.primes
                      : parseInt(currentProduct.welt_price) +
                        (this.state.giftPrice != undefined &&
                          parseFloat(this.state.giftPrice) * 100)
                    : (getProduct &&
                        getProduct.welt_price != undefined &&
                        (parseInt(getProduct.welt_price) +
                          this.state.giftPrice !=
                          undefined && parseFloat(this.state.giftPrice * 100)) *
                          this.props.cart.cartItems.filter(
                            (cartItem) => cartItem.id === currentProduct.id
                          )[0].quantity) >= this.state.primes
                    ? this.state.primes
                    : (parseInt(getProduct.welt_price) +
                        (this.state.giftPrice != undefined &&
                          parseFloat(this.state.giftPrice * 100))) *
                      this.props.cart.cartItems.filter(
                        (cartItem) => cartItem.id === currentProduct.id
                      )[0].quantity

                  // : currentProduct.price
                  // currentProduct.welt_price
                }
                step={5}
                disabled={
                  (currentProduct &&
                    currentProduct.options &&
                    currentProduct.options.only_welt_point) ||
                  !isAuthenticated
                    ? true
                    : false
                }
                minValue={0}
                value={
                  currentProduct &&
                  currentProduct.options &&
                  currentProduct.options.only_welt_point
                    ? getProduct == undefined
                      ? initialWeltPrice
                      : initialWeltPrice * getProduct.quantity
                    : this.state.value
                }
                onChange={(value) =>
                  this.setState({ value }) +
                  parseInt(this.state.giftPrice) * 100
                }
                onChangeComplete={(value) => {
                  // this.props.addRangeValueToReducer(value)
                  if (getProduct !== undefined) {
                    if (model == null) {
                      featureProduct.model = "";
                    }
                    if (edition == null) {
                      featureProduct.edition = "";
                    }
                    if (colors == null) {
                      featureProduct.color = "";
                    }
                    if (size == null) {
                      featureProduct.size = "";
                    }
                    this.props.addRangeValueToReducer({
                      ...currentProduct,
                      featureProduct,
                      faceOption,
                      ...pointPrice,
                      pointPrice,
                      licenceChecked: "default",
                    });
                  }
                }}
              />
              {!isAuthenticated && (
                <span className="text-range-of-line">
                  Login to redeem your W points !
                </span>
              )}
            </div>{" "}
            <div className="col-md-12 details-info">
              {(currentProduct.quantity > 0 ||
                currentProduct.backorder_check == "yes") &&
              currentProduct.multiplier != false ? (
                <div className="prix-multiplier">
                  {initialWeltPrice}W×{currentProduct.multiplier} →{" "}
                  <span>+{initialWeltPrice * currentProduct.multiplier}W</span>
                </div>
              ) : (
                (currentProduct.quantity > 0 ||
                  currentProduct.backorder_check == "yes") && (
                  <div className="prix-chf"> +{initialWeltPrice}W</div>
                )
              )}
              <div className="qty-add-favori">
                <div
                  className={
                    model !== null ||
                    colors !== null ||
                    size !== null ||
                    edition !== null
                      ? handleAddBtn
                      : "qty"
                  }
                >
                  {
                    <button
                      className={
                        this.state.produit > 1 ? "decrement" : "gray-signe"
                      }
                      onClick={() => {
                        this.state.produit > 1 && this.decrement();
                        if (getProduct != undefined)
                          this.props.removeFromCart(getProduct);
                      }}
                    >
                      -
                    </button>
                  }
                  <p className="info-bulle">
                    {" "}
                    Select from the left to top to Shopping Cart{" "}
                  </p>{" "}
                  {currentProduct.choice_product != "Typeface" ? (
                    <p className="number"> {this.state.produit}</p>
                  ) : (
                    ""
                  )}
                  <button
                    className="increment"
                    onClick={() => {
                      this.increment();
                      if (model == null) {
                        featureProduct.model = "";
                      }
                      if (edition == null) {
                        featureProduct.edition = "";
                      }
                      if (colors == null) {
                        featureProduct.color = "";
                      }
                      if (size == null) {
                        featureProduct.size = "";
                      }

                      /* this.props.addToCart({
												...currentProduct,
												featureProduct,
												faceOption,
												...pointPrice,
												pointPrice,
												newWeltPrice: Math.floor(initialWeltPrice * 100), gift_wrap_price: this.state.giftPrice
											}); */
                    }}
                  >
                    {currentProduct.choice_product === "Typeface" ? (
                      <>
                        {" "}
                        <p> Add to cart </p>{" "}
                        <div className="img-cart">
                          {" "}
                          <img src="/images/panier-add.svg" />{" "}
                        </div>
                      </>
                    ) : (
                      "+"
                    )}
                  </button>
                </div>
                <button
                  className="btn-heart like"
                  onClick={() => {
                    this.toggleFavorite(currentProduct);
                  }}
                >
                  {this._displayFavoriteImage(currentProduct)}{" "}
                </button>{" "}
                {currentProduct.quantity > 0 ||
                (currentProduct.quantity <= 0 &&
                  currentProduct.backorder_check == "yes") ? (
                  <button
                    className="btn-panier panier"
                    onClick={() => {
                      if (model == null) {
                        featureProduct.model = "";
                      }
                      if (edition == null) {
                        featureProduct.edition = "";
                      }
                      if (colors == null) {
                        featureProduct.color = "";
                      }
                      if (size == null) {
                        featureProduct.size = "";
                      }
                      if (getProduct != undefined)
                        for (let i = 0; i < getProduct.quantity; i++) {
                          this.props.removeFromCart(getProduct);
                        }
                      for (let i = 0; i < this.state.produit; i++) {
                        this.props.cart.cartItems.filter(
                          (cartItem) => cartItem.id === currentProduct.id
                        );
                        this.props.addToCart({
                          ...currentProduct,
                          featureProduct,
                          faceOption,
                          ...pointPrice,
                          pointPrice,
                          newWeltPrice: Math.floor(initialWeltPrice * 100),
                          gift_wrap_price: 0 /*this.state.giftPrice */,
                          licenceChecked: "default",
                        });
                      }
                    }}
                  >
                    {this._displayCartImage(currentProduct)}{" "}
                  </button>
                ) : (
                  <button className="increment panier-type uppercase out-of-stock">
                    <p>Out of stock</p>
                  </button>
                )}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>
    );
  }
}

const mapStateToprops = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToprops)(OverviewProduct);
