import React, { useState, useEffect } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from "react-google-maps";
import MarkerIcon from "./icon/normalmap.svg"
import mapStyles from "./mapStyles";

function Map() {
  return (
    <GoogleMap
      defaultZoom={13}
      defaultCenter={{ lat: 46.3338174, lng: 6.1943733 }}
      defaultOptions={
        {
          styles           : mapStyles,
          mapTypeControl   : false,
          zoomControl      : false,
          panControl       : false,
          streetViewControl: false,
          fullscreenControl: false,
          visible          : false
        }
      }
    >
      <Marker
        position={{ lat: 46.3338174, lng:6.1943733 }}
        icon={MarkerIcon}
      />
    </GoogleMap>
  );

}
const MapWrapped = withScriptjs(withGoogleMap(Map));

export default function map() {

  return (
    <div style={{ width: "100%", height: "65vh" }}>
      <MapWrapped
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBKj64n9saFmW2RqHkLJd97DHMmfpgc514`}
        loadingElement={<div style={{ height: `50vh` }} />}
        containerElement={<div style={{ height: `50vh` }} />}
        mapElement={<div style={{ height: `50vh` }} />}
      />
    </div>
  );
}
