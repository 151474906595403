import React, { Component, useState } from 'react';
import HeaderIndex from "../../components/support/header";
import FooterIndex from "../_includes/footer/FooterSanaHeader";
import MenuSupport from "../_includes/menu/support";
import Spinner from '../utils/Spinner'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Cart from "../_includes/lastProduits/Cart"
import User from "../_includes/login/Login"
import { getPages, getFaq } from '../../actions/pagesAction'
import { Helmet } from 'react-helmet';
import $ from 'jquery'

class Index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			keySearch: ''
		};
	}

	componentDidMount() {
		$('html,body').animate({
			scrollTop: 0
		}, 'slow');

		this.props.getPages()
		this.props.getFaq()

		var item1 = $("p");
		$(".col-md-7.float-left").find(item1).css("background-color", "red");

		$("body").removeClass("body-lock")
		$(".cart").on('click', function () {
			$(".top-header").toggleClass("dark");
			return false;
		});

		$(".account, .account-offine").on('click', function () {
			$(".top-header").toggleClass("dark");
			return false;
		});

		$(".menu").on('click', function () {
			$(".top-header").toggleClass("dark");
			return false;
		});

	}

	inputsaerch = (event) => {
		const keySearch = event.target.value
		this.setState({ keySearch })
	}

	render() {
		let { pages } = this.props;
		let content = [];
		let displayContent = [];
		let displayContent2 = [];
		let total = 0;
		let total2 = 0;
		let displayFooter;


		if (pages.pageData === undefined || pages.pageData === null || pages.loading) {
			displayContent = <Spinner />
		} else {
			let foundFooter = false;
			displayFooter = pages && pages.pageData && pages.pageData.map((page, i) => {
				if (pages.slug !== null) {
					let founded = (page.slug.indexOf("support-faq") > -1);
					if (founded) {
						foundFooter = true;
						return (
							<FooterIndex
								key={i}
								footer={page}
							/>
						)
					}
				}
				total = page.total
			})

			if (!foundFooter) {
				displayFooter = <FooterIndex />
			}
		}

		if (pages.pageData === undefined || pages.pageData === null || pages.loading) {
			displayContent2 = <Spinner />
		} else {
			pages && pages.pageData.map((faq, i) => {
				if (faq.slug !== null) {
					let founded = (faq.slug.indexOf("support-faq") > -1)
					if (founded) {
						if (this.state.keySearch != null && this.state.keySearch != "") {
							const faqFilter = faq.general.question_reponse.filter(question => {
								return question.question.toLowerCase().includes(this.state.keySearch.toLowerCase())
							})

							total = faqFilter.length
							displayContent = faqFilter.map((question) => {
								return (
									<div className="contact-mails">
										{ console.log("question=====", question)}
										<h3 dangerouslySetInnerHTML={{ __html: question.question.replace(this.state.keySearch, "<span style='background-color:yellow'>" + this.state.keySearch + "</span>") }}></h3>
										<p dangerouslySetInnerHTML={{ __html: question.reponse.replace(this.state.keySearch, "<span style='background-color:yellow'>" + this.state.keySearch + "</span>") }}></p>
									</div>
								)
							})
						}
					}

					let founded2 = (faq.slug.indexOf("support-information") > -1)
					if (founded2 && this.state.keySearch != undefined && this.state.keySearch != "") {
						const faqinfosFilter = faq.info.filter(item => {
							return (
								<div>
									item.title.toLowerCase().includes(this.state.keySearch.toLowerCase())
									item.text.content.toLowerCase().includes(this.state.keySearch.toLowerCase())
								</div>
							)
						})
						total2 = faqinfosFilter.length
						content = faqinfosFilter.map(item => {
							return (
								<div>
									<div className="col-md-5 float-left">
										<p dangerouslySetInnerHTML={{ __html: item && item.title && item.title.replace(this.state.keySearch, "<span style='background-color:yellow'>" + this.state.keySearch + "</span>") }}></p>
									</div>
									<div className="col-md-7 float-left">
										{item && item.text && item.text.map((text) =>
											<p dangerouslySetInnerHTML={
												{
													__html: text && text.content && text.content.replace
														(this.state.keySearch, "<span style='background-color:yellow'>" + this.state.keySearch + "</span>")
												}
											}>
											</p>)
										}
									</div>
								</div>
							)
						})
					}
				}
			})
		}

		const insideHeader = (
			<MenuSupport />
		);

		return (
			<>
				<Helmet>
					<title>Support – WK®</title>
					<meta name="Support" content="Page Support de welt." />
				</Helmet>
				<HeaderIndex />
				<div className="col-md-12 short-story scroll-top-div">
					<div className="col-md-10 container-center search-support">

						<h2 className="title-big">What are you searching for?</h2>

						<form name>
							<input type="text" onChange={this.inputsaerch} className="form-text" placeholder="Please, just ask…" />
							<span name id className="form-submit"></span>
						</form>

						<div className="col-md-10 container-center search-support padding-t-50">
							{total || total2 ?
								<div className="col-md-4 float-left">
									<p className="pall">{total + total2} results</p>
								</div> : ""
							}
							<div className="col-md-8 float-left">
								<div className="results">
									{displayContent}
									{content}
								</div>
							</div>
						</div>

					</div>
				</div>
				<Cart />
				<User />
				{displayFooter}
			</>)
	}
}
Index.propTypes = {
	getPages: PropTypes.func.isRequired,
	getFaq: PropTypes.func.isRequired,
}
const mapStateToprops = (state) => ({
	pages: state.pages,

})
export default connect(mapStateToprops, { getPages, getFaq })(Index)