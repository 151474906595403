import React, { Component } from 'react';
import HeaderIndex from "../../components/support/header";
import FooterIndex from "../_includes/footer/FooterSanaHeader";
import MenuSupport from "../_includes/menu/support";
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Cart from "../_includes/lastProduits/Cart"
import User from "../_includes/login/Login"
import Spinner from '../utils/Spinner'
import { getPages, getFaq } from '../../actions/pagesAction'
import { Helmet } from 'react-helmet';
import $ from 'jquery';

class Faq extends Component {
	componentDidMount() {
		$('html,body').animate({
      scrollTop: 0
    }, 'slow');

		this.props.getPages()
		this.props.getFaq()
	 $("body.body").removeClass("body-lock")

	 $(".cart, .account, .account-offine, .menu").on('click', function(){
		$(".top-header").toggleClass("dark")
		return false
	})
	$('li a.Menu6').addClass("activeMenu")
	const s = document.createElement('script');
		s.type = 'text/javascript';
		s.async = true;
		s.innerHTML = `
        jQuery(function () {
        $(".menu-my-account").idTabs({});
      });
    `;
		document.body.appendChild(s);
	}
	render() {

		const { pages } = this.props;
		let displayFooter;
		let displayContent, total = 0, displayOrder, displayDelivery, displayReturn, contentTitle, orderTitle, deliveryTitle, returnTitle ;
		if (pages.pageData === undefined || pages.pageData === null || pages.loading) {
			displayContent  = <Spinner />
			displayOrder    = <Spinner />
			displayDelivery = <Spinner />
			displayReturn   = <Spinner />
			contentTitle    = <Spinner />
			orderTitle      = <Spinner />
			deliveryTitle   = <Spinner />
			returnTitle     = <Spinner />
		} else {
			let foundFooter = false;
			displayFooter = pages && pages.pageData.map((page, i) => {
				if (pages.slug !== null) {
					let founded = (page.slug.indexOf("support-faq") > -1);
					if (founded) {
						foundFooter = true;
						return (
							<FooterIndex
								key={i}
								footer={page}
							/>
						)
					}
				}
				total = page.total
			})


			if (!foundFooter) {
				displayFooter = <FooterIndex />
			}
		}

		if (pages.faqData === undefined || pages.faqData === null || pages.faqLoading) {
			displayContent  = <Spinner />
			displayOrder    = <Spinner />
			displayDelivery = <Spinner />
			displayReturn   = <Spinner />
			contentTitle    = <Spinner />
			orderTitle      = <Spinner />
			deliveryTitle   = <Spinner />
			returnTitle     = <Spinner />
		} else {

			pages && pages.pageData && pages.pageData.map((faq, i) => {
				if (faq.slug !== null) {
					let founded = (faq.slug.indexOf("support-faq") > -1)
					if (founded) {
						total         = faq.total
						contentTitle  = (<p dangerouslySetInnerHTML={{__html:faq.general.title}}></p>)
						orderTitle    = (<p dangerouslySetInnerHTML={{__html:faq.order.title}}></p>)
						deliveryTitle = (<p dangerouslySetInnerHTML={{__html:faq.delivery.title}}></p>)
						returnTitle   = (<p dangerouslySetInnerHTML={{__html:faq.return.title}}></p>)
						displayContent = faq.general.question_reponse.map((question) => {
							return (
								<div className="contact-mails">
									<h3>{question.question}</h3>
									<p dangerouslySetInnerHTML={{__html:question.reponse}}></p>
								</div>
							)
						})
						displayDelivery = faq.delivery.question_reponse.map((question) => {

							return (
								<div className="contact-mails">
									<h3>{question.question}</h3>
									<p dangerouslySetInnerHTML={{__html:question.reponse}}></p>
								</div>
							)
						})
						displayOrder = faq.order.question_reponse.map((question) => {

							return (
								<div className="contact-mails">
									<h3>{question.question}</h3>
									<p dangerouslySetInnerHTML={{__html:question.reponse}}></p>
								</div>
							)
						})
						displayReturn = faq.return.question_reponse.map((question) => {

							return (
								<div className="contact-mails">
									<h3>{question.question}</h3>
									<p dangerouslySetInnerHTML={{__html:question.reponse}}></p>
								</div>
							)
						})
					}
				}
			})
		}


		const insideHeader = (
			<MenuSupport />
		);
		return (
		<>
			<Helmet>
					<title>FAQ – WK®</title>
					<meta name="Support-FAQ" content="FAQ – WK®" />
				</Helmet>
			<HeaderIndex/>
			<div className="col-md-12 clearfix scroll-top-div">
				<div className="col-md-10 container-center tab-list">
					<div className="menu-account">
						<ul className="d-flex-row menu-my-account">
							<li><a href="#general">General</a></li>
							<li><a href="#order">order</a></li>
							<li><a href="#delivery">typefaces</a></li>
							<li><a href="#return">club.weltkern</a></li>
						</ul>
					</div>
				</div>
				{/* About */}
				<div className="col-md-12 list-account" id="general">
					<div className="col-md-10 container-center bg-blanc padding-t-10 padd-t12">
						<div className="col-md-10 container-center">
							<div className="col-md-5 float-left">
								{contentTitle}
							</div>
							<div className="col-md-7 float-left">
								{displayContent}
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-12 list-account" id="order">
					<div className="col-md-10 container-center bg-blanc padding-t-10">
						<div className="col-md-10 container-center">
							<div className="col-md-5 float-left">
								{orderTitle}
							</div>
							<div className="col-md-7 float-left">
								{displayOrder}
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-12 list-account" id="delivery">
					<div className="col-md-10 container-center bg-blanc padding-t-10">
						<div className="col-md-10 container-center">
							<div className="col-md-5 float-left">
								{deliveryTitle}
							</div>
							<div className="col-md-7 float-left">
								{displayDelivery}
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-12 list-account" id="return">
					<div className="col-md-10 container-center bg-blanc padding-t-10">
						<div className="col-md-10 container-center">
							<div className="col-md-5 float-left">
								{returnTitle}
							</div>
							<div className="col-md-7 float-left">
								{displayReturn}
							</div>
						</div>
					</div>
				</div>
				{/* About */}
			</div>
			{displayFooter}
			<Cart />
			<User />
		</>)
	}
}
Faq.propTypes = {
	getPages: PropTypes.func.isRequired,
	getFaq  : PropTypes.func.isRequired,
}
const mapStateToprops = (state) => ({
	pages: state.pages,

})
export default connect(mapStateToprops, { getPages, getFaq })(Faq)
