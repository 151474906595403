import { GET_ADRESSES, ADRESSES_LOADING, DELET_ADRESSES } from "./types";
import API, { subUrl } from "../utils/api";
export const getAdresses = () => (dispatch) => {
  dispatch(setAdressesLoading());
  API.get(subUrl.adresses)
    .then((res) => {
      dispatch({
        type: GET_ADRESSES,
        payload: res.data,
      });
    })
    .catch((error) => console.log(error));
};
export const newAdress = (adressData) => {
  API.post(subUrl.updateAdress, adressData)
    .then((res) => console.log(res))
    .catch((error) => console.log(error));
};

export const editAdress = (adressData) => {
  API.put(subUrl.updateAdress + "/" + adressData.id, adressData)
    .then((res) => console.log(res))
    .catch((error) => console.log(error));
};

export const deleteAdress = (index, adress) => (dispatch) => {
  dispatch(setAdressesLoading());
  API.delete(subUrl.adresses + "/" + index)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: DELET_ADRESSES,
          payload: adress,
        });
      }
    })
    .catch((error) => console.log(error));
};
export const changeAddress = (address) => (dispatch) => {
  dispatch({ type: "CHANGE_ADDRESS", payload: address });
};

export const changeAddressFacturation = (address) => (dispatch) => {
  dispatch({ type: "CHANGE_ADDRESS_FACTURATION", payload: address });
};

export const setAdressesLoading = () => {
  return {
    type: ADRESSES_LOADING,
  };
};
