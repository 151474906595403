import {GET_ORDERS,  ORDERS_LOADING,SET_CURRENT_ORDER,SET_STATUS, PAYED_LOADING, INIALIZE_CHECKOUT} from '../actions/types'

const initialState = { 
    orders: null,
    loading: false,
    sendedOrder: null,
    status: 'failed',
    loadingPayment: false
}

export default function (state= initialState, action)  {
    switch (action.type) {
        case ORDERS_LOADING:
            return {
                ...state,
                loading: true
            };
        case GET_ORDERS:
            console.log(action.payload)
            return {
                ...state,
                orders: action.payload,
                loading: false
            };
        case SET_CURRENT_ORDER :
            console.log(action.payload)
            return {
                ...state,
                sendedOrder: action.payload,
            }
        case PAYED_LOADING:
                return {
                    ...state,
                    loadingPayment: true
                };
        case SET_STATUS :
            return {
                ...state,
                status: action.payload,
                loadingPayment: false
            }
            case INIALIZE_CHECKOUT :
            return {
                ...state,
                orders: null,
                loading: false,
                sendedOrder: null,
                status: 'failed',
                loadingPayment: false
            }
        default: 
           return state
    }

}