import { GET_CATEGORIES, CATEGORIES_LOADING } from "./types";
import API, { subUrl } from "../utils/api";
export const getCategories = () => (dispatch) => {
  dispatch(setCategoriesLoading());
  API.get(subUrl.categories)
    .then((res) => {
      dispatch({
        type: GET_CATEGORIES,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log(error);
      localStorage.clear();
      // window.location.reload()
    });
};

// Categorie loading

export const setCategoriesLoading = () => {
  return {
    type: CATEGORIES_LOADING,
  };
};
