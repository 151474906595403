import {TOGGLE_FAVORITE,  FAVORITES_LOADING} from '../actions/types'

const initialState = { 
    favorites: null,
    loadingFavorites: false
}

export default function (state= initialState, action)  {
    switch (action.type) {
        case FAVORITES_LOADING:
            return {
                ...state,
                loadingFavorites: true
            };
        case TOGGLE_FAVORITE:
            return {
                ...state,
                favorites: action.payload,
                loadingCategories: false
            };
        default: 
           return state
    }

}