import $ from 'jquery';
import React, { Component } from 'react';
import Moment from "react-moment";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import styled from 'styled-components';
import { getFavorite } from '../../actions/favoriteAction';
import { getPages } from '../../actions/pagesAction';
import { getProductById, getProducts, getProductsOnPage } from '../../actions/productsActions';
import WKch from '../../assets/images/WK_ch.svg';
import WKeu from '../../assets/images/WK_eu.svg';
import WKworld from '../../assets/images/WK_world.svg';
import history from "../../utils/history";
import isEmpty from '../../validation/is-empty';
import SimplePost from '../posts/SimplePost';
import Spinner from '../utils/Spinner';
import FooterIndex from "../_includes/footer/FooterIndex";
import HeaderIndex from '../_includes/header/Indexsanslogo';
import Cart from '../_includes/lastProduits/Cart';
import Modal from '../_includes/lastProduits/modal/Modal';
import OverviewProduct from '../_includes/lastProduits/OverviewProduct';
import ProductPaginate from '../_includes/lastProduits/productPaginate';
import Supply from '../_includes/lastProduits/Supply';
import User from '../_includes/login/Login';
import Overview from './Overview';

class Details extends Component {

	constructor(props) {
		super(props)
		this.state = {
			value: 10,
			currentData: null,
			activePage: 1,
			disabled: false,
			total: '',
			isShowing: false,
			productsRandom: [],
			informationClicked: true,
			storyClicked: false,
			downloadClicked: false,
			relatedClicked: false,
			down: 'none',
			show: 'block',
		}
	}

	findItem = (cart, id) => {
		return cart.filter(cartItem => cartItem.id === id)
	}

	_displayFavoriteImage(product) {
		if (product != null) {
			var sourceImage = require('../../assets/images/heart-like.png')
			if (this.props.favorites.favorites.findIndex(item => item.id === product.id) !== -1) {
				sourceImage = require('../../assets/images/heart-dislike.png')
			}
			return (
				<img
					src={sourceImage}
					width="17"
					height="17" alt="welt"
				/>
			)
		} else {
			return <Spinner />
		}

	}
	componentDidMount() {
		this.initData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			// window.location.reload();
			// this.forceUpdate();
			this.initData();
		}
	}

	initData() {
		if ($('.scroll-top-div').length) {

			var fixmeTop = $('.scroll-top-div').offset().top ? $('.scroll-top-div').offset().top + 800 : 800;

			$(window).scroll(function () {
				var currentScroll = $(window).scrollTop();
				if (currentScroll >= fixmeTop) {
					$('.menu-page-details').addClass('dark');
					$('.top-header').addClass('dark');
				} else {
					$('.menu-page-details').removeClass('dark');
					$('.top-header').removeClass('dark');
				}
			});

		}

		$('html,body').animate({
			scrollTop: 0
		}, 'slow');

		$(".top-header-right").removeClass("grise")

		const s = document.createElement('script');
		s.type = 'text/javascript';
		s.async = true;
		s.innerHTML = `
        jQuery(function () {
        $(".menu-my-account").idTabs({});
      });
    `;

		document.body.appendChild(s);
		const { currencies, currentLange } = this.props;
		const { currencyChecked } = currencies;
		const { language } = currentLange;
		const slug = this.props.match.params.id
		this.props.getPages()
		this.props.getProductsOnPage(4, 1)
		//this.props.getFavorite();
		this.props.getProductById(slug, language)
		this.props.getProducts(language, currencyChecked.key);
		//history.push(`/shop/detail-product/${slug}`)

		class Parallax {
			constructor(object) {
				this.object = object;
				this.offset = this.object.offset().top;
				this.object.css("background-position-y", `${this.offset + $(window).scrollTop() / 4}px`)
			} parallax() {
				this.object.css("background-position-y", `${this.offset + $(window).scrollTop() / 4}px`)
			}
		}

		var parallax = new Parallax($('.parallax'));
		$(window).scroll(function () {
			parallax.parallax()
		})
	}

	openModalHandler = () => {
		this.setState({
			isShowing: true
		});
		document.body.classList.add("no-sroll")
	}

	closeModalHandler = () => {
		this.setState({
			isShowing: false
		});
		document.body.classList.remove("no-sroll")
	}

	toIndex = () => {

		let page = (this.props.products.products.filter((product) =>
			product.choice_product !== "Typeface"
		))[0]
		if (page !== undefined && page.slug !== undefined) {
			history.push("/shop/detail-product/" + page.slug);
			// window.location.reload()
			// this.forceUpdate();
			this.initData();
		}
		else {
			this.setState({ disabled: true })
		}
		this.setState({ activePage: 1, disabled: false, productsRandom: [] })
		this.props.getProductsOnPage(4, 1);
	}

	informationClicked = () => {
		this.setState({
			informationClicked: true,
			storyClicked: false,
			relatedClicked: false
		});
	}

	estimation(date) {
		const newDate = new Date();
		const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
		newDate.setDate(newDate.getDate() + parseInt(date) + 1);
		return newDate;
	}

	now() {
		const newDate = new Date();
		const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
		newDate.setDate(newDate.getDate() + 1);

		return newDate
	}

	storyClicked = () => {
		this.setState({
			informationClicked: false,
			storyClicked: true,
			relatedClicked: false
		});
	}

	relatedClicked = () => {
		this.setState({
			informationClicked: false,
			storyClicked: false,
			relatedClicked: true
		});
	}

	nextProduct = () => {

		const { products } = this.props;
		let total = (products.total / 1) + 1
		let nextProduct = this.state.activePage + 1

		this.setState({ activePage: nextProduct, productsRandom: [] })
		if (total >= nextProduct) {
			this.setState({ disabled: false })
			let page = (this.props.products.products.sort((a, b) => b.id - a.id).filter((product) =>
				product.id !== this.props.products.product[0].id && product.choice_product !== "Typeface" && product.id < this.props.products.product[0].id
			))[0]
			if (page !== undefined && page.slug !== undefined) {
				history.push("/shop/detail-product/" + page.slug);
				// window.location.reload()
				// this.forceUpdate();
				this.initData();
			}
			else {
				this.setState({ disabled: true })
			}
			this.props.getProductsOnPage(1, nextProduct);
		}
		else {
			this.setState({ disabled: true })
		}
	}

	randomProducts = () => {
		//random
		let dataproduct = (this.props.products.products.filter((product) =>
			product.id !== this.props.products.product[0].id && product.choice_product !== "Typeface"
		));
		let datalength = dataproduct.length;
		let productRandom = [];
		for (var i = 0; i < datalength - 1; i++) {
			productRandom.push(dataproduct.splice(Math.floor(Math.random() * dataproduct.length), 1)[0]);
		}

		if (dataproduct !== undefined && dataproduct[0].slug !== undefined) {
			history.push("/shop/detail-product/" + dataproduct[0].slug);
			// window.location.reload()
			// this.forceUpdate();
			this.initData();
		}
		else {
			this.setState({ disabled: true })
		}
	}

	render() {
		const { products, currentProduct, pages, currencies } = this.props;
		const { currencyChecked } = currencies
		const paramsID = this.props.match.params.id
		let product = null
		const simplePost = [];
		let images, productsItems, productsRandom, displayFooter, ProductsTypeface, postdata, intro__option1, intro__author, detaillist, contentdata, intro__option2, estimation_livraison;

		if (pages.pageData === undefined || pages.pageData === null || pages.loading) {
			displayFooter = <Spinner />
		} else {
			let foundFooter = false;
			displayFooter = pages.pageData && pages.pageData[0] && pages.pageData.map((page, i) => {
				if (pages.slug !== null) {
					let founded = (page.slug.indexOf("shop") > -1);
					if (founded) {
						foundFooter = true;
						return (
							<FooterIndex
								key={i}
								footer={page}
							/>
						)
					}
				}
			})

			if (!foundFooter) {
				displayFooter = <FooterIndex />
			}
		}
		const settingsCaroussel = {
			dots: false,
			slidesToShow: 4,
			infinite: false,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						infinite: false,
						dots: false
					}
				},
				{
					breakpoint: 768,
					settings: {
						dots: false,
						slidesToShow: 2,
						infinite: false,
					}
				},
				{
					breakpoint: 480,
					settings: {
						dots: false,
						slidesToShow: 1,
						infinite: false,
					}
				}
			]
		};
		const productInCart = this.findItem(this.props.cart.cartItems, paramsID)[0]
		if (productInCart === undefined) {
			if (products.product !== null && products.product !== undefined) {
				product = products.product[0]
			}
		} else {
			product = productInCart
		}
		if (product !== null && product !== undefined) {
			if (product.gallery_image && product.gallery_image.length > 0 && typeof product.gallery_image === 'object') {

				images = product && product.gallery_image && product.gallery_image.map((image, i) => {
					return (<div className="img-l-full">
						<img src={image.url} key={i} alt={image.alt} />
					</div>)
				})
			}
		} else {
			images = <p>empty</p>
		}

		if (products.products === null) {
			productsItems = <Spinner />;
			productsRandom = <Spinner />;
		} else {
			if (products.products.length > 0) {

				productsItems = products && products.products[0] && products.products.map((product, i) => {
					if (paramsID !== product.slug && product.choice_product !== "Typeface") {
						return (
							<Supply
								key={i}
								products={product}
								addToCart={this.props.addToCart}
								removeFromCart={this.props.removeFromCart}
								getFavorite = {this.props.getFavorite}
								favorites={this.props.favorites.favorites}
								getCurentProduct={this.props.getCurentProduct}
								openModal={this.openModalHandler}
								cartItem={this.props.cart.cartItems.filter(cartItem => cartItem.id === product.id)[0]}
								currencyChecked={this.props.currencies.currencyChecked}
							/>
						)
					}

				})

				if (product !== undefined) {
					const cat = product && product.categories && product.categories[0] && product.categories[0].slug;
					const brand = product && product.header && product.header !== false && product.header[0] && product.header[0].header !== null && product.header[0].header.author_information !== null && product.header[0].header.author_information.author !== null ? product.header[0].header.author_information.author.name : '';
					const id = product && product.id;
					ProductsTypeface = products.products.map((product, index) => {
						if (product.categories !== null && product.categories !== undefined) {
							if (product.header !== false && product.header[0] && product.header[0].header !== null && product.header[0].header.author_information !== null && product.header[0].header.author_information.author !== null && product.header[0].header.author_information.author.name === brand && product.id !== id)
								for (let i = 0; i < product.categories.length; i++) {
									if (product.categories[i].slug === cat) {
										return (
											<Supply
												key={index}
												products={product}
												addToCart={this.props.addToCart}
												removeFromCart={this.props.removeFromCart}
												getFavorite      = {this.props.getFavorite}
												favorites={this.props.favorites.favorites}
												getCurentProduct={this.props.getCurentProduct}
												openModal={this.openModalHandler}
												cartItem={this.props.cart.cartItems.filter(cartItem => cartItem.id === product.id)[0]}
												currencyChecked={this.props.currencies.currencyChecked}
											/>
										)
									}
								}
						}
					})
				}

			}
		}
		if (products.someProducts === null) {
			productsItems = <Spinner />;
			productsRandom = <Spinner />;
		} else {
			if (products.someProducts.length > 0) {
				if (this.state.productsRandom.length) {
					productsRandom = this.state && this.state.productsRandom && this.state.productsRandom.map((product, i) => {
						if (paramsID !== product.slug) {
							return (
								<ProductPaginate
									key={i}
									products={product}
									addToCart={this.props.addToCart}
									removeFromCart={this.props.removeFromCart}
									toggleFavorite={this.props.toggleFavorite}
									getFavorite      = {this.props.getFavorite}
									favorites={this.props.favorites.favorites}
									getCurentProduct={this.props.getCurentProduct}
									openModal={this.openModalHandler}
									cartItem={this.props.cart.cartItems.filter(cartItem => cartItem.id === product.id)[0]}
								/>
							)
						}

					})

				} else {
					productsRandom = products && products.someProducts[0] && products.someProducts.map((product, i) => {
						if (paramsID !== product.slug) {
							return (
								<ProductPaginate
									key={i}
									products={product}
									addToCart={this.props.addToCart}
									removeFromCart={this.props.removeFromCart}
									getFavorite      = {this.props.getFavorite}
									toggleFavorite={this.props.toggleFavorite}
									favorites={this.props.favorites.favorites}
									getCurentProduct={this.props.getCurentProduct}
									openModal={this.openModalHandler}
									cartItem={this.props.cart.cartItems.filter(cartItem => cartItem.id === product.id)[0]}
								/>
							)
						}
					})
				}
			}
		}
		const settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: false,
		};
		if (product !== null) {
			console.log("product", product)
			if (product.estimation_de_livraison && product.estimation_de_livraison.length > 0) {
				estimation_livraison = product && product.estimation_de_livraison && product.estimation_de_livraison.map((pays) =>
					<div className="delivery__national">
						<div className="pays-icon">
							<div class="icon">
								{pays.pays === "Suisse" && <img src={WKch} />}
								{pays.pays === "Other" && <img src={WKworld} />}
								{pays.pays === "Europe" && <img src={WKeu} />}
							</div>
							<div className={`date ${product?.quantity <= 0 && product?.backorder_check == "yes" && 'out-of'}`}>
								<Moment format="MMM DD">{this.now()}</Moment>
									-
								<Moment format="MMM DD">{this.estimation(pays.nombre_de_jours)}</Moment>
							</div>
						</div>
					</div>
				)
			}
			if (product.content_story !== false && product.content_story !== undefined) {
				if (product.content_story[0] !== null && product.content_story[0] !== false) {
					postdata = product.content_story.map((block_text, i) => {
						return (
							/* ============================ Slider ============================ */
							block_text.acf_fc_layout === "block_slide" ?
								<Components.Slider className="col-md-12 block-slide-image" key={i}>
									<div className="img-l-full">

										<Slider {...settings}>
											{block_text.slide.map((image, i) => {
												return (
													<img src={image} width="100%" />
												)
											}
											)}

										</Slider>
									</div>
								</Components.Slider>
								:
								/* ============================ Text full width –– Left justified  ============================ */
								block_text.acf_fc_layout === "content_blocks" ?
									<Components.TextFullWidthLeft className="col-md-10 text text--full-width container-center bg-blanc padding-t-20 padding-b-80 " key={i}>
										<div className="col-md-12 container-center text__content">
											<div dangerouslySetInnerHTML={{
												__html: block_text.content_block.map((block, i) => {
													return (
														block.content_block_column
													)
												}
												)
											}}>

											</div>
										</div>
									</Components.TextFullWidthLeft>
									:
									/* ============================ Full width image ============================ */
									block_text.acf_fc_layout === "block_full_image" ?
										<Components.FullWidthImage className="col-md-12" key={i}>
											<div className="img-l-full">
												<img src={block_text.full_image} width="100%" />
											</div>
										</Components.FullWidthImage>
										:
										/* ============================ Publication ============================ */
										block_text.acf_fc_layout === "block_post" ?
											<Components.Publications className="col-md-12 padding-t-b-l-r-8 overflow-h align-center bg-corps">
												{/* Produits items */}
												{simplePost.length > 0 && simplePost.map((post, i) => {
													while (i < 3) {
														return (
															<SimplePost key={i} post={post} />
														)
													}
												})
												}

												{/* Produits items */}
												<div className="nav-between-produits col-md-12">
													<div className="col-md-12 align-center">
														<a className="nav-btn-top" />
														<ul>
															{/* <li><a className="back-home">Back to index</a></li> */}
															<li>
																<Link to={{ pathname: `/newspaper` }}>More articles </Link>
															</li>
															{/* <li><a className="next">Next article</a></li> */}
														</ul>
													</div>
												</div>
											</Components.Publications>

											:
											/* ============================ Two columns –– Centered + Colored ============================ */
											block_text.acf_fc_layout === "text_with_color" ?
												<Components.TwoColsCenteredColors className="col-md-12 short-story bloc__2cols--centered" key={i} style={{ backgroundColor: block_text.color }}>
													<div className="col-md-10 container-center no-paddings" style={{ backgroundColor: block_text.color }}>
														<div className="col-md-10 list-account bloc__cols no-paddings" style={{ backgroundColor: block_text.color }}>
															<div classNames="col-md-10 container-center cols no-paddings" style={{ backgroundColor: block_text.color }}>
																<div className="col-md-5 float-left col__title">
																	<p style={{ color: block_text.text_color }}>{block_text.titre}</p>
																</div>
																<div className="col-md-7 float-left col__content">
																	<p style={{ color: block_text.text_color }}>{block_text.content}</p>
																</div>
															</div>
														</div>
													</div>
												</Components.TwoColsCenteredColors>
												:
												/* ============================ Random products preview ============================ */
												block_text.acf_fc_layout === "product_blocks" ?
													<Components.RandomProducts className="last-addition-shop">
														<div className="menu-addition related-produits">
															<p>You may also like these beautiful products?</p>
														</div>
														<div className="items-last-add align-center">
															{

																//productsItems.props.products.filter(filtre => block_text.produit.includes(filtre.id))
															}
															{/* Items */}

															<Slider {...settingsCaroussel}>
																{productsItems}
															</Slider>
															{/* End Items */}
														</div>
													</Components.RandomProducts>
													:
													''
						)

					})
				}

			}
			if (product.header[0] !== null) {
				if (product.header[0].header !== null && product.header[0].header !== false) {
					if (product.header[0].header.block_option !== false)
						contentdata = product && product.header[0] && product.header[0].header && product.header[0].header.block_option.map((block_option, i) => {
							return (
								<div className="composants product__details">
									<span>{block_option.option}</span>
									{block_option.value}
								</div>
							)

						})

					if (product.header[0].header.block_option_center !== false)
						intro__option1 = product.header[0].header.block_option_center.map((block_option_center, i) => {
							return (
								<div className="composants product__details">
									<span>{block_option_center.option}</span>
									{block_option_center.value}
								</div>
							)

						})

					if (product.header[0].header.block_option !== false)
						intro__option2 = product.header[0].header.block_option.map((block_option, i) => {
							return (
								<div className="composants product__details">
									<span>{block_option.option}</span>
									{block_option.value}
								</div>
							)

						})

					intro__author = (
						<Components.AuthorInfos className="top-info-ds col-md-12">
							<div className="col-md-12 float-left">
								<div className="col-md-12">
									<div className={`tag fournisseur produit ${product.header[0].type_author !== undefined && product.header[0].type_author !== false && product.header[0].type_author !== null && product.header[0].type_author}`}>
										{product.header[0].image_author !== undefined ? product.header[0].image_author !== false ?
											<img src={product.header[0].image_author} alt="Welt" />
											:
											''
											:
											''
										}
										<div className="info author__infos">
											{product.choice_product === "Physical" ?
												''
												:
												<p className="infos__designed-by text text--xsmall">Designed by</p>
											}

											<p className="infos__name text text--xsmall">{product.header !== false && product.header[0] && product.header[0].header != null && product.header[0].header.author_information != null && product.header[0].header.author_information.author != null ? product.header[0].header.author_information.author.name : ''}</p>
										</div>
									</div>
									<div className="col-md-12">
										{product.header[0].header.tags !== undefined && product.header[0].header.tags !== false ? product.header[0].header.tags.map((tag) => {
											return (<>
												<div className="tag">{tag.name}</div>
											</>)
										}) : ''}

									</div>
								</div>
							</div>
						</Components.AuthorInfos>
					)
					detaillist = product.header[0].header.text_grand
				}
			}

		}


		return (
			<>

				<HeaderIndex col={product === null ? null : product && product.header_color} dark = {product && product.featured_image && product.featured_image.url === false ? "false" : ''}/>
				<div className="col-md-12 relative">
					<div className="img-articles-top" style={{height : `${product && product.featured_image && product.featured_image.url === false && '15px'}`}}>
						
						<div  className={`img parallax ${product && product.featured_image && product.featured_image.url === false ? "false" : ''}`} style={{
							backgroundImage: product === null ? null :
								`url(
							${product && product.featured_image && product.featured_image.url})`, display : `${product && product.featured_image && product.featured_image.url === false && 'none'}`
						}}>
							{product === null ? null : <img src={product.featured_image.url} alt="Welt" style={{ opacity: 0 }} />}
							<div className="release-info d-flex-column-c here">
								<div className="container-center col-md-10">
								</div>
							</div>
						</div>
					</div>

					<div className={`col-md-12 menu-page-details ${product && product.featured_image && product.featured_image.url === false ? "false" : ''} ${product === null ? null : product.header_color}`}>
						<div className="breadcrumbs">
							<div className='col-md-6 float-left bgNoir'>
								<ul>
									<li>
										{/* <Link to={{ pathname: `/`, }}><img src="/images/welt-breadcrumb-noir.svg" alt="Welt" /></Link> */}
										<Link to={{ pathname: `/`, }}>WELTKERN®</Link>
									</li>

									<li>
										<Link to={{ pathname: `/shop`, }}>	Shop</Link>
									</li>

									{product === null ?
										null :
										<>
										<li>
										<Link to={{ pathname: `/shop/${product.categories && product.categories[0] && product.categories[0].slug === "books" ? "books" : product.categories[0].slug === "stationery" ? "stationery" : product.categories[0].slug === "others" ? "other" : product.categories[0].slug === "supply" ? "supplies" : product.categories[0].slug === "rares" ? "rare" : product.categories[0].slug === "apparel" ? "apparel" : "#"}`, }}>	{product.categories && product.categories[0] && product.categories[0].name}</Link>
										</li>
										<li>
											<Link to={{ pathname: `/shop/detail-product/${paramsID}`, }} dangerouslySetInnerHTML={{ __html: product.name }}>

											</Link>
										</li>
										</>
									}
								</ul>
							</div>
						</div>
					</div>
					<div className="scroll-top-div col-md-12"></div>

				</div>
				{product !== null &&
					<Overview
						product={product}
						addToCart={this.props.addToCart}
						addRangeValueToReducer={this.props.addRangeValueToReducer}
						cart={this.props.cart}
						getFavorite      = {this.props.getFavorite}
						favorites={this.props.favorites.favorites}
						removeFromCart={this.props.removeFromCart}
						currencyChecked={currencyChecked}
						dark = {product && product.featured_image && product.featured_image.url === false ? "false" : ''}
						cartItem={this.props.cart.cartItems.filter(cartItem => cartItem.id === product.id)[0]}
					/>
				}
				<Detail.Content className="col-md-12 clearfix story-tab">
					<Detail.Navbar className="col-md-10 container-center tab-list">
						<div className="menu-account">
							<ul className="d-flex-row menu-my-account uppercase">
								<li><a href="#specificities" onClick={this.informationClicked}>Informations and Specificities</a></li>

								<li style={product === null ? { display: this.state.down } : product.content_story !== false && product.display_story !== false ? { display: this.state.show } : { display: this.state.down }}><a href="#story" onClick={this.storyClicked}>Story</a></li>

								<li><a href="#related" onClick={this.relatedClicked}>Related</a></li>
							</ul>
						</div>
					</Detail.Navbar>
					{/* Informations and Specificities */}
					<Detail.About className="col-md-12 list-account" id="specificities" style={{ display: this.state.informationClicked ? 'block' : 'none' }}>
						<div className="col-md-10 container-center bg-blanc">
							<div className="top-info-ds col-md-12">
								<div className="intro__author col-md-6">
									{intro__author}
								</div>
								<div className="intro__option1 col-md-6">
									<div className="col-md-6">
									<div className="availability-txt">
									{product?.quantity>0 && product?.backorder_check == "yes" ?
									<>
									<p className="txt-gray">Availability</p>
									<p className="title">In stock 🥰</p>
									</>
									:
									product?.quantity>0 && product?.backorder_check != "yes" ? 
									<>
									<p className="txt-gray">Availability</p>
									<p className="title">Last available piece(s) 🤯</p>
									</>
									:
									product?.quantity <= 0 && product?.backorder_check == "yes" ? 
									<>
									<p className="txt-gray">Availability</p>
									<p className="title-orange">Item available on Backorder 👆🏻</p>
									</>
									:
									<>
									<p className="txt-gray">Availability</p>
									<p className="title-red">Out of Stock </p>
									<p className="txt-ship">
									<span className="welt-txt">
										Stay tuned, we could maybe have this item later on!
										Follow our <a target="_blank" href="https://www.instagram.com/weltkern/">Instagram</a> page
										to keep you updated.
									</span>
									</p>
									</>
									}
									</div>
									{product?.quantity <= 0 && product?.backorder_check != "yes" ? 
										''
										:
										<>
										{intro__option1}

										{estimation_livraison}
										</>
										
										}

									</div>
									<div className="col-md-6">
										{intro__option2}
									</div>
								</div>
							</div>
							<div class="col-md-12">
								<div class="container-center list-account details-about">
									<div class="top-list col-md-12 container-center">
										{product != null ?
											<div className="detailText" dangerouslySetInnerHTML={{ __html: product.header[0].header.text_grand }}></div>
											:
											''
										}
									</div>
								</div>
							</div>
						</div>
					</Detail.About>
					{/* Informations and Specificities */}

					{/* Story */}
					{/* <div className="col-md-12 short-story" id="story" style={{ display: this.state.storyClicked ? 'block' : 'none' }}>
						{product === null ? null : <div className="detailText" dangerouslySetInnerHTML={{ __html: product.description }}></div>}

						{postdata}
					</div> */}
					{product !== null && product.content_story !== false && product.content_story !== null && product.display_story ?
						<Detail.About className="col-md-12 short-story" id="story" style={{ display: this.state.storyClicked ? 'block' : 'none' }}>

							{postdata}
						</Detail.About>
						:
						""
					}

					{/* Story */}

					{/* Related  */}
					<div className="col-md-12 list-account" id="related" style={{ display: this.state.relatedClicked ? 'block' : 'none' }}>
						<div className="col-md-12 related-img relative">
							<div className="profile-photo">
								<div className="col-md-10 container-center">
									<div className="img-profil">
										<img src={product !== null && product.header !== false && product.header[0].image_author ? product.header[0].image_author : ''} alt="Welt" />
									</div>
								</div>
							</div>
							<div className="img-l-full">
								<img src={product !== null && product.header !== false && product.header[0].banner_author ? product.header[0].banner_author : ''} alt="Welt" />
							</div>
						</div>
						<div className="col-md-10 container-center bg-blanc">
							<div className="top-info-ds col-md-12">
								<div className="col-md-6 float-left">
									<div className="col-md-12">
										<div className="tag fournisseur produit">
											<div className="info author__infos">{product !== null && product.header !== false && product.header[0] && product.header[0].header !== null && product.header[0].header.author_information !== null && product.header[0].header.author_information.author !== null ? <p className="infos__name text text--xsmall">{product.header[0].header.author_information.author.name}</p> : null}</div>
										</div>
									</div>
									<div className="col-md-12">
										{product !== null && product.header !== false && product.header[0].tags_author ? product.header[0].tags_author.map((tag) => <div className="tag">{tag.name}</div>) : ''}
									</div>
								</div>
							</div>
						</div>
						{/* Produits */}
						<div className="col-md-12 items-last-add align-center" >
							{/* Produits items */}
							{ProductsTypeface}
							{/* End Produits items */}
						</div>
						{/* Produits */}
					</div>
					{/* Related  */}
				</Detail.Content>
				{/* <!-- Derniers produits ajoutés --> */}
				<div className="last-addition-shop">

					<div className="nav-between-produits col-md-12">
						<div className="col-md-12 align-center">
							<a className="nav-btn-top" />
							<ul>
								<li><a href="/shop" className="back-home">Back to index</a></li>
								<li><a onClick={this.randomProducts} className="random">Random product</a></li>
								<li>{this.state.disabled ? <a style={{ cursor: 'no-drop' }} className="next">Next product</a> : <a onClick={this.nextProduct} className="next">Next product</a>}</li>
							</ul>
						</div>
					</div>
					<div className="menu-addition related-produits">
						<p>You may also like these beautiful products?</p>
					</div>
					<div className="items-last-add align-center">
						{productsRandom}
					</div>
				</div>

				<Modal
					className="modal"
					show={this.state.isShowing}
					close={this.closeModalHandler}>

					<div className="modal-body clearfix quick-purchase">
						<span className="close-modal-btn" onClick={this.closeModalHandler}>×</span>
						{!isEmpty(currentProduct.currentProduct) &&
							<OverviewProduct
								currentProduct={currentProduct.currentProduct}
								addToCart={this.props.addToCart}
								cart={this.props.cart}
								toggleFavorite={this.props.toggleFavorite}
								getFavorite      = {this.props.getFavorite}
								favorites={this.props.favorites.favorites}
								removeFromCart={this.props.removeFromCart}
								cartItem={this.props.cart.cartItems.filter(cartItem => cartItem.id === currentProduct.currentProduct.id)[0]}
								currencyChecked={this.props.currencies.currencyChecked}
							/>
						}
					</div>
				</Modal>
				{displayFooter}
				<Cart />
				<User />
			</>)
	}
}

const mapStateToProps = (state) => ({
	cart: state.cart,
	products: state.products,
	favorites: state.favorites,
	pages: state.pages,
	currentProduct: state.currentProduct,
	currencies: state.currencies,
	currentLange: state.translation
})
const mapDispatchToProps = (dispatch) => {
	return {
		addToCart: (item) => {
			dispatch({ type: 'ADD', payload: item })
		},

		addRangeValueToReducer: (item) => {
			dispatch({ type: 'GET_RANGE_VALUE', payload: item })
		},

		removeFromCart: (item) => {
			dispatch({ type: 'REMOVE', payload: item })
		},

		getCurentProduct: (item) => {
			dispatch({ type: 'GET_CURRENT_PRODUCT', payload: item })
		},

		getProducts: () => dispatch(getProducts()),
		getProductById: (id, language) => dispatch(getProductById(id, language)),
		getPages: () => dispatch(getPages()),
		getProductsOnPage: (perPage, paramater) => dispatch(getProductsOnPage(perPage, paramater)),
		getFavorite: () => dispatch(getFavorite()),
	}
}


const Detail = {
	Content: styled.div`
    font-family: Lausanne600;
    `,
	Navbar: styled.div`
	@media screen and (max-width: 768px){
		height: inherit;
		.menu-account {
			ul {
				li {
					a {
						padding-top: 6px !important;
					}
				}
			}
		}
	}
    height: 50px;
    .menu-account {
        ul {
            li {
                a {
                    display: flex;
                    justify-content: center;
                    padding-left: 0;
                    padding: 0 5px;
                }
            }
        }
    }
    `,
	/* ================================== Onglet 'ABOUT' ==================================*/
	About: styled.div`
    .about__intro { padding: 0; }
    .intro__author {
        float: left;
    }


    .intro__option1 {
        float: right;
        display: flex;
        padding: 14px 20px 14px 20px;
        .product__details {
            span {
                padding-right: 5px;
                color: #D8D8D8;
            }
        }
    }
    `,
	/* ================================== Onglet 'STYLES' ==================================*/
	Styles: styled.div``,
	StylesIntro: styled.div`
    .control-font { font-family: 'Lausanne400'; }
    .flex {
        display: flex;
        justify-content: space-between;
        padding-top: 6px;
        gap: 5px;
    }
    .align-text-transform {
        .align {
            padding-top: 0 !important;
            .text-transform {
                display: flex;
                flex-wrap: wrap;
                row-gap: 5px;
                a {
                    margin-right : 0;
                }
            }
        }
    }
    .color-theme-switcher {
        .switch {
            position: relative;
            display: inline-block;
            width: 72px;
            height: 36px;

            input {
                opacity: 0;
                width: 0;
                height: 0;
                &:checked + .slider {
                    background-color: none;
                    border: 1px solid black;
                    &:before {
                        -webkit-transform: translateX(36px);
                        -ms-transform: translateX(36px);
                        transform: translateX(36px);
                    }
                }

                &:focus + .slider { box-shadow: 0 0 1px #2196F3; }
            }

            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: none;
                border: 1px solid black;
                -webkit-transition: .4s;
                transition: .4s;

                &.round  {
                    border-radius: 36px;
                    &:before {
                        border-radius: 50%;
                    }
                }

                &:before {
                    position: absolute;
                    content: "";
                    height: 100%;
                    width: 34px;
                    left: 0;
                    bottom: 0;
                    background-color: #EBEBEB;
                    -webkit-transition: .4s;
                    transition: .4s;
                }
            }
        }
    }

    `,

	StylesOverview: styled.div`
    padding-bottom: 50px;
    min-height: 450px;

    &.theme-switch-dark{
        background: #141414;
        color: white !important;
        .theme-switch-dark--colors {
            div, ul, li, p  {
                color: white !important;
            }
        }
    }
    `,

	StylesFamily: styled.div`
    .details__content {
        padding: 4px 0;
        .list__bloc:first-child { height: 155px; }
        .list__bloc:nth-child(2) { height: 100px; }
        .list__bloc { font-family: Lausanne600; }

        .specific-font {
            padding: 0 5px 5px 5px;
            .col-md-10 { padding: 0; }
            .flex {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                padding-bottom: 10px;
                .my {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                }
            }
            .font-check {
                float: right;
                text-align: right;
            }

            .leftside,
            .rightside { height: 100%; }

            .rightside {
                text-align: right;
            }
        }

        .family__styles { 
            float: left;
            p { line-height: 25px; }
        }

        .font-select { line-height: 25px; }
        .font-select, .flex { margin-top: 3px; }

        .specific-font,
        .lab {height: 100%;}
    }
    `,

	StylesCollection: styled.div`
    .collection__name {
        margin: 0 auto;
        clear: both;
        padding-top: 40px;
    }
    .list-collections__name { margin: 0 auto; }
    .list-collections__fonts {
        .list-collections__font {
            clear: both;
            &:last-child {
                .font__example { 
                    margin-bottom: 0;
                }
            }
        }
        .font__example {
            padding: 0 0 12px 12px;
            margin: 3px 0;
            p { white-space: nowrap; }
        }

        .font__select {
            font-family: Lausanne600;
            label {
                &.sign {
                    display: flex;
                    flex-direction: row-reverse;
                    flex-wrap: wrap;
                    label {
                        display: flex;
                        align-self: flex-start;
                        margin-left: 10px;
                        margin-bottom: 3px;
                    }
                }
            }
        }
    }
    `,
	/* ================================== Onglet 'LAYOUT' ==================================*/
	Layout: styled.div`
    .layout__content {
        background: #F7F7F7;

        .editable-font {
            opacity: 0;
            &:hover { opacity: 1; }
            margin-top: 30px;
        }

        .flex-reverse {
            display: flex;
            flex-direction: column-reverse;
        }
    }
    .remove { float: left; }
    .content-style {
        margin: 0 auto;
        background: inherit;
    }
    .order {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-right: 12px;
    }
    .render {
        justify-content: space-between;
        width: 100%;
        display: flex;
    }
    `,
}

const Components = {
	Slider: styled.div``,
	TextFullWidthLeft: styled.div`
    &.text--full-width {
        padding: 20px 0 80px 0;
        .text__content { padding: 0 20px; }
        p {
            font-size: 36px;
            line-height: 39px;
        }
    }
    `,
	FullWidthImage: styled.div`
    .img-l-full {
        img {
            height: auto;
        }
    }
    `,
	Publications: styled.div``,
	TwoColsCenteredColors: styled.div`
    .no-paddings { padding: 0;}
    .bloc__cols { margin: 0 auto; }
    `,

	RandomProducts: styled.div``,
	AuthorInfos: styled.div`
    .author__infos {
        text-align: center;
        .infos__designed-by { color: #595959; }
        .infos__name { color: white; }
    }
    `,
}
export default connect(mapStateToProps, mapDispatchToProps)(Details)
