import { GET_ADRESSES, ADRESSES_LOADING, DELET_ADRESSES, UPDATE_ADRESSES,CHANGE_ADDRESS_FACTURATION, ADD_ADRESSES, CHANGE_ADDRESS, GET_CUSTOMER } from '../actions/types'

const initialState = {
	adresses: null,
	loading: false,
	checkedAddress: null,
	users: null,
	checkedAddressFacturation: null
}

export default function (state = initialState, action) {
	switch (action.type) {
		case ADRESSES_LOADING:
			return {
				...state,
				loading: true
			};
		case GET_ADRESSES:
			return {
				...state,
				adresses: action.payload,
				loading: false
			};
		case DELET_ADRESSES:
			const newData = state.adresses.filter(item => item.id !== action.payload.id)
			return {
				...state,
				adresses: newData,
				loading: false
			};
		case GET_CUSTOMER:
			return {
				...state,
				users: action.payload,
				loading: false
			};
		case CHANGE_ADDRESS:
			return {
				...state,
				checkedAddress: action.payload
			};
		case CHANGE_ADDRESS_FACTURATION:
			return {
				...state,
				checkedAddressFacturation: action.payload
			};
	
		default:
			return state
	}

}