import React, { Component } from 'react';
import ProductShop from "../_includes/lastProduits/ProductShop";
import FooterIndex from "../_includes/footer/FooterSanaHeader";
import HeaderPage from "../_includes/headersPage/HeaderPage";
import Spinner from '../utils/Spinner'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getPages } from '../../actions/pagesAction';
import $ from 'jquery'
import User from "../_includes/login/Login"
import { Helmet } from 'react-helmet';
import { getCategories } from '../../actions/categorieAction'
import { getProducts, getProductsOnPage, getOneMoreProduct } from '../../actions/productsActions';

class IndexShop extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cat: '',
		};
	}

	componentDidMount() {
		this.initData();
	}

	initData() {
		$(".goodies").addClass('active');

		/* $('html,body').animate({
			scrollTop: 0
		}, 'slow'); */

		this.props.getCategories();
		this.props.getPages();

		$(".top-header-right.cart").on('click', function () {
			$("body").toggleClass("body-lock")
		});

		$(".account, .account-offine").on('click', function () {
			$(".top-header.logo").toggleClass("logohide")
		});
	}

	componentDidUpdate(){
		/* $('html,body').animate({
			scrollTop: 0
		}, 'slow'); */
	}


	onClick(item) {
		this.setState({
			cat: item
		})
	}

	render() {
		const { pages, products, categories } = this.props;
		let displayedHeaders;
		let cat
		if (products !== null && products.loading === false && products.products !== null) {
			cat = products.products
		}
		let total = products.loading === false && products.products !== null ? products.products.length : 0;
		let i = products.loading === false && products.products !== null ?
			products.products.filter(produit =>
				produit.choice_product === "Physical") : ""
		let displayFooter;

		if (pages.pageData === undefined || pages.pageData === null || pages.loading) {
			displayedHeaders = <Spinner />
			displayFooter    = <Spinner />
		}
		else {
			let foundHeader = false;
			displayedHeaders = pages && pages.pageData[0] && pages.pageData.map((page, i) => {
				if (pages.slug !== null) {
					const founded = (page.slug.indexOf("shop") > -1);
					if (founded) {
						foundHeader = true;
						return (
							<HeaderPage key={i} header={page} />
						)
					}
				}
			})
			let foundFooter = false;
			displayFooter = pages.pageData.map((page, i) => {
				if (pages.slug !== null) {
					let founded = (page.slug.indexOf("shop") > -1);
					if (founded) {
						foundFooter = true;
						return (
							<FooterIndex
								key={i}
								footer={page}
							/>
						)
					}
				}
			})
			if (!foundFooter) {
				displayFooter = <FooterIndex />
			}
		}

		const insideHeaderRelative = (
			<div>
				<div className="shop-top frfr col-md-12 relative clearfix align-center">
					<ul className="title-big">
						{categories != null && categories.loadingCategories === false && categories && categories.categories && categories.categories.map((item) =>
							<li><a onClick={() => this.onClick(item.slug)} >{item.name},</a></li>
						)}
						<li><a href="/book" className="books">Books</a>,</li>
						<li><a href="/typeface">Typefaces</a>,</li>
						<li><a href="/furniture">Supply</a>,</li>
					</ul>
					<ul className="title-big">
						<li><a href="/goodies">Stationery</a>,</li>
						<li><a href="#">rares or primes</a>,</li>
					</ul>
				</div>
				<div className="bottom-header col-md-12">
					<div className="scroll-bottom">
						<a>We’ve got {total} Products for you</a>
					</div>
				</div>
			</div>
		);
		return (
			<div>
				<Helmet>
					<title>WELT | Shop| Stationeries</title>
					<meta name="Shop" content="Page Shop de welt." />
				</Helmet>
				<div className="products-wrapper">
					<div className="headerShopImg"></div>
					<ProductShop getOneMoreProduct={ this.props.getOneMoreProduct } cat="stationery" produit={cat !== undefined ? cat : null} />
					{displayFooter}
				</div>
				<User />
			</div>
		)
	}
}
IndexShop.propTypes = {
	getPages: PropTypes.func.isRequired,
	getOneMoreProduct : PropTypes.func.isRequired,

}
const mapStateToprops = (state) => ({
	pages: state.pages,
	products: state.products,
	categories: state.categorie,
	countproducts: state.products,
})

const mapDispatchToProps = (dispatch) => {
	return {
		getCategories: () => dispatch(getCategories()),
		getPages: () => dispatch(getPages()),
		/* getProducts: () => dispatch(getProducts()),
		getProductsOnPage: (perPage, paramater, order, cat) => dispatch(getProductsOnPage(perPage, paramater, order, cat)),
		 */getOneMoreProduct: (products, perPage, paramater, order, typeface, search, orderb, brand, people, price, welt, tag, spe) => dispatch(getOneMoreProduct(products, perPage, paramater, order, typeface, search, orderb, brand, people, price, welt, tag, spe))

	}
}

export default connect(mapStateToprops, mapDispatchToProps)(IndexShop)
