import React, { Component } from 'react';
import Spinner from '../../utils/Spinner'
import OrdersComponent from './CommandeComponentState'
import { getPages } from '../../../actions/pagesAction'
import { getOrders } from '../../../actions/checkoutsActions';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
class Inventaire extends Component {
	
	render() {
		const { checkouts} = this.props;
		const { orders }   = checkouts
		let displayOrders;
		let productOrder   = []
		let typeFaceOrder  = []

		if (orders === null || checkouts.loading) {
			displayOrders = <Spinner />
		} else {
			productOrder = orders
			displayOrders = orders && orders.map((order, i) => {
				order && order.line_items && order.line_items.map((item, i) => {
					if (item.typeface) {
						typeFaceOrder.push(order)
					} 
				})
			})
		}

		return (
			<>
				<div class="col-md-12 list-account" id="commandes">
					<OrdersComponent  order={productOrder} />
				</div>
			</>
		)
	}
}
Inventaire.propTypes = {
	auth     : PropTypes.object.isRequired,
	getOrders: PropTypes.func.isRequired,
	checkouts: PropTypes.object.isRequired,
	getPages : PropTypes.func.isRequired,
}
const mapStateToprops = (state) => ({
	footerData: state.footerData,
	auth      : state.auth,
	checkouts : state.checkouts,
	pages     : state.pages,
})
export default connect(mapStateToprops, { getOrders, getPages })(Inventaire) 
