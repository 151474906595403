import React, { Component } from 'react';
import MenuTop from "./menu/Index";
import MenuPrincipal from "../menu/principal";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

export default class HeaderIndexTypefaces extends Component {
	render() {
		return (
			<header className={"col-md-12 relative menu-white " + this.props.col}>
				<div className="container-center col-md-10 relative">
					<div className="top-header">
						<div className="logo">
							<Link to="/typeface">	Type.WELTKERN® </Link>
						</div>
						<MenuTop />
					</div>
					<MenuPrincipal />
					{this.props.insideHeader}
				</div>
			</header>
		)
	}
}
