import $ from "jquery";
import React, { Component } from "react";
import Moment from "react-moment";
import API, { subUrl } from "../../../utils/api";
class OrdersComponent extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    $(".price-virgule").text(function () {
      return $(this).text().replace(".", ",");
    });
    console.log("data==",this.props.order)
  }
  

  getInvoice = (order) => {
    const data ={
			order_id: order
		}
    API.post(subUrl.getInvoice,data)
      .then((res) =>
        window.open(res.data, "_blank")
       )
      .catch((error) => alert(error.response.data.message));
	 
	}

  render() {

    let orderItems;
    if (this.props.order !== undefined && this.props.order != null) {
      orderItems = this.props.order.slice(0, 105).map(item => {
        console.log("status", item.status)
        return (
          <>
            <div className="list-licenses d-flex-row">
              <div className="col-md-6">
                <div className="col-md-10 float-left">
                  <h3 className="text text--medium">#{item.order_id}</h3>
                  <Moment format="DD/MM/YYYY" className="dateCommande">
                    {item.date_created}
                  </Moment>
                  <br />
                  {console.log("status",item)}
                  <div className="rangeState">
                    <span className="text text--small statCommande product-order__state">Order status</span><br />
                    {
                      item.status === "pending" ?
                      <hr className="niveauRange Commande" />: ""
                    }

                    {
                      item.status === "cancelled" ?
                      <hr className="niveauRange Annuler" />: ""
                    }

                    {
                      item.status === "completed" ?
                      <hr className="niveauRange Envoyer" /> : ""
                    }

                    {
                      item.status === "processing" ?
                      <hr className="niveauRange Commande" /> : ""
                    }


                  </div>
                  {item.status === "cancelled" ?
                    <div className='state state__container col-md-10 no-padd'>
                      <span className='stateCommande Red'>Ordered</span>
                      <span className='stateCours Red'>Being processed</span>
                      <span className='stateEnvoye Red'>To cancel</span>
                    </div> :
                    item.status === "completed" ?
                      <div className='state state__container col-md-10 no-padd'>
                        <span className='stateCommande Green'>Ordered</span>
                        <span className='stateCours Green'>Being processed</span>
                        <span className='stateEnvoye Green'>Sent</span>
                      </div> :
                      item.status === "pending" ?
                        <div className='state state__container col-md-10 no-padd'>
                          <span className='stateCommande black'>Ordered</span>
                          <span className='stateCours black'>Being processed</span>
                          <span className='stateEnvoye Grise'>Sent</span>
                        </div> :
                        item.status === "processing" ?
                          <div className='state state__container col-md-10 no-padd'>
                            <span className='stateCommande black'>Ordered</span>
                            <span className='stateCours black'>Being processed</span>
                            <span className='stateEnvoye black'>Sent</span>
                          </div> :
                          ""}
                </div>
              </div>
              <div className="col-md-6 prixItem product-order__statut">

                {console.log('items==>',item)}
                <h3 className='price-virgule'>{item.currency} {item.total.replace('.',',')}</h3>
                <h3 className="prixwelt"> +{item.total_points}W</h3>
                <a onClick={() => {this.getInvoice(item.order_id)}} className="viewmoreaccount">Download invoice</a>
                <a href={`/detailProduct/${item.order_id}`} className="viewmoreaccount">View more</a>
              </div>
            </div>
          </>
        );
      });
    }
    return (
      <div className="col-md-10 container-center my-order">
        {orderItems}
      </div>
    );
  }
}

export default OrdersComponent;
