import { CardElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import $ from "jquery";
import React from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { animateScroll as scroller } from "react-scroll";
import "react-toastify/dist/ReactToastify.min.css";
import styled from "styled-components";
import Swal from "sweetalert2";
import {
  changeAddress,
  changeAddressFacturation,
  deleteAdress,
  editAdress,
  getAdresses,
  newAdress,
} from "../../../actions/adressesActions";
import { newCheckout } from "../../../actions/checkoutsActions";
import { getCurrencyData } from "../../../actions/currencyAction";
import { getPayment } from "../../../actions/paymentAction";
import carte from "../../../assets/images/carte-credit.svg";
import paypal from "../../../assets/images/paypal.svg";
import API, { subUrl } from "../../../utils/api";
import { sort } from "../../../utils/helpers";
import Spinner from "../../utils/Spinner";
import Cart from "../../_includes/lastProduits/Cart";
import User from "../../_includes/login/Login";
import Detail from "../../_includes/menu/detail";
import HeaderIndex from "../header/Indexsanslogo";
import LoginCheckout from "./LoginCheckout";
import PayPalBtn from "./PayPalBtn";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: 0,
  },
};

let displayedLoading = null;
class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelected: false,
      show: false,
      showPopup: false,
      popupText: 0,
      payment: false,
      showPaypal: false,
      adresseName: "",
      firstName: "",
      lastName: "",
      country: "",
      address: "",
      postcode: "",
      city: "",
      adresseName2: "",
      firstName2: "",
      lastName2: "",
      country2: "",
      addressFact1: "",
      addressFact2: "",
      zipcode2: "",
      city2: "",
      state2: "",
      address2: "",
      postcode2: "",
      codePostal: "",
      state: "",
      ville: "",
      id: "",
      type: "",
      erreur: "",
      erreur1: "",
      erreur2: "",
      zipcode: "",
      availableFace: false,
      facturationAdressesData: null,
      checked: false,
      checkedFacturation: true,
      index: null,
      idCheck: null,
      pays: [],
      default: "",
      defPays: "",
      updatedAdress: null,
      submit: "Ajouter",
      showModal: false,
      showModalPoint: false,
      currentAdresse: null,
      checkedAddress: null,
      checkedAddressFacturation: null,
      oNrefresh: true,
      order: null,
      frais: 0,
      livraison: 0,
      bonus: 0,
      idShip: null,
      key: [],
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  componentDidMount() {
    $("body").removeClass("body-lock");
    $("#form-stripe").hide();
    $("#credit-card").on("click", function () {
      $("#form-stripe").show();
    });
    $("#payment-methode").hide();
    $(".previous").hide();
    $(".place-order").hide();
    $("html,body").animate(
      {
        scrollTop: 0,
      },
      "slow"
    );
    this.props.setOnSuccess(null);
    // $(".cart,.account, .account-offine,.menu").on('click', function () {
    //   $(".top-header").addClass('dark')
    // })

    $(".price-virgule").text(function () {
      return $(this).text().replace(".", ",");
    });

    this.props.getAdresses();
    this.props.getCurrencyData();
    this.props.getPayment();
    if (
      this.props.adresses &&
      this.props.adresses != null &&
      this.props.adresses.adresses != null &&
      this.props.adresses.adresses[0]
    ) {
      this.getAdresse(this.props.adresses.adresses[0]);
    }

    API.get(subUrl.adresses)
      .then((res) => {
        console.log("ressssss", res.data[0].id);
        this.scrollToWithContainer(res.data[0]);
        this.setState({
          default: res.data[0].country,
          country: res.data[0].country,
          idShip: res.data[0].id,
          facturationAdressesData: res.data[1],
          country2: res.data[0].country,
        });
        this.onCheck(res.data[0]);
        this.scrollToWithContainerUpdate(res.data[1]);
      })
      .catch((error) => console.log(error));
    API.get(subUrl.zones)
      .then((res) => {
        this.setState({ pays: res.data });
      })
      .catch((error) => console.log(error));
    API.get(subUrl.getPublishableKey)
      .then((res) => {
        this.setState({ key: res.data });
      })
      .catch((error) => console.log(error));
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if ([e.target.name] == "country") {
      let lineItems = [];
      this.props.cart.cartItems.map((item) => {
        let givenItem = {
          product_id: item.id,
          quantity: item.quantity,
          points:
            item && item.options && item.options.only_welt_point
              ? item.pointPrice.value
              : item.pointPrice.value,
          attributes: item.featureProduct,
          fontfaces: item.faceOption,
          gift_wrap: item.gift_wrap,
          licences: item.licence,
          price: item.licenceChecked == "Trial version" ? 0 : item.price,
          variant:
            item.variant && item.variant.length > 0 ? item.variant : null,
          options: item.featureProduct,
          licenceChecked: item.licenceChecked,
          weight: item.weight,
          newPrice: item.newPrice,
        };
        lineItems.push(givenItem);
      });
      const checkout = {
        country_code: e.target.value,
        total_shipping: this.returnPrice(),
        line_items: lineItems,
      };
      let prix = 0;
      this.props.cart.cartItems.map((item) => {
        prix += item.quantity * item.weight;
      });

      API.post(subUrl.updateOrders, checkout)
        .then((res) => {
          this.setState({
            frais: res.data.price,
            bonus: res.data.bonus,
            surprise: res.data.cadeaux,
            total: res.data.total,
            default: res.data.country,
          });
        })
        .catch((error) => console.log(error));
    }
  };
  handleOpenModal(address, i) {
    this.setState({ currentAdresse: address, showModal: true, idCheck: i });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }
  handleCloseModalPoint = () => {
    this.setState({ showModalPoint: false });
  };
  _validateEmail(email) {
    var regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(String(email).toLowerCase());
  }
  isValid() {
    if (this.state.firstName == "" || this.state.lastName == "") {
      this.setState({
        erreur: "Please enter the required fields",
        erreur1: "",
        erreur2: "",
      });
      return false;
    } else {
      this.setState({ erreur: "", erreur1: "", erreur2: "" });
      return true;
    }
  }

  addAddress = (e) => {
    e.preventDefault();
    //if (this.isValid()) {
    const newAdresse = {
      address_name: this.state.adresseName,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      company: this.state.company,
      country: this.state.country,
      address_1: this.state.address,
      address_2: this.state.address2,
      postcode: this.state.postcode,
      zipcode: this.state.zipcode,
      city: this.state.city,
      state: this.state.state,
      phone: this.state.phone,
      email: this.state.email,
      codePostal: this.state.codePostal,
      type: "shipping",
    };

    Swal.fire(
      "Good job your address has been added !",
      "You clicked the button!",
      "success",
      this.props.newAdress(newAdresse),
      this.setState({
        oNrefresh: false,
        adresseName: "",
        firstName: "",
        lastName: "",
        company: "",
        country: "",
        address: "",
        postcode: "",
        zipcode: "",
        city: "",
        state: "",
        phone: "",
        email: "",
        codePostal: "",
      }),

      this.props.getAdresses()
    );
    
  };

  addAddressFacturation = (e) => {
    e.preventDefault();
    //if (this.isValid()) {
    const newAdresse = {
      address_name: this.state.addressFact1,
      first_name: this.state.firstName2,
      last_name: this.state.lastName2,
      company: this.state.company,
      country: this.state.country2,
      address_1: this.state.addressFact1,
      address_2: this.state.addressFact2,
      postcode: this.state.zipcode2,
      city: this.state.city2,
      state: this.state.state2,
      phone: this.state.phone,
      email: this.state.email2,
      zipcode: this.state.zipcode2,
      type: "billing",
    };

    Swal.fire(
      "Good job your address has been added !",
      "You clicked the button!",
      "success",
      this.props.newAdress(newAdresse),
      this.setState({
        oNrefresh: false,
        adresseName2: "",
        firstName2: "",
        lastName2: "",
        country2: "",
        zipcode: "",
        address2: "",
      }),
      this.props.getAdresses()
    );

  };

  onCheck2 = (adress) => {
    this.setState({
      checkedFacturation: this.state.checkedFacturation ? false : true,
      checkedAddressFacturation: adress,
      addressFact1: adress.address_name,
      firstName2: adress.first_name,
      lastName2: adress.last_name,
      company2: adress.company,
      country2: adress.country,
      addressFact1: adress.address_1,
      addressFact2: adress.address_2,
      postcode2: adress.postcode,
      city2: adress.city,
      state2: adress.state,
      phone2: adress.phone,
      email2: adress.email,
      zipcode2: adress.zipcode,
    });
    this.props.changeAddressFacturation(adress);
  };

  onCheck = (address) => {
    this.setState({
      checked: true,
      index: address.id,
      checkedAddress: address,
    });
    console.log("default", this.state.default, address);
    {
      this.state.pays.map((z) =>
        z.zone.map((s) => {
          if (s.pays === this.state.default || s.code === this.state.default)
            this.setState({ default: s.code, defPays: s.pays });
        })
      );
    }
    let lineItems = [];
    this.props.cart.cartItems.map((item) => {
      let givenItem = {
        product_id: item.id,
        quantity: item.quantity,
        points:
          item && item.options && item.options.only_welt_point
            ? item.pointPrice.value
            : item.pointPrice.value,
        attributes: item.featureProduct,
        fontfaces: item.faceOption,
        gift_wrap: item.gift_wrap,
        licences: item.licence,
        price: item.licenceChecked == "Trial version" ? 0 : item.price,
        variant: item.variant && item.variant.length > 0 ? item.variant : null,
        options: item.featureProduct,
        licenceChecked: item.licenceChecked,
        weight: item.weight,
        newPrice: item.newPrice,
      };
      lineItems.push(givenItem);
    });
    const checkout = {
      country_code: address.country,
      total_shipping: this.returnPrice(),
      line_items: lineItems,
    };
    let prix = 0;
    this.props.cart.cartItems.map((item) => {
      prix += item.quantity * item.weight;
    });

    API.post(subUrl.updateOrders, checkout)
      .then((res) => {
        this.setState({
          frais: res.data.price,
          bonus: res.data.bonus,
          surprise: res.data.cadeaux,
          total: res.data.total,
          default: res.data.country,
        });
      })
      .catch((error) => console.log(error));
    this.props.changeAddress(address);

    this.setState({ livraison: this.state.frais * prix });
    // newCheckout(checkout)
  };

  getAdresse = (adress) => {
    if (adress.type == "shipping")
      this.setState({
        adresseName: adress.address_name,
        firstName: adress.first_name,
        lastName: adress.last_name,
        company: adress.company,
        country: adress.country,
        address: adress.address_1,
        address2: adress.address_2,
        postcode: adress.postcode,
        city: adress.city,
        state: adress.state,
        phone: adress.phone,
        email: adress.email,
        zipcode: adress.zipcode,
        show: true,
        id: adress.id,
      });
    else
      this.setState({
        addressFact1: adress.address_name,
        firstName2: adress.first_name,
        lastName2: adress.last_name,
        company2: adress.company,
        country2: adress.country,
        addressFact1: adress.address_1,
        addressFact2: adress.address_2,
        postcode2: adress.postcode,
        city2: adress.city,
        state2: adress.state,
        phone2: adress.phone,
        email2: adress.email,
        zipcode2: adress.zipcode,
        show: true,
      });
  };
  changePayment = (e) => {
    this.setState({ payment: e.target.value });
  };
  onChangeForm = () => {
    this.scrollTo();
    this.setState({
      adresseName: "",
      firstName: "",
      lastName: "",
      company: "",
      country: "",
      address: "",
      zipcode: "",
      city: "",
      state: "",
      phone: "",
      email: "",
      submit: "Ajouter",
      erreur: "",
    });
  };

  onChangeFormFacturation = () => {
    this.scrollToFacturation();
    this.setState({
      adresseName: "",
      firstName: "",
      lastName: "",
      company: "",
      country: "",
      address: "",
      zipcode: "",
      city: "",
      state: "",
      phone: "",
      email: "",
      submit: "Ajouter",
      erreur: "",
    });
  };

  showPaypalButtons = () => {
    this.setState({ showPaypal: true });
  };
  paymentHandler = (details, data) => {
    /** Here you can call your backend API
      endpoint and update the database */

    if (details.status == "COMPLETED") {
      this.checkout(details.id, details.status, "paypal", details.status);
    }
  };

  componentDidUpdate(prev, next) {
    if (prev.auth.isAuthenticated != this.props.auth.isAuthenticated) {
      this.props.getAdresses();
      this.props.getCurrencyData();
      this.props.getPayment();
      setTimeout(() => {
        // window.location.reload();
      }, 2000);
    }
  }
  clicked = () => {
    return loadStripe(this.state.key.stripe_publishable_key);
  };

  clickedNext = () => {
    if (!this.props.cart.cartItems.length) {
      Swal.fire({
        icon: "error",
        title: "Your cart is empty...",
        text: "Votre panier est vide",
      });
      return;
    }

    $("#payment-methode").show();
    $(".place-order").show();
    $(".all-address").hide();
    $(".previous").show();
    $(".next-button").hide();
  };
  clickedPrev = () => {
    $("#payment-methode").hide();
    $(".place-order").hide();
    $(".all-address").show();
    $(".previous").hide();
    $(".next-button").show();
  };

  returnPrice = () => {
    const { cart, currencyChecked } = this.props;

    let currentPrice, unitPrice;
    return cart.cartItems.reduce((acc, current) => {
      const { currencyChecked } = this.props.currencies;
      const ratePiont = current.pointPrice.value * 0.01;
      if (current.choice_product === "Typeface") {
        let start = 0;
        unitPrice = Object.values(current.faceOption).reduce((acc, curr) => {
          if (curr.isChecked) return acc + parseInt(curr.price);
        }, 0);
        let check =
          current.licences &&
          current.licences.filter((s) => s.name == current.licenceChecked);
        if (check != null && check.length != 0)
          if (check[0].name != "Trial version") {
            if (current.newPrice == 0) unitPrice = current.newPrice;
            else unitPrice = current.newPrice;
          } else unitPrice = 0;
      } else {
        if (current.variant && current.variant.length > 0)
          current.variant.map((variant) => {
            if (
              variant.name.attribute_pa_edition ==
              current.featureProduct.edition
            ) {
              unitPrice =
                parseFloat(variant.price) + parseInt(current.gift_wrap_price);
            } else if (
              variant.name.attribute_pa_color == current.featureProduct.color
            ) {
              unitPrice =
                parseFloat(variant.price) + parseInt(current.gift_wrap_price);
            } else {
              unitPrice =
                parseFloat(current.variant[0].price) +
                parseInt(current.gift_wrap_price);
            }
          });
        else {
          if (current.gift_wrap_price != undefined)
            unitPrice = current.price + parseInt(current.gift_wrap_price);
          else unitPrice = current.price;
        }
      }

      current && current.options && current.options.only_welt_point
        ? (currentPrice = 0)
        : (currentPrice =
            Math.round(
              (unitPrice * currencyChecked.value * current.quantity -
                (current.choice_product !== "Typeface" && ratePiont)) *
                100
            ) /
              100 <
            0
              ? 0
              : (currentPrice =
                  Math.round(
                    (unitPrice * currencyChecked.value * current.quantity -
                      (current.choice_product !== "Typeface" && ratePiont)) *
                      100
                  ) / 100));
      return acc + currentPrice;
    }, 0);
  };

  checkout = (token, id, methode, status, userPoint) => {
    const { cart, currencyChecked, auth } = this.props;
    const totalPoint = cart.cartItems.reduce((acc, current) => {
      return acc + current.points;
    }, 0);
    if (userPoint < totalPoint) {
      this.setState({ showModalPoint: true });
    } else if (this.state.checkedAddress == null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sélectionner une adresse de livraison",
      });
    } else {
      const { currencyChecked } = this.props.currencies;
      let lineItems = [];

      cart.cartItems.map((item) => {
        let givenItem = {
          product_id: item.id,
          quantity: item.quantity,
          points:
            item && item.options && item.options.only_welt_point
              ? item.pointPrice.value
              : item.pointPrice.value,
          attributes: item.featureProduct,
          fontfaces: item.faceOption,
          gift_wrap: item.gift_wrap,
          licences: item.licence,
          price: item.price,
          variant:
            item.variant && item.variant.length > 0 ? item.variant : null,
          options: item.featureProduct,
          licenceChecked: item.licenceChecked,
          weight: item.weight,
          newPrice: item.newPrice,
          family: item.family,
        };
        lineItems.push(givenItem);
      });

      let currentPrice, unitPrice;
      let total = cart.cartItems.reduce((acc, current) => {
        const ratePiont = current.pointPrice.value * 0.01;
        if (current.choice_product === "Typeface") {
          this.setState({ availableFace: true });
          let start = 0;
          unitPrice = Object.values(current.faceOption).reduce((acc, curr) => {
            if (curr.isChecked) return acc + parseInt(curr.price);
          }, 0);
          let check =
            current.licences &&
            current.licences.filter((s) => s.name == current.licenceChecked);
          if (check != null && check.length != 0)
            if (check[0].name != "Trial version") {
              if (current.newPrice == 0) unitPrice = current.newPrice;
              else unitPrice = current.newPrice;
            } else unitPrice = 0;
        } else {
          if (current.variant && current.variant.length > 0)
            current.variant.map((variant) => {
              if (
                variant.name.attribute_pa_edition ==
                current.featureProduct.edition
              ) {
                unitPrice =
                  parseFloat(variant.price) + parseInt(current.gift_wrap_price);
              } else if (
                variant.name.attribute_pa_color == current.featureProduct.color
              ) {
                unitPrice =
                  parseFloat(variant.price) + parseInt(current.gift_wrap_price);
              } else {
                unitPrice =
                  parseFloat(current.variant[0].price) +
                  parseInt(current.gift_wrap_price);
              }
            });
          else {
            if (current.gift_wrap_price != undefined)
              unitPrice = current.price + parseInt(current.gift_wrap_price);
            else unitPrice = current.price;
          }
        }

        current && current.options && current.options.only_welt_point
          ? (currentPrice = 0)
          : (currentPrice =
              Math.round(
                (unitPrice * currencyChecked.value -
                  (current.choice_product !== "Typeface" && ratePiont)) *
                  current.quantity *
                  100
              ) /
                100 <
              0
                ? 0
                : (currentPrice =
                    Math.round(
                      (unitPrice * currencyChecked.value -
                        (current.choice_product !== "Typeface" && ratePiont)) *
                        current.quantity *
                        100
                    ) / 100));
        return acc + currentPrice;
      }, 0);
      let prime;
      cart.cartItems.reduce((acc, current) => {
        prime =
          current.multiplier != false
            ? Math.floor(current.newWeltPrice / 100) *
              current.multiplier *
              current.quantity
            : Math.floor(current.newWeltPrice / 100) * current.quantity;
        return acc + prime;
      }, 0);

      let statusPrime;
      cart.cartItems.reduce((acc, current) => {
        statusPrime = Math.floor(current.newWeltPrice / 100) * current.quantity;
        return acc + statusPrime;
      }, 0);



      const stat = cart.cartItems.reduce((acc, current) => {
        return (
          acc +
          Math.floor(
            current.newWeltPrice / 100 / current.categorie_multiplier
          ) *
            current.quantity
        );
      }, 0);

      const Adresse = {
        address_name: this.state.adresseName,
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        company: this.state.company,
        country: this.state.country ? this.state.country : this.state.default,
        address_1: this.state.address,
        address_2: this.state.address2,
        zipcode: this.state.zipcode,
        postcode: this.state.zipcode,
        city: this.state.city,
        state: this.state.state,
        phone: this.state.phone,
        email: this.state.email,
        type: "shipping",
        id: this.state.idShip,
      };

      const factAdresse = {
        address_name: this.state.addressFact1,
        first_name: this.state.firstName2,
        last_name: this.state.lastName2,
        company: this.state.company2,
        country: this.state.country2,
        address_1: this.state.addressFact1,
        address_2: this.state.addressFact2,
        postcode: this.state.zipcode2
          ? this.state.zipcode2
          : this.state.zipcode,
        city: this.state.city2,
        state: this.state.state2,
        phone: this.state.phone,
        email: this.state.email2,
        zipcode: this.state.zipcode2,
        id: this.state.id,
        type: "billing",
      };

      

      const checkout = {
        frais: this.state.frais,
        bonus: this.state.bonus,
        currency: currencyChecked.key,
        prime: prime,
        statusPoint: statusPrime,
        status: 100,
        satus_paypal: status,
        line_items: lineItems,
        billing_address: this.state.checkedFacturation ? Adresse : factAdresse,
        shipping_address: Adresse,
        payment_method: methode,
        payment_token: token,
        payment_id: id,
        total: total + this.state.frais,
        points: localStorage.getItem("ratePoint") ? localStorage.getItem("ratePoint") : 0
      };
     
      if (methode == "points" && userPoint < (total + this.state.frais) * 100) {
        this.setState({ showModalPoint: true });
      } else {
        API.post(subUrl.payOrder, checkout)
          .then((res) => {
            console.log(res);
            if (res.status == 200) {
              this.props.editAdress(Adresse);
              if (!this.state.checkedFacturation)
                this.props.editAdress(factAdresse);
              this.setState({ showPopup: true, popupText: "Thank You!" });
              $(".cheekout").removeAttr("disabled");
              this.props.initializeCarte();
              /*  setTimeout(() => {
                this.props.history.push("/account/inventory");
              }, 5000); */
            } else {
              $(".cheekout").removeAttr("disabled");
              this.setState({ showPopup: false });
            }
          })
          .catch((error) => console.log(error));
      }
    }
  };

  scrollTo() {
    scroller.scrollTo("form-adress", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }

  scrollToFacturation() {
    scroller.scrollTo("form-adress-facturation", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }

  scrollToWithContainer(adresse) {
    $(".col-md-12.form1").toggleClass("show");
    let goToContainer = new Promise((resolve, reject) => {
      this.scrollTo();
      console.log("idddddddddddddddddd", adresse);
      this.setState({ id: adresse.id, submit: "Modifier", erreur: "" });
      this.getAdresse(adresse);
    });

    goToContainer.then(() => {
      scroller.scrollTo("scroll-container-second-element", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        containerId: "form-adress",
      });
    });
  }

  scrollToWithContainerUpdate(adresse) {
    $(".form2").toggleClass("show");
    let goToContainer = new Promise((resolve, reject) => {
      this.scrollToFacturation();
      //this.setState({ id: adresse.id, submit: "Modifier", erreur: "" });
      this.getAdresse(adresse);
    });

    goToContainer.then(() => {
      scroller.scrollTo("scroll-container-second-element", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        containerId: "form-adress",
      });
    });
  }
  deleteAdresse = (adresse) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your address has been deleted.", "success");
        this.props.deleteAdress(adresse.id, adresse);
      }
    });
  };
  updateAddress = (e) => {
    e.preventDefault();
    //if (this.isValid()) {
    const Adresse = {
      address_name: this.state.adresseName,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      company: this.state.company,
      country: this.state.country,
      address_1: this.state.address,
      address_2: this.state.address2,
      zipcode: this.state.zipcode,
      city: this.state.city,
      state: this.state.state,
      phone: this.state.phone,
      email: this.state.email,
      type: "shipping",
      id: this.state.id,
    };

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be update this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "update adress!",
          "Your address has been updated.",
          "success"
        );
        this.props.editAdress(Adresse);
        this.setState({
          oNrefresh: false,
          adresseName: "",
          firstName: "",
          lastName: "",
          company: "",
          country: "",
          address: "",
          zipcode: "",
          city: "",
          state: "",
          phone: "",
          email: "",
        });
        this.props.getAdresses();
      }
    });
  };

  updateAddressFacturation = (e) => {
    e.preventDefault();
    const Adresse = {
      address_name: this.state.adresseName2,
      first_name: this.state.firstName2,
      last_name: this.state.lastName2,
      company: this.state.company2,
      country: this.state.country2,
      address_1: this.state.address,
      address_2: this.state.address2,
      zipcode: this.state.zipcode,
      city: this.state.city,
      state: this.state.state,
      phone: this.state.phone,
      email: this.state.email,
      type: "billing",
      id: this.state.id,
    };

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be update this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "update adress!",
          "Your address has been updated.",
          "success"
        );
        this.props.editAdress(Adresse);
        this.setState({
          oNrefresh: false,
          adresseName2: "",
          firstName2: "",
          lastName2: "",
          country2: "",
          address2: "",
        });
        this.props.getAdresses();
      }
    });
  };
  componentDidUpdate() {
    // window.location.reload(false)
  }

  handleSubmitPoint = (event) => {
    //this.checkout('result.token.id', 'paymentMethod.id', "point")
    event.preventDefault();
    this.setState({ showPopup: true });
    $(".cheekout").attr("disabled", "disabled");
    API.get(subUrl.customerPoint)
      .then((res) => {
        this.checkout(
          "result.token.id",
          "paymentMethod.id",
          "points",
          "",
          res.data.point.prime
        );
      })
      .catch((error) => console.log(error));
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ showPopup: true });
    $(".cheekout").attr("disabled", "disabled");
    const { stripe, elements, checkouts } = this.props;
    const { currencyChecked } = this.props.currencies;
    const cardElement = elements.getElement(CardElement);

    const checkout = {
      currency: currencyChecked.key,
      total: this.returnPrice() + this.state.frais,
    };

    API.post(subUrl.paymentIntent, checkout)
      .then(async (res) => {
        //console.log(res)
        if (res.status == 200) {
          const confirmPayment = await stripe.confirmCardPayment(
            res.data.clientSecret,
            { payment_method: { card: cardElement } }
          );
          const { paymentIntent, error } = confirmPayment;
          //console.log(confirmPayment)
          if (paymentIntent && paymentIntent.status === "succeeded") {
            //console.log(paymentIntent)
            this.checkout(paymentIntent.id, paymentIntent.id, "stripe");
          }
          if (error) {
            if (error.code === "payment_intent_authentication_failure")
              Swal.fire(
                "Payment refused by your card issuer",
                "You clicked the button!",
                "error"
              );
            else if (error.decline_code === "insufficient_funds")
              Swal.fire(
                "Payment failed due to insufficient funds",
                "You clicked the button!",
                "error"
              );
            else
              Swal.fire("Payment refused", "You clicked the button!", "error");
            this.setState({ showPopup: false });
            $(".cheekout").removeAttr("disabled");
          }
        } else {
          $(".cheekout").removeAttr("disabled");
          this.setState({ showPopup: false });
        }
      })
      .catch((error) => console.log(error));
  };

  render() {
    const {
      stripe,
      checkouts,
      cart,
      adresses,
      currencies,
      orders,
      payment,
      auth,
    } = this.props;
    const { sendedOrder } = checkouts;
    const { currencyChecked } = currencies;
    const { isAuthenticated, user } = auth;

    let currentPrice = this.returnPrice();

    let displayedAdresses,
      currentAdresse,
      unitPrice,
      facturationAdresses,
      formAddress,
      formAddressFact;

    if (
      adresses.adresses === null ||
      adresses.loading ||
      (!this.state.oNrefresh && !isAuthenticated)
    ) {
      displayedAdresses = <Spinner />;
      facturationAdresses = <Spinner />;
      formAddressFact = <Spinner />;
    } else {
      formAddressFact = (
        <form
          onSubmit={
            this.state.submit == "Modifier"
              ? this.updateAddressFacturation
              : this.addAddressFacturation
          }
          id="form-adress-facturation"
        >
          <div class="erreur">
            {this.state.erreur !== "" ? (
              <FormattedMessage
                id="error.required"
                defaultMessage={this.state.erreur}
              />
            ) : null}
            {this.state.erreur1 !== "" ? (
              <FormattedMessage
                id="error.valid"
                defaultMessage={this.state.erreur1}
              />
            ) : null}
            {this.state.erreur2 !== "" ? (
              <FormattedMessage
                id="error.email"
                defaultMessage={this.state.erreur2}
              />
            ) : null}
          </div>
          <div className="col-md-12 ">
            <div className="form-text">
              <label>Prénom</label>
              <input
                type="text"
                name="firstName2"
                value={this.state.firstName2}
                onChange={this.onChange}
              />
            </div>
            <div className="form-text right">
              <label>Nom</label>
              <input
                type="text"
                name="lastName2"
                value={this.state.lastName2}
                onChange={this.onChange}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-text full">
              <label>Company</label>
              <input
                type="text"
                value={this.state.company2}
                name="company2"
                onChange={this.onChange}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-text full">
              <label>Address</label>
              <input
                type="text"
                value={this.state.addressFact1}
                name="addressFact1"
                onChange={this.onChange}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-text full">
              <label>Address complement</label>
              <input
                type="text"
                value={this.state.addressFact2}
                name="addressFact2"
                onChange={this.onChange}
              />
            </div>
          </div>
          <div className="col-md-12 ">
            <div className="form-text num">
              <label>Zip code</label>
              <input
                type="text"
                name="zipcode2"
                value={this.state.zipcode2}
                onChange={this.onChange}
              />
            </div>
            <div className="form-text rue">
              <label>City</label>
              <input
                type="text"
                name="city2"
                value={this.state.city2}
                onChange={this.onChange}
              />
            </div>
          </div>
          <div className="col-md-12 ">
            <div className="form-text full">
              <label>State, Area code, Other</label>
              <input
                type="text"
                name="state2"
                value={this.state.state2}
                onChange={this.onChange}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-text">
              <label>Country</label>
              <select
                name="country2"
                defaultValue={{ label: "Maroc", value: "Maroc" }}
                onChange={this.onChange}
              >
                <option
                  value={this.state.default}
                  dangerouslySetInnerHTML={{
                    __html: this.state.defPays
                      ? this.state.defPays
                      : this.state.default,
                  }}
                ></option>
                {this.state.pays != null
                  ? this.state.pays.map((pays) => (
                      <optgroup className="group-list" label={pays.zone_name}>
                        {pays.zone
                          .sort(function (a, b) {
                            if (a.pays.toLowerCase() < b.pays.toLowerCase())
                              return -1;
                            if (a.pays.toLowerCase() > b.pays.toLowerCase())
                              return 1;
                            return 0;
                          })
                          .map((location) => {
                            return (
                              this.state.defPays != location.pays && (
                                <option
                                  value={location.code}
                                  dangerouslySetInnerHTML={{
                                    __html: location.pays,
                                  }}
                                ></option>
                              )
                            );
                          })}
                      </optgroup>
                    ))
                  : ""}
              </select>
            </div>
          </div>
        </form>
      );

      formAddress = (
        <div className="col-md-12 form1 form-adresse ">
          <form
            onSubmit={
              this.state.submit == "Modifier"
                ? this.updateAddress
                : this.addAddress
            }
            id="form-adress"
          >
            <div class="erreur">
              {this.state.erreur !== "" ? (
                <FormattedMessage
                  id="error.required"
                  defaultMessage={this.state.erreur}
                />
              ) : null}
              {this.state.erreur1 !== "" ? (
                <FormattedMessage
                  id="error.valid"
                  defaultMessage={this.state.erreur1}
                />
              ) : null}
              {this.state.erreur2 !== "" ? (
                <FormattedMessage
                  id="error.email"
                  defaultMessage={this.state.erreur2}
                />
              ) : null}
            </div>
            <div className="col-md-12 ">
              <div className="form-text">
                <label>First name</label>
                <input
                  type="text"
                  name="firstName"
                  value={this.state.firstName}
                  onChange={this.onChange}
                />
              </div>
              <div className="form-text right">
                <label>Last name</label>
                <input
                  type="text"
                  name="lastName"
                  value={this.state.lastName}
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-text full">
                <label>Company</label>
                <input
                  type="text"
                  value={this.state.company}
                  name="company"
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-text full">
                <label>Address</label>
                <input
                  type="text"
                  value={this.state.address}
                  name="address"
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-text full">
                <label>Address complement</label>
                <input
                  type="text"
                  value={this.state.address2}
                  name="address2"
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="col-md-12 ">
              <div className="form-text num">
                <label>Zip code</label>
                <input
                  type="text"
                  name="zipcode"
                  value={this.state.zipcode}
                  onChange={this.onChange}
                />
              </div>
              <div className="form-text rue">
                <label>City</label>
                <input
                  type="text"
                  name="city"
                  value={this.state.city}
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-text full">
                <label>State, Area code, Other</label>
                <input
                  type="text"
                  name="state"
                  value={this.state.state}
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-text">
                <label>Country</label>
                <select
                  name="country"
                  defaultValue={{ label: "Maroc", value: "Maroc" }}
                  onChange={this.onChange}
                >
                  <option
                    value={this.state.default}
                    dangerouslySetInnerHTML={{ __html: this.state.defPays }}
                  ></option>
                  {this.state && this.state.pays != null
                    ? this.state &&
                      this.state.pays[0] &&
                      this.state.pays.map((pays) => (
                        <optgroup className="group-list" label={pays.zone_name}>
                          {pays.zone
                            .sort(function (a, b) {
                              if (a.pays.toLowerCase() < b.pays.toLowerCase())
                                return -1;
                              if (a.pays.toLowerCase() > b.pays.toLowerCase())
                                return 1;
                              return 0;
                            })
                            .map((location) => {
                              return (
                                this.state.defPays != location.pays && (
                                  <option
                                    value={location.code}
                                    dangerouslySetInnerHTML={{
                                      __html: location.pays,
                                    }}
                                  ></option>
                                )
                              );
                            })}
                        </optgroup>
                      ))
                    : ""}
                </select>
              </div>
            </div>
          </form>
        </div>
      );

      displayedAdresses = adresses.adresses.map((address, i) => {
        if (address.type === "shipping")
          return (
            <>
              <div className="item address" key={i}>
                <input
                  type="radio"
                  name="modele"
                  id={i}
                  checked={this.state.index == i}
                />
                <label className="address__content" for={i}>
                  <div className="adress__infos">
                    <p className="address--name text text--small">
                      {address.first_name} {address.last_name}
                    </p>
                    {/* <p className="address--name text text--small">{address.first_name} {address.last_name}</p> */}
                    <p className="address--street text text--small">
                      {address.city} {address.state}
                    </p>
                    <p className="address--city text text--small">
                      {address.address_name}
                    </p>
                    <p className="address--country text text--small">
                      {address.zipcode}
                    </p>
                    <p className="address--country text text--small">
                      {address.country}
                    </p>
                  </div>
                  <div className="icon--arrow">
                    <p>↓</p>
                  </div>
                  {/* layer */}
                </label>
                <div className="adresse-layer">
                  <div className="action">
                    {this.state.index === address.id && this.state.checked ? (
                      <img
                        className="check-icon"
                        src={require("../../../assets/images/adresse-chek.svg")}
                      />
                    ) : (
                      <img
                        className="check-icon"
                        src={require("../../../assets/images/adresse-chek-no.svg")}
                      />
                    )}

                    <button
                      onClick={() => {
                        this.handleOpenModal(address, address.id);
                        // this.getAdresse(address)
                      }}
                    >
                      View
                    </button>
                    <button
                      className="default-adresse"
                      onClick={() => this.onCheck(address)}
                    >
                      Use as delivery address
                    </button>
                    <button
                      id="update-adresse"
                      onClick={() => {
                        this.scrollToWithContainer(address);
                        //this.getAdresse(address)
                      }}
                    >
                      Update
                    </button>
                    <button
                      id="delete-adresse"
                      onClick={() => {
                        this.deleteAdresse(address);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </>
          );
      });

      facturationAdresses = (
        <div className="item address">
          <div className="adresse-layer">
            <div className="action">
              {this.state.checkedFacturation ? (
                <img
                  className="check-icon"
                  src={require("../../../assets/images/adresse-chek.svg")}
                />
              ) : (
                <img
                  className="check-icon"
                  src={require("../../../assets/images/adresse-chek-no.svg")}
                />
              )}

              <button
                className="default-adresse"
                onClick={() =>
                  this.onCheck2(this.state.facturationAdressesData)
                }
              >
                Use as billing address
              </button>
            </div>
          </div>
        </div>
      );

      if (this.state.currentAdresse == null) {
        currentAdresse = <Spinner />;
      } else {
        currentAdresse = (
          <div>
            <table className="table-detail">
              <tbody>
                <tr>
                  <th>First name</th>
                  <td>{this.state.currentAdresse.first_name}</td>
                </tr>
                <tr>
                  <th>Last Name</th>
                  <td>{this.state.currentAdresse.last_name}</td>
                </tr>
                <tr>
                  <th>Address Name</th>
                  <td>{this.state.currentAdresse.address_name}</td>
                </tr>
                <tr>
                  <th>Address 1</th>
                  <td>{this.state.currentAdresse.address_1}</td>
                </tr>
                <tr>
                  <th>City</th>
                  <td>{this.state.currentAdresse.city}</td>
                </tr>
                <tr>
                  <th>State</th>
                  <td>{this.state.currentAdresse.state}</td>
                </tr>
                <tr>
                  <th>Phone</th>
                  <td>{this.state.currentAdresse.phone}</td>
                </tr>
                <tr>
                  <th>Country</th>
                  <td>{this.state.currentAdresse.country}</td>
                </tr>
                <tr>
                  <th>Postcode</th>
                  <td>{this.state.currentAdresse.postcode}</td>
                </tr>
              </tbody>
            </table>

            {this.state.currentAdresse.id == this.state.idCheck &&
            this.state.currentAdresse.id == this.state.index ? (
              <img
                className="checked-icon"
                onClick={() => this.onCheck(this.state.currentAdresse)}
                src={require("../../../assets/images/check-markwhit-white.png")}
              />
            ) : (
              <img
                className="checked-icon"
                onClick={() => this.onCheck(this.state.currentAdresse)}
                src={require("../../../assets/images/check-mark.png")}
              />
            )}
          </div>
        );
      }
      cart.cartItems.sort((a, b) =>
        a.featureProduct.model > b.featureProduct.model
          ? 1
          : b.featureProduct.model > a.featureProduct.model
          ? -1
          : 0
      );
    }

    return (
      <>
        <div className="page-checkout">
          <Helmet>
            <title>Checkout – WK®</title>
            <meta name="Type Face" content="TYPE.WELTKERN®" />
          </Helmet>

          <CheckoutPage.Content className="panier-contribution panier-content orders">
            <HeaderIndex />
            <div className="col-md-12 menu-page-details">
              <div className="breadcrumbs">
                <div className="col-md-6 float-left bgNoir">
                  <ul>
                    <li>
                      <Link to={{ pathname: "/home" }}>
                        <img
                          src="/images/welt-breadcrumb-noir.svg"
                          alt="Welt"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to={{ pathname: "/home" }}>Cart</Link>
                    </li>

                    <li>
                      <Link to={{ pathname: `/home` }}>Summary</Link>
                    </li>

                    <li>
                      <Link to={{ pathname: `home` }}>Confirmation</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-10">
              <div className="col-md-12 adresse-paiment container-center">
                <div className="connect-content">
                  <div className="nom-account">
                    {/* <p className="user-infos__greetings text text--xmedium">Hello,</p>
                  <p className="user-infos__name text text--xmedium">
                    {user.user_display_name} {""} {user.user_lastname}  </p> */}
                    {/*//TODO: dynamiser le statut*/}
                    {/* <div className="status silver">Heavy</div> */}

                    <Detail />
                  </div>
                </div>
                {/* Adresse */}
                <div className="leftside adresse">
                  {/* Se connecter */}

                  {!isAuthenticated ? (
                    <LoginCheckout />
                  ) : (
                    <div className="user-infos clearfix">
                      {/* <div className="connect-content">
                        <div className="nom-account">
                          {/* <p className="user-infos__greetings text text--xmedium">Hello,</p>
                          <p className="user-infos__name text text--xmedium">
                            {user.user_display_name} {""} {user.user_lastname}  </p> */}
                      {/*//TODO: dynamiser le statut*/}
                      {/* <div className="status silver">Heavy</div> *

                          <Detail />
                        </div>
                      </div> */}
                      <div className="connect-content" id="payment-methode">
                        <div className="moyen">
                          <p>Payment methods</p>
                          <div className="liste-methode">
                            <div className="form-submit">
                              {currentPrice + this.state.frais > 0 &&
                              this.state.checkedAddress ? (
                                <div className="button-paie">
                                  {this.state.showPaypal ? (
                                    <PayPalBtn
                                      clientId={
                                        this.state.key.paypal_publishable_key
                                      }
                                      amount={(
                                        currentPrice + this.state.frais
                                      ).toFixed(2)}
                                      currency={currencyChecked.key}
                                      onSuccess={this.paymentHandler}
                                    />
                                  ) : (
                                    <button
                                      type="submit"
                                      className="cheekout"
                                      onClick={this.showPaypalButtons}
                                    >
                                      PayPal
                                    </button>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="image">
                                <img src={paypal} />
                              </div>
                            </div>
                            <div className="form-submit">
                              <div className="button-paie">
                                <button
                                  id="credit-card"
                                  type="submit"
                                  onClick={() => {
                                    this.clicked();
                                    // this.getAdresse(address)
                                  }}
                                  className="cheekout"
                                >
                                  Credit card
                                </button>
                              </div>
                              <div className="image">
                                <img src={carte} />
                              </div>
                              {/* <button onClick={this.handleSubmitPoint} className="cheekout" type="submit" >Paie With Point</button> */}
                            </div>
                          </div>
                          <form onSubmit={this.handleSubmit} id="form-stripe">
                            {displayedLoading}

                            <CardElement
                              className="stripe-element"
                              options={{
                                style: {
                                  base: {
                                    fontSize: "16px",
                                    color: "#424770",
                                    "::placeholder": {
                                      color: "#aab7c4",
                                    },
                                  },
                                  invalid: {
                                    color: "#9e2146",
                                  },
                                },
                              }}
                            />
                            {/* <label> */}
                            {currentPrice > 0 &&
                              this.state.checkedAddress != null && (
                                <button
                                  className="cheekout next"
                                  type="submit"
                                  disabled={!stripe}
                                >
                                  Place Order
                                </button>
                              )}
                          </form>

                          {this.state.checkedAddress != null &&
                            currentPrice + this.state.frais == 0 && (
                              <>
                                <button
                                  onClick={this.handleSubmitPoint}
                                  className="cheekout next"
                                  type="submit"
                                >
                                  Place Order
                                </button>
                              </>
                            )}
                        </div>
                      </div>
                      <div className="all-address">
                        <div className="connect-content">
                          <div className="user-infos__addresses col-md-12">
                            <div className="address--delivery adresse-livraison details-info">
                              <p class="address__delivery--title text text--xsmall">
                                Delivery address
                              </p>
                              {/* <div className="address__delivery--list list-adresse option-gift">
																<p class="address__list--title text text--small">Liste d'adresse(s)</p>
																<div className="flex">
																	<div className="licenses">
																		<div className="address__list allAddress" >
																			{displayedAdresses}
																		</div>
																	</div>
																	<button id="btn-addresse-livraison" className="btn btn--add-address" onClick={
																		this.onChangeForm}>Ajouter une adresse
                                									</button>
																</div>
															</div> */}
                            </div>
                          </div>
                          {/* Formulaire */}

                          {formAddress}
                          {/* End Formulaire */}
                        </div>

                        {/* End Ajouter adresse */}
                        <div className="connect-content">
                          <div className="user-infos__addresses col-md-12">
                            <div className="address--billing adresse-livraison details-info">
                              <p className="address__billing--title text text--xsmall">
                                Billing address
                              </p>
                              <div className="address__billing--list list-adresse option-gift">
                                <div className="flex">
                                  <div className="licenses">
                                    <div className="address__list allAddress">
                                      {facturationAdresses}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Formulaire */}
                          {!this.state.checkedFacturation && (
                            <div className="col-md-12 form-adresse form-adresse-facturation">
                              <div className="form2">{formAddressFact}</div>
                            </div>
                          )}
                          {/* End Formulaire */}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* End Se connecter */}
                  {/* Ajouter adresse */}
                </div>
                {/* Adresse */}
                {/* Produits */}
                <CheckoutPage.Products className="col-md-10 rightside produits content-panier">
                  <div className="overview">
                    <p>Summary of the order</p>
                    <div className="recap">
                      <div className="col-md-12">
                        {/* Listes produits */}
                        {sort(cart.cartItems).map((item, i) => {
                          let unitPriceWelt;
                          if (item.choice_product === "Typeface") {
                            let start = 0;
                            unitPrice = Object.values(item.faceOption).reduce(
                              (acc, curr) => {
                                if (curr.isChecked) start += 1;
                                return acc + parseInt(curr.price);
                              },
                              0
                            );
                            let check =
                              item.licences &&
                              item.licences.filter(
                                (s) => s.name == item.licenceChecked
                              );
                            if (check != null && check.length != 0)
                              if (check[0].name != "Trial version") {
                                if (item.newPrice == 0)
                                  unitPrice = item.newPrice;
                                else unitPrice = item.newPrice;
                              }
                              //unitPrice = unitPrice + (check[0].price * start)
                              else unitPrice = 0;
                            unitPriceWelt = Object.values(
                              item.faceOption
                            ).reduce((acc, curr) => {
                              return acc + parseInt(curr.price) * 100;
                            }, 0);
                          } else {
                            if (item.variant && item.variant.length > 0)
                              item.variant.map((variant) => {
                                if (
                                  variant.name.attribute_pa_edition ==
                                  item.featureProduct.edition
                                ) {
                                  unitPrice =
                                    parseFloat(variant.price) +
                                    parseInt(item.gift_wrap_price);
                                  unitPriceWelt =
                                    parseFloat(variant.price) * 100;
                                } else if (
                                  variant.name.attribute_pa_color ==
                                  item.featureProduct.color
                                ) {
                                  unitPrice =
                                    parseFloat(variant.price) +
                                    parseInt(item.gift_wrap_price);
                                  unitPriceWelt =
                                    parseFloat(variant.price) * 100;
                                } else {
                                  unitPrice =
                                    parseFloat(item.variant[0].price) +
                                    parseInt(item.gift_wrap_price);
                                  unitPriceWelt =
                                    parseFloat(item.variant[0].price) * 100;
                                }
                              });
                            else {
                              this.state.frais_livraison =
                                this.state.frais_livraison +
                                item.poids * item.quantity * this.state.value;

                              if (item.gift_wrap_price != undefined)
                                unitPrice =
                                  (item.price +
                                    parseFloat(item.gift_wrap_price)) *
                                  item.quantity;
                              else unitPrice = item.price * item.quantity;
                              unitPriceWelt = item.welt_price;
                            }
                          }
                          const ratePiont = item.pointPrice.value * 0.01;
                          return (
                            <div
                              className="product___desc list-produit content"
                              key={i}
                            >
                              <div className="product__img images img-l-full">
                                <img
                                  src={
                                    item.gallery_image.length == 0
                                      ? item.featured_image.url
                                      : item.gallery_image[0].url
                                  }
                                  alt="Welt"
                                />
                              </div>
                              <div className="product__infos container d-flex-column-s-b">
                                <div className="product__infos--wrapper  wraper-info">
                                  <div className="product__infos--card information">
                                    <h3
                                      className="card-title"
                                      dangerouslySetInnerHTML={{
                                        __html: item.name,
                                      }}
                                    ></h3>
                                    {item.choice_product === "Typeface" ? (
                                      <div>
                                        <p class="product__pack text text--medium license-select">
                                          {item.family != "not-selected"
                                            ? item.family
                                            : item.faceOption.map(
                                                (font) =>
                                                  font.isChecked && (
                                                    <span>
                                                      {font.value.name}
                                                    </span>
                                                  )
                                              )}
                                        </p>
                                      </div>
                                    ) : (
                                      <div>
                                        <p className="product__quantity text text--medium">
                                          {item.quantity}x
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                  <div className="product__infos--price prix">
                                    <div className="price-items">
                                      {item.choice_product === "Typeface" &&
                                      item.licenceChecked == "Trial version" ? (
                                        <p className="text">FREE</p>
                                      ) : (
                                        <>
                                          <span>{currencyChecked.key}</span>
                                          <span>
                                            <p className="text text--xsmall price-virgule">
                                              {" "}
                                              {Math.round(
                                                (unitPrice *
                                                  currencyChecked.value -
                                                  (item.choice_product !==
                                                    "Typeface" &&
                                                    item.pointPrice.value *
                                                      0.01)) *
                                                  100
                                              ) /
                                                100 <
                                              0
                                                ? 0
                                                : (
                                                    Math.round(
                                                      (unitPrice *
                                                        currencyChecked.value -
                                                        (item.choice_product !==
                                                          "Typeface" &&
                                                          item.pointPrice
                                                            .value * 0.01)) *
                                                        100
                                                    ) / 100
                                                  ).toFixed(2)}{" "}
                                            </p>
                                          </span>
                                        </>
                                      )}
                                      {/* <span>+{unitPriceWelt}W</span> */}
                                    </div>

                                    {item.choice_product === "Typeface" &&
                                    item.licenceChecked == "Trial version" ? (
                                      ""
                                    ) : (
                                      <div className="price-items clearfix">
                                        {/* <span>selected point :  </span> */}
                                        <span>
                                          {item &&
                                          item.options &&
                                          item.options.only_welt_point
                                            ? item.pointPrice.value *
                                              item.quantity
                                            : item.pointPrice.value}
                                          W
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="feature-info">
                                  {item.featureProduct.color != "" && (
                                    <p
                                      className="features"
                                      style={{
                                        backgroundColor: `${item.featureProduct.color}`,
                                      }}
                                    ></p>
                                  )}
                                  {item.featureProduct.size != "" && (
                                    <p className="features-size">
                                      {item.featureProduct.size}
                                    </p>
                                  )}
                                  {item.featureProduct.edition != "" && (
                                    <p className="features-size">
                                      {item.featureProduct.edition}
                                    </p>
                                  )}
                                </div>
                                {item.choice_product === "Typeface" ? (
                                  <p className="text text--xsmall license__choice">
                                    {item.licenceChecked}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="col-md-12">
                        {this.state.frais > 0 ? (
                          <div className="bloc-livraison">
                            Shipping{" "}
                            <span>
                              {this.state.frais != 0 && this.state.frais != null
                                ? this.state.frais.toFixed(2)
                                : "0.00"}
                            </span>
                          </div>
                        ) : (
                          <div className="bloc-livraison">
                            Free Delivery *{" "}
                            <span>
                              <img src="/images/checked.svg" />
                            </span>
                          </div>
                        )}
                        {this.state.surprise != null &&
                          this.state.surprise &&
                          this.state.surprise.map((result) =>
                            this.state.total >= result.total_panier
                              ? result.surprise_a_debloquer.point_a_gagner && (
                                  <div className="list col-md-12">
                                    <div className="col-md-2-2">
                                      <p>Bonus</p>
                                    </div>
                                    <div className="col-md-10 float-right align-right flex">
                                      <div className="col-md-6">
                                        <div className="col-md-12">
                                          <div className="col-md-9">
                                            <p>
                                              +
                                              {
                                                result.surprise_a_debloquer
                                                  .point_a_gagner
                                              }
                                              W
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <img src="/images/checked.svg" />
                                      </div>
                                    </div>
                                  </div>
                                )
                              : ""
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="bottom-chackout">
                    <div className="total-paiement clearfix">
                      <div className="total">
                        <span>Total</span>
                      </div>
                      <div className="prix">
                        <span className="">
                          {(
                            Math.round(
                              (currentPrice -
                                (localStorage.getItem("ratePoint")
                                  ? localStorage.getItem("ratePoint") * 0.01
                                  : 0) +
                                this.state.frais) *
                                100
                            ) / 100
                          ).toFixed(2)}
                          <br />
                        </span>
                        <span>{currencyChecked.key}</span>
                        <span style={{ clear: "both" }}>
                          {
                            /* {this.props &&
                            this.props.cart &&
                            this.props.cart.cartItems.reduce((acc, current) => {
                              let pointValue =
                                current &&
                                  current.options &&
                                  current.options.only_welt_point
                                  ? current.pointPrice.value * current.quantity
                                  : current.pointPrice.value;
                              return acc + pointValue;
                            }, 0)} */ localStorage.getItem("ratePoint")
                              ? localStorage.getItem("ratePoint")
                              : 0
                          }
                          W
                        </span>
                      </div>
                    </div>
                  </div>
                </CheckoutPage.Products>
                {/* Produits */}
              </div>
            </div>

            {/* hole Modal  */}
            <Modal
              isOpen={this.state.showModal}
              contentLabel="Minimal Modal Example"
              style={customStyles}
            >
              <div className="modalContet">{currentAdresse}</div>

              <button onClick={this.handleCloseModal} className="closeModal">
                <img src={require("../../../assets/images/icon-remove.png")} />
              </button>
            </Modal>
            <Modal
              isOpen={this.state.showModalPoint}
              contentLabel="Minimal Modal Example"
              style={customStyles}
            >
              <div className="modalContet">
                <p>
                  the number of points you have is less than this price
                  <span
                    style={{
                      fontWeight: "bold",
                      textDecoration: "underline",
                      marginLeft: 4,
                    }}
                  >
                    <Link
                      to={{
                        pathname: "/",
                      }}
                    >
                      Continue Shopping
                    </Link>
                  </span>
                </p>
              </div>

              <button
                onClick={this.handleCloseModalPoint}
                className="closeModal"
              >
                <img src={require("../../../assets/images/icon-remove.png")} />
              </button>
            </Modal>
            <User />
          </CheckoutPage.Content>
        </div>
        <div className="pagination-checkout">
          <button
            className="previous"
            onClick={() => {
              this.clickedPrev();
              // this.getAdresse(address)
            }}
          >
            {" "}
            Previous
          </button>
          <button
            className="next next-button"
            onClick={() => {
              this.clickedNext();
              // this.getAdresse(address)
            }}
          >
            {" "}
            Next
          </button>

          <div className="place-order">
            <div className="text">
              <p>
                In placing your order, you are confirming that you have read{" "}
                <br />
                and agree to our{" "}
                <a href="/support_/informations">Terms and Conditions</a>.{" "}
                <br />
                Please also see our{" "}
                <a href="/support_/informations">Privacy Policy</a> and our{" "}
                <a href="/support_/informations">Cookies Policy</a>.
              </p>
            </div>
          </div>
        </div>

        <Cart />
        {this.state.showPopup && (
          <div className="popup-checkout">
            {this.state.popupText === 0 ? (
              <p style={{textTransform: 'uppercase'}}>PLEASE WAIT...</p>
            ) : (
              <>
                <Popups>
                  <div>
                    <h1 className="text">Your order has been received!</h1>
                    <h2>Thank you for your purchase!</h2>
                    <br />
                    <p>
                      You will receive and order confirmation email <br />
                      with details of your orders.
                    </p>
                    <br />
                    {this.state.availableFace && (
                      <p>You can find your fonts in your inventory.</p>
                    )}
                    <div class="_btn__">
                      {this.state.availableFace && (
                        <a
                          href="/account/inventory"
                          color="primary"
                          class="float-left btn_popup"
                        >
                          Go to inventory
                        </a>
                      )}
                      <a
                        href="/shop"
                        color="primary"
                        class="float-right btn_popup"
                      >
                        Continue shopping
                      </a>
                    </div>
                  </div>

                  {/* <div>
            <h1 className="text text--medium-grey">
              Your order has been received!
            </h1>
            <h2>Thank you for your purchase!</h2>
            <br />
            <p>
              You will receive and order confirmation email <br />
              with details of your orders.
            </p>

            <button color="primary" class="btn_popup shoppp">
              Continue shopping
            </button>
          </div>  */}
                </Popups>
              </>
            )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  checkouts: state.checkouts,
  cart: state.cart,
  adresses: state.adresses,
  auth: state.auth,
  checkedAddress: state.adresses.checkedAddress,
  checkedAddressFacturation: state.adresses.checkedAddressFacturation,
  currencies: state.currencies,
  payment: state.payment,
  orders: state.checkouts,
});

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item) => {
      dispatch({ type: "ADD", payload: item });
    },
    removeFromCart: (item) => {
      dispatch({ type: "REMOVE", payload: item });
    },
    removeAllFromCart: (item) => {
      dispatch({ type: "REMOVE_ALL", payload: item });
    },
    getAdresses: () => dispatch(getAdresses()),
    getCurrencyData: () => dispatch(getCurrencyData()),
    initializeCarte: () => dispatch({ type: "INIALIZE_CART" }),
    getPayment: () => dispatch(getPayment()),
    setOnSuccess: (item) => {
      dispatch({ type: "SET_ON_SUCESS", payload: item });
    },
    changeAddress: (address) => dispatch(changeAddress(address)),
    changeAddressFacturation: (address) =>
      dispatch(changeAddressFacturation(address)),
    newAdress: (adressData) => newAdress(adressData),
    newCheckout: (checkoutData) => dispatch(newCheckout(checkoutData)),
    editAdress: (adressData) => editAdress(adressData),
    deleteAdress: (index, adress) => dispatch(deleteAdress(index, adress)),
  };
};

const Popups = styled.div`
{
  width: 80%;
  max-width: 600px;
  border: .5px solid #BEBEBE;
  background: rgba(232, 232, 232, 0.9);
  position: absolute;
  top: 50%;
  left: 50%;
  
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 50px 30px 40px;
  color: #333;

  h1, h2{
    font-family: "TWKLausanne-600";
    font-size: 30px;
    
  }
  h1{
    color:#BEBEBE;
  }

  ._btn__{
    text-align: center;
    padding-top: 35px;
  }
  

  .btn_popup{
    
    height: 36px;
    font-size: 13px;
    color: #ffffff;
    margin: 0 2px 0;
    width: 49%;
    max-width: 222px;
    float: none;
    display: inline-block;
    letter-spacing: -0.1px;
    text-align: center;
    background: #000000;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    border: 1px solid #000000;
  }

  p {
    font-family: "TWKLausanne-600";
    font-size: 16px;
    
  },

  .shoppp{
    margin-top: 30px;
  }
},


`;

const CheckoutPage = {
  Content: styled.div`
    &.panier-contribution
      .adresse-paiment
      .adresse
      .connect-content
      .identification
      .form-submit
      button {
      background: none !important;
      border: 1px solid black !important;
    }
    .container-center {
      /* height: calc(100vh - 36px); */
    }
    .adresse {
      height: 100%;
      max-height: 70% !important;
    }
    background: #ebebeb;
  `,

  Products: styled.div`
    height: auto;
  `,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CheckoutForm));
