import classnames from "classnames";
import $ from "jquery";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import InputRange from "react-input-range";
import { connect } from "react-redux";
import Slider from "react-slick";
import styled from "styled-components";
import API, { subUrl } from "../../utils/api";
import isEmpty from "../../validation/is-empty";

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      color: "black",
      size: "large",
      model: "",
      edition: "",
      giftPrice: 0,
      giftChecked: false,
      produit: 1,
      name: "",
      primes: 0,
      favorites: [],
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  _displayFavoriteImage(product) {
    var sourceImage = require("../../assets/images/heart-like.svg");
    if (
      this.props.favorites &&
      this.props.favorites.findIndex((item) => item.id === product.id) !== -1
    ) {
      sourceImage = require("../../assets/images/heart-dislike.svg");
    }
    return <img src={sourceImage} width="36" height="36" alt="welt" />;
  }
  componentDidMount() {
    this.forceUpdate();
    const totalPoint =
      this.props.cart.cartItems.length !== 0
        ? this.props.cart.cartItems.reduce((acc, current) => {
            return acc + current.points;
          }, 0)
        : 0;

    API.get(subUrl.customerPoint)
      .then((res) => {
        this.setState({ primes: res.data.point.prime - parseInt(totalPoint) });
        //console.log('primes',res.data.point.prime)
      })
      .catch((error) => console.log(error));

    this.setState({ name: this.props.product.name.replace("|", "<br/>") });
    this.props.cart.cartItems.length !== 0 &&
      this.props.cart.cartItems.map((cart) => {
        if (cart.id == this.props.product.id) {
          this.setState({
            produit: cart.quantity,

            giftChecked: this.props.product.giftChecked,

            giftPrice: this.props.product.gift_wrap_price,
          });
        }
      });

    if (this.props.product.options.only_welt_point) {
      this.setState({
        value: this.props.product.welt_price,
      });
    } else {
      this.setState({
        value:
          this.props.cartItem !== undefined
            ? this.props.cartItem.pointPrice.value
            : 0,
      });
    }

    $(".price-virgule").text(function () {
      return $(this).text().replace(".", ",");
    });

    $(".panier-type").on("click", function () {
      $(".cart").addClass("animate");
      setTimeout(() => {
        $(".cart").removeClass("animate");
      }, 2000);
    });
    $(".popup-price-product").on("click", function (e) {
      e.preventDefault();
      $(".popup--price").addClass("open");
      $(".scrollTop").addClass("not-showing");
      $("body").addClass("overflow-hidden");
    });
    $(".popup--price-close").on("click", function (e) {
      $(".popup--price").removeClass("open");
      $(".scrollTop").removeClass("not-showing");
      $("body").removeClass("overflow-hidden");
      e.preventDefault();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.cart.cartItems.length !== 0 &&
      !this.props.cart.cartItems.filter(
        (cartItem) => cartItem.id === this.props.product.id
      )
    ) {
      this.setState({
        giftChecked: this.props.cart.cartItems.filter(
          (cartItem) => cartItem.id === this.props.product.id
        )[0].giftChecked,
      });
    }
  }

  increment = () => {
    if (
      this.state.produit < this.props.product.quantity ||
      this.props.product.backorder_check == "yes"
    )
      this.setState({ produit: this.state.produit + 1 });
  };
  toggleFavorite = (current) => {
    const id = {
      id: current.id,
    };
    API.post(subUrl.postFavorit, id)
      .then((res) => {
        this.props.getFavorite();
        this.forceUpdate();
      })
      .catch((error) => console.log(error));
  };

  decrement = () => {
    if (this.state.produit !== 0)
      this.setState({ produit: this.state.produit - 1 });
  };

  handleGiftChecked = (event) => {
    if (!this.state.giftChecked) {
      this.setState({
        giftPrice: parseFloat(event.target.value),
        giftChecked: true,
      });
    } else {
      this.setState({ giftPrice: 0, giftChecked: false });
    }
  };

  break = (event) => {
    event.preventDefault();
  };

  render() {
    const { isAuthenticated, user } = this.props.auth;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    const featureProduct = {
      model: this.state.model,
      color: this.state.color,
      size: this.state.size,
      edition: this.state.edition,
    };
    const pointPrice = {
      value: this.state.value,
    };

    const product = this.props.product;
    const productInCart = this.props.cartItem;
    // image Thumbs

    let images, model, colors, size, edition, getProduct;
    if (product !== null && product !== undefined) {
      if (
        product.gallery_image.length > 0 &&
        typeof product.gallery_image === "object"
      ) {
        images = product.gallery_image.map((image, i) => {
          return (
            <div onClick={this.break} key={i} className="img-l-full">
              <img
                onClick={this.break}
                src={image.url}
                key={i}
                alt={image.alt}
              />
            </div>
          );
        });
      }
    } else {
      images = <p>empty</p>;
    }

    if (!isEmpty(product.model)) {
      model = product.model.map((model, i) => {
        return (
          <div key={i}>
            <input
              type="radio"
              name="model"
              value={model.slug}
              id={model.slug}
              onChange={this.onChange}
            />
            <label for={model.slug}>{model.name}</label>
          </div>
        );
      });
    } else {
      model = null;
    }

    if (!isEmpty(product.edition)) {
      edition = product.edition.map((edition, i) => {
        return (
          <div key={i}>
            <input
              type="radio"
              name="edition"
              defaultChecked={i < 1 && edition.slug}
              value={edition.slug}
              id={edition.slug}
              onChange={this.onChange}
            />{" "}
            <label for={edition.slug}> {edition.name} </label>{" "}
          </div>
        );
      });
    } else {
      edition = null;
    }

    if (!isEmpty(product.colors)) {
      colors = product.colors.map((colors, i) => {
        return (
          <div key={i}>
            <input
              type="radio"
              name="color"
              value={colors.color}
              defaultChecked={i < 1 && colors.color}
              id={colors.slug}
              onChange={this.onChange}
            />
            <label
              for={colors.slug}
              style={{ background: colors.color, border: "1px solid #000" }}
              title={colors.name}
            ></label>
          </div>
        );
      });
    } else {
      colors = null;
    }

    if (!isEmpty(product.sizes)) {
      size = product.sizes.map((size, i) => {
        return (
          <option key={i} value={size.slug}>
            {size.name}
          </option>
        );
      });
    } else {
      size = null;
    }

    if (colors === null && size === null) {
      getProduct = this.props.cart.cartItems.filter(
        (cartItem) => cartItem.id === product.id
      )[0];
    } else {
      getProduct = this.props.cart.cartItems.filter(
        (cartItem) =>
          cartItem.id === product.id &&
          JSON.stringify(cartItem.featureProduct) ===
            JSON.stringify(featureProduct)
      )[0];
    }

    const handleAddBtn = classnames({
      qty: true,
      disabled: featureProduct.color === "" || featureProduct.size === "",
    });

    const ratePiontPrime =
      this.state.value * 0.01 * product.categorie_multiplier;
    const ratePiont = this.state.value * 0.01;
    const faceOption = {};

    let currentPrice =
      productInCart === undefined
        ? product.variant && product.variant.length > 0
          ? Math.round(
              (parseFloat(product.variant[0].price) *
                this.props.currencyChecked.value -
                ratePiont) *
                1 *
                100
            ) / 100
          : Math.round(
              ((product.price +
                (this.state.giftPrice != undefined &&
                  parseFloat(this.state.giftPrice))) *
                this.props.currencyChecked.value -
                ratePiont) *
                1 *
                100
            ) / 100
        : Math.round(
            ((productInCart.price +
              (this.state.giftPrice != undefined &&
                parseFloat(this.state.giftPrice))) *
              this.props.currencyChecked.value *
              productInCart.quantity -
              ratePiont) *
              100
          ) / 100;

    if (
      productInCart &&
      productInCart.variant &&
      productInCart.variant.length > 0
    )
      productInCart.variant.map((variant) => {
        variant.name.attribute_pa_edition ===
        productInCart.featureProduct.edition
          ? (currentPrice =
              Math.round(
                (parseFloat(variant.price) * this.props.currencyChecked.value -
                  ratePiont) *
                  1 *
                  100
              ) / 100)
          : variant.name.attribute_pa_color ===
            productInCart.featureProduct.color
          ? (currentPrice =
              Math.round(
                (parseFloat(variant.price) * this.props.currencyChecked.value -
                  ratePiont) *
                  1 *
                  100
              ) / 100)
          : (currentPrice =
              Math.round(
                (parseFloat(productInCart.variant[0].price) *
                  this.props.currencyChecked.value -
                  ratePiont) *
                  1 *
                  100
              ) / 100);
      });

    let initialWeltPrice = parseInt(currentPrice);

    return (
      <>
        <Helmet>
          <title>{this.state.name.replace("<br>", "")} – WK®</title>
          <meta name="Shop" content="Inventory – WK®" />
        </Helmet>
        <div>
          <div
            onClick={this.break}
            className={"col-md-12 d-flex-row shop-overview " + this.props.dark}
          >
            <div onClick={this.break} className="col-md-6 float-left">
              <Slider {...settings}>{images}</Slider>
            </div>

            <div className="col-md-6 float-left bg-blanc d-flex-column-s-b details-info detail-shop">
              <div>
                <div className="marque">
                  <ul>
                    {/* <li>{product.categories[0].name}</li> */}
                    <li
                      dangerouslySetInnerHTML={{
                        __html:
                          product.header !== false &&
                          product.header[0] &&
                          product.header[0].header !== null &&
                          product.header[0].header.author_information != null &&
                          product.header[0].header.author_information.author !=
                            null
                            ? product.header[0].header.author_information.author
                                .name
                            : "",
                      }}
                    ></li>
                  </ul>
                </div>
                {/* <h2 className="title-big">{product === null ? null : product.slug}</h2> */}
                <p
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html:
                      product !== null && product.name !== null && product.name,
                  }}
                ></p>
                <div className="option-gift physique">
                  {model !== null && (
                    <div className="col-md-12 licenses model">
                      <p>Modèle</p>
                      {model}
                    </div>
                  )}
                  {edition !== null && (
                    <div className="col-md-12 licenses model">
                      <p> Edition </p>
                      {edition}
                    </div>
                  )}
                  {colors !== null && (
                    <div className="col-md-12 licenses color">
                      <p>Piètement</p>
                      {colors}
                    </div>
                  )}
                  {size !== null && (
                    <div className="col-md-12 licenses">
                      <p>Revêtement</p>
                      <div className="select">
                        <select name="size" onChange={this.onChange}>
                          <option value="large">L</option>
                          {size}
                        </select>
                      </div>
                    </div>
                  )}

                  {/* <div className="col-md-12 licenses color two-lines">
                                    <p>Couleur de<br /> revêtement</p>
                                    <div className="content">
                                        <div>
                                            <input type="radio" name="color_revetement" id="purple" />
                                            <label for="purple" style={{background:'#985C45'}} title="Purple"></label>
                                        </div>
                                        <div>
                                            <input type="radio" name="color_revetement" id="mauve" />
                                            <label for="mauve" style={{background:'#381E41'}} title="Mauve"></label>
                                        </div>
                                        <div>
                                            <input type="radio" name="color_revetement" id="orange" />
                                            <label for="orange" style={{background:'#FFBB84'}} title="Orange"></label>
                                        </div>
                                       <div>
                                            <input type="radio" name="color_revetement" id="vert" />
                                            <label for="vert" style={{background:'#415648'}} title="Vert"></label>
                                       </div>
                                    </div>
                                </div> */}
                  {/* <div className="col-md-12 licenses">
                                    <p>Piètement sur</p>
                                    <div className="select">
                                        <select name="">
                                        <option value="roulette souples, freinées pour sol dur">roulette souples, freinées pour sol dur</option>
                                        <option value="Cuir">Cuir</option>
                                        <option value="Cuir">Cuir</option>
                                        </select>
                                    </div>
                                </div>
                                */}
                  <div className="col-md-12 option">
                    {product.gift_wrap !== null &&
                    product.gift_wrap !== 0 &&
                    !isNaN(parseFloat(product.gift_wrap)) ? (
                      <>
                        <p className="small-txt">OPTIONS</p>
                        <input
                          type="checkbox"
                          name=""
                          id="option-gift"
                          checked={this.state.giftChecked}
                          onClick={this.handleGiftChecked}
                          value={product.gift_wrap}
                        />
                        <label for="option-gift">
                          Add Gift Wrap (+{this.props.currencyChecked.key}{" "}
                          {product.gift_wrap})
                        </label>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className="col-md-12">
                  <div
                    className={`monnaie-chf ${
                      product.options.only_welt_point ? "disabled" : ""
                    }`}
                  >
                    <sup className="text text--small">
                      {this.props.currencyChecked.key}{" "}
                    </sup>
                    {/* { productInCart == undefined ? Math.round((((product.price * this.props.currencyChecked.value) - ratePiont) * 1) * 100) / 100 : Math.round((((productInCart.price * this.props.currencyChecked.value) - ratePiont) * productInCart.quantity) * 100) / 100}  */}
                    {/* { productInCart == undefined ? product.price : productInCart.totalPricesProduct} */}
                    {currentPrice < 0 ? 0 : parseFloat(currentPrice).toFixed(2)}
                  </div>
                  <div className="monnaie-welt">
                    <label>+</label>
                    <span className="text-grey">
                      {product.options.only_welt_point
                        ? getProduct === undefined
                          ? parseInt(product.welt_price) +
                            parseInt(this.state.giftPrice) * 100
                          : (parseInt(getProduct.welt_price) +
                              parseInt(this.state.giftPrice) * 100) *
                            getProduct.quantity
                        : this.state.value}
                      W
                    </span>
                  </div>
                </div>
                <div className="range">
                  <InputRange
                    maxValue={
                      productInCart === undefined
                        ? (product &&
                            product.welt_price !== undefined &&
                            parseInt(product.welt_price) +
                              parseFloat(this.state.giftPrice) * 100) >=
                          this.state.primes
                          ? this.state.primes
                          : parseInt(product.welt_price) +
                            (this.state.giftPrice != undefined &&
                              parseFloat(this.state.giftPrice) * 100)
                        : (getProduct &&
                            getProduct.welt_price !== undefined &&
                            (parseInt(getProduct.welt_price) +
                              (this.state.giftPrice != undefined &&
                                parseFloat(this.state.giftPrice * 100))) *
                              getProduct.quantity) >= this.state.primes
                        ? this.state.primes
                        : (parseInt(getProduct.welt_price) +
                            (this.state.giftPrice != undefined &&
                              parseFloat(this.state.giftPrice * 100))) *
                          getProduct.quantity
                    }
                    step={5}
                    minValue={0}
                    disabled={
                      product.options.only_welt_point || !isAuthenticated
                        ? true
                        : false
                    }
                    value={
                      product.options.only_welt_point
                        ? getProduct === undefined
                          ? parseInt(product.welt_price)
                          : parseInt(getProduct.welt_price) *
                              getProduct.quantity +
                            parseInt(this.state.giftPrice) * 100
                        : this.state.value
                    }
                    onChange={(value) => this.setState({ value })}
                    onChangeComplete={(value) => {
                      // this.props.addRangeValueToReducer(value)
                      if (getProduct !== undefined) {
                        if (model === null) {
                          featureProduct.model = "";
                        }
                        if (colors === null) {
                          featureProduct.color = "";
                        }
                        if (edition === null) {
                          featureProduct.edition = "";
                        }
                        if (size === null) {
                          featureProduct.size = "";
                        }
                        this.props.addRangeValueToReducer({
                          ...product,
                          featureProduct,
                          faceOption,
                          ...pointPrice,
                          pointPrice,
                          licenceChecked: "default",
                        });
                      }
                    }}
                  />
                  <span className="popup-price-product text text--small">
                    ?
                  </span>
                  {!isAuthenticated && (
                    <span className="text-range-of-line">
                      Login to redeem your W points !
                    </span>
                  )}
                </div>
                <div className="col-md-12">
                  {(this.props.product.quantity > 0 ||
                    this.props.product.backorder_check == "yes") &&
                  this.props.product.multiplier !== 0 ? (
                    <div className="prix-multiplier">
                      <span className="white-text-welt">
                        {initialWeltPrice *
                          this.props.product.categorie_multiplier +
                          "W" +
                          "×" +
                          this.props.product.multiplier}{" "}
                        ={" "}
                      </span>
                      <span className="text-grey">
                        {initialWeltPrice *
                          this.props.product.categorie_multiplier *
                          this.props.product.multiplier}
                        W
                      </span>
                    </div>
                  ) : (
                    (this.props.product.quantity > 0 ||
                      this.props.product.backorder_check == "yes") && (
                      <div className="prix-chf border">
                        <span className="text-grey">
                          +
                          {currentPrice > 0
                            ? parseInt(
                                currentPrice *
                                  this.props.product.categorie_multiplier
                              )
                            : 0}
                          W
                        </span>
                      </div>
                    )
                  )}

                  <div
                    className={`qty-add-favori ${
                      this.props.product.quantity > 0
                        ? ""
                        : "qty-add-favori-out"
                    }`}
                  >
                    <div
                      className={
                        model !== null ||
                        colors !== null ||
                        size !== null ||
                        edition
                          ? handleAddBtn
                          : "qty"
                      }
                    >
                      {
                        <button
                          className={
                            this.state.produit > 1 ? "decrement" : "gray-signe"
                          }
                          onClick={() => {
                            this.state.produit > 1 && this.decrement();
                            if (getProduct !== undefined)
                              this.props.removeFromCart(getProduct);
                          }}
                        >
                          -
                        </button>
                      }
                      <p className="info-bulle">
                        Select from the left to top to Shopping Cart
                      </p>
                      <p>{this.state.produit}</p>

                      <button
                        className="increment"
                        onClick={() => {
                          if (model === null) {
                            featureProduct.model = "";
                          }
                          if (edition === null) {
                            featureProduct.edition = "";
                          }
                          if (colors === null) {
                            featureProduct.color = "";
                          }
                          if (size === null) {
                            featureProduct.size = "";
                          }
                          this.increment();
                        }}
                      >
                        +
                      </button>
                    </div>
                    <button
                      className="btn-heart like"
                      onClick={() => {
                        this.toggleFavorite(product);
                      }}
                    >
                      {this._displayFavoriteImage(product)}
                    </button>

                    <div className={model !== null ? "btn-type" : "btn-type"}>
                      {product.quantity > 0 ||
                      (product.quantity <= 0 &&
                        product.backorder_check == "yes") ? (
                        <button
                          className={
                            productInCart !== undefined
                              ? "active increment panier-type uppercase"
                              : "increment panier-type uppercase"
                          }
                          onClick={() => {
                            console.log("produits", product);
                            if (model === null) {
                              featureProduct.model = "";
                            }
                            if (colors === null) {
                              featureProduct.color = "";
                            }
                            if (edition === null) {
                              featureProduct.edition = "";
                            }
                            if (size === null) {
                              featureProduct.size = "";
                            }
                            if (getProduct !== undefined)
                              for (let i = 0; i < getProduct.quantity; i++) {
                                this.props.removeFromCart(getProduct);
                              }
                            for (let i = 0; i < this.state.produit; i++) {
                              this.props.cart.cartItems.filter(
                                (cartItem) => cartItem.id === product.id
                              );
                              this.props.addToCart({
                                ...product,
                                featureProduct,
                                faceOption,
                                ...pointPrice,
                                pointPrice,
                                newWeltPrice: parseInt(
                                  initialWeltPrice *
                                    product.categorie_multiplier *
                                    100
                                ),
                                gift_wrap_price: 0 /*parseFloat(this.state.giftPrice)*/,
                                giftChecked: this.state.giftChecked,
                                licenceChecked: "default",
                              });
                            }
                          }}
                        >
                          <p>Add to cart</p>
                          <div className="img-cart">
                            <img src="/images/panier-add.svg" />
                          </div>
                        </button>
                      ) : (
                        <button className="increment panier-type uppercase out-of-stock">
                          <p>Out of stock</p>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Popups>
          <div className="popup--price col-md-12">
            <div className="popup__content">
              <div className="col-md-8 center">
                <div className="popup__text-content">
                  <div className="popup__title">
                    <h3 className="uppercase text text--xmedium">
                      HOW TO REDEEM YOUR POINTS
                    </h3>
                  </div>
                  <div className="popup__text popup-redeem-all">
                    <p className="mb-30">
                      There are many ways to spend your Status W Points. You can
                      redeem your precious collected points directly for
                      attractive products or benefits. For example, to get a
                      discount, your next favorite book as a gift or, in the
                      future, enjoy services from our selected partners.
                    </p>

                    <p className="mb-30">
                      A. You determine how many W points to spend on your
                      favorite product.
                      <br />
                      B. Enough points? Treat yourself and get the product of
                      your choice in full!
                      <br />
                      C. What’s more, in the future, you will also benefit from
                      our partners’ advantages.
                    </p>

                    <p>
                      Create an account and receive 500W deductible right away
                      or later.
                    </p>
                  </div>
                </div>
                <div className="col-md-12 right">
                  <button className="popup--price-close btn btn--close">
                    <p>Close</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Popups>
      </>
    );
  }
}

const Popups = styled("div")`
  .popup--licence,
  .popup--download,
  .popup--price {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    opacity: 0;
    visibility: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: RGBA(235, 235, 235, 0.98);
    font-family: "TWKLausanne-600";

    &.open {
      opacity: 1;
      visibility: visible;
      z-index: 10;
    }

    .popup__content {
      display: flex;
      justify-content: flex-start;
      height: auto;
      width: 100%;
      margin-top: 400px;
      padding-bottom: 100px;
    }
    .center {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    .right {
      display: flex;
      justify-content: flex-end;
    }
    .popup__title {
       margin-bottom: 70px;
    }
    .mb-30 {
       margin-bottom: 30px;
    }
    .mb-40 {
       margin-bottom: 40px;
    }
    .underline {
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 480px) {
    .popup--licence,
    .popup--download,
    .popup--price {
      .popup__title {
         margin-bottom: 30px;
      }
      &.open {
        padding: 0 20px;
      }
    }
    h3 {
      color: #000000;
    }
  }
`;

const mapStateToprops = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToprops)(Overview);
