import React, { Component } from 'react';
import { logoutUser } from '../../../actions/authActions'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import $ from 'jquery'
import { NavLink } from 'react-router-dom';

class MenuSupport extends Component {
	componentDidMount() {
		$(".Menu").on('click', function () {
			$('li a.Menu').addClass("activeMenu")
		})
	}

	render() {
		const { isAuthenticated, user, date } = this.props.auth
		return (
			<div className="col-md-3 float-left linkAccountLeft">
				<ul>
					<li><NavLink to="/support_/about" className="Menu">About us</NavLink></li>
					<li><NavLink to="/support_/informations" className="Menu2">Informations</NavLink></li>
					<li><NavLink to="/support_/download" className="Menu3">Download</NavLink></li>
					<li><NavLink to="/support_/updates" className="Menu4">Updates</NavLink></li>
					<li><NavLink to="/support_/contact" className="Menu5">Contact</NavLink></li>
					<li><NavLink to="/support_/faq" className="Menu6">FAQ</NavLink></li>
				</ul>
			</div>
		)
	}
}

MenuSupport.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth      : PropTypes.object.isRequired,
}
const mapStateToprops = (state) => ({
	auth: state.auth
})

export default connect(mapStateToprops, { logoutUser })(MenuSupport)