import React, { Component } from 'react';
import HeaderIndex from "./_includes/header/Index";
import MenuIndex from "./_includes/menu";
import ProductsListing from "./_includes/lastProduits/ProductsListing";
import FooterIndex from "./_includes/footer/FooterIndex";
import LoginIndex from "./_includes/login/Login";
import Cart from "./_includes/lastProduits/Cart"
import HeaderPage from "./_includes/headersPage/HeaderPage"
import SimplePost from "./posts/SimplePost"
import InlinePost from "./posts/InlinePost"
import FullPost from "./posts/FullPost"
import Spinner from './utils/Spinner'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getPages } from '../actions/pagesAction'
import { getPostData } from '../actions/postActions'
import { getProducts, getProductsOnPage, getOneMoreProduct } from '../actions/productsActions'
import Recherche from './_includes/popup/recherche'
import { Helmet } from 'react-helmet';
import $ from 'jquery';
import API, { subUrl } from '../utils/api'
import { NavLink } from 'react-router-dom';

class Index extends Component {
	constructor() {
		super()
		this.state = {
			isShowing: false,
			visible  : 10,
			vignette : [],
			color    : 'red',
			productPerPage : 9
		}
	}
	componentDidMount() {
		const { currentLange, currencies } = this.props;
		const { currencyChecked } = currencies;
		const { language }     = currentLange;
		// this.props.getProducts(language, currencyChecked.key);
		this.props.getProductsOnPage(this.state.productPerPage, 1, "all", 'books')
		if($('.scroll-top-div').length){
			var fixmeTop = $('.scroll-top-div').offset().top ? $('.scroll-top-div').offset().top + 700 : 700;
			$(window).scroll(function () {
				var currentScroll = $(window).scrollTop();
		    if (currentScroll >= fixmeTop) {
	       	$('.menu-page-details').addClass('dark');
					$('.top-header').addClass('dark');
		    }else {
			 		$('.menu-page-details').removeClass('dark');
					$('.top-header').removeClass('dark');
		    }
			});
		}

		$('html,body').animate({
			scrollTop: 0
		}, 'slow');
		$("body.body").removeClass("body-lock")
		this.props.getPages()
		this.props.getPostData(language)

		$(".cart,.menu,.account, .account-offine").on('click', function () {
			$("header.top-header").toggleClass('dark')
		})

		API
			.get(subUrl.vignette)
			.then(res => {
				this.setState({ vignette: res.data })
			}
			)
			.catch(error => console.log(error))
	}
	showMore = () => {
		this.setState({ isShowing: true, visible: this.state.visible + 5 })
	}

	render() {
		const { posts, pages, products } = this.props;
		let displayedHeaders = <Spinner />;
		let displayFooter;
		let bannerView;
		let _produit;
		const simplePost = [];
		const inlinePost = [];
		const fullPost = [];

		console.log(products)


		if (pages.pageData === undefined || pages.pageData === null || pages.loading) {
			// displayedHeaders = <Spinner />
		} else {
			let foundHeader  = false;
			let foundBanners = false;
			let foundFooter  = false;
			const founded = pages.pageData.find(page => page.slug.indexOf("index") > -1);
			if (founded) {
				if(founded.header && founded.header.link && founded.header.link.interne && founded.header.link.interne.ID){
					if(founded.header.link.interne.post_type === "post"){
						_produit = null;
					}
					else {
						_produit = products && products.products ? products.products.find(produit => produit.id === founded.header.link.interne.ID) : null;
					}
				}
				foundHeader = true;
				foundFooter = true;
				this.state.color = founded.colorBackground
				displayedHeaders = (
					<HeaderPage
						header={founded}
						scroll={founded.colorBackground}
					/>
				)
				displayFooter = (
					<FooterIndex
						footer={founded}
					/>
				)
			}

			bannerView = pages.pageData?.map((page, i) => {
				if (pages.slug !== null) {
					let founded = (page.slug.indexOf("index") > -1)
					if (founded) {
						if (page.banner_1.background_image !== false) {
							foundBanners = true;
							return (
								<img key={i} src={page.banner_1.background_image.url} alt="Welt" />
							)
						}
					}
				}
			})

			if (!foundHeader) {
				displayedHeaders = <HeaderPage />
			}
			if (!foundFooter) {
				displayFooter = <FooterIndex />
			}
			if (!foundBanners) {
				bannerView = ""
			}
		}
		if (posts.post === null || posts.loading) {

		} else {
			posts && posts.post && posts.post.map((post, i) => {
				const simplePostFounded = (post.post_template.indexOf("col-md-4") > -1 || post.post_template.indexOf("col-md-6") > -1);
				const inlinePostFounded = (post.post_template.indexOf("main-book") > -1);
				const fullPostFounded = (post.post_template.indexOf("col-md-12") > -1);

				if (simplePostFounded) {
					simplePost.push(post)
				}
				if (inlinePostFounded) {
					inlinePost.push(post)
				}
				if (fullPostFounded) {
					fullPost.push(post)
				}
			})
		}

		return (

			<>
				<Helmet>
					<title>WELTKERN®</title>
					<meta name="Home" content="Curator and Producer of Design. A fine selection of the most beautiful books and objects in the world. Free Shipping in Switzerland. Shipping worldwide." />
				</Helmet>
				<HeaderIndex hasPanier={true} col={this.state.color} />
				{/*  Get HEADER */}
				{displayedHeaders}

				<MenuIndex currentPage={"shop typeface journal"} />
				<div className="row vignette">
					{this.state && this.state.vignette && this.state.vignette[0] && this.state.vignette?.map((item) => {
						return (
							<>
								<div class="col-md-2-2">
									<NavLink to={item.external_link ? item.url_externe : item.url_interne} target={item.external_link ? "_blank" : ''}>
										<div className="img-vignette"><img src={item.image} /></div>
										<h2>{item.title}</h2>
									</NavLink>
								</div>
							</>)
					})}
				</div>

				<div className="col-md-12 post post-simple padding-t-b-l-r-8 overflow-h bg-corps text-center">
					{simplePost.length > 0 &&
						simplePost.slice(0, 5)?.map((post, i) => {

							return (
								<SimplePost 
								key={i} 
								post={post} 
								 />
							)
						}
						)
					}
				</div>

				{<ProductsListing getOneMoreProduct={ this.props.getOneMoreProduct } getProductsOnPage={ this.props.getProductsOnPage }/> }



				{/*!-- Produits -->*/}
				{bannerView === '' ? null :
					<div className="col-md-12 overflow-h">
						{/* Produits items */}
						<div className="img-window img-fenetre">
							{bannerView}
						</div>
						{/* End Produits items */}
					</div>
				}
				{/*!-- Produits -->*/}

				{/*!-- Main Book -->*/}
				<div className="col-md-12 bg-corps">
					{inlinePost.length > 0 &&
						inlinePost?.map((post, i) => {
							while (i < 1) {
								return (
									<InlinePost
										key={i}
										post={post}

									/>
								)
							}
						}

						)
					}
				</div>

				{/*!-- End Main Book -->*/}
				<div className="col-md-12 overflow-h align-center bg-corps">
					{/*!-- Produits items -->*/}
					{fullPost.length > 0 &&
						fullPost.slice(0, 1)?.map((post, i) => {
							return (
								<FullPost
									key={i}
									post={post}
								/>
							)
						}
						)
					}
					{/*!-- End Produits items -->*/}
				</div>


				<div className="col-md-12 overflow-h align-center bg-corps">
					{/*!-- Produits items -->*/}
					{fullPost.length > 0 &&
						fullPost.slice(1, 2)?.map((post, i) => {
							return (
								<FullPost
									key={i}
									post={post}
								/>
							)
						}
						)
					}
					{/*!-- End Produits items -->*/}
				</div>
				{/*!-- Produits -->*/}

				{/*!-- Produits -->*/}
				<div className="col-md-12 post post-simple padding-t-b-l-r-8 overflow-h align-center bg-corps">
					{simplePost.slice(5, this.state.visible)?.map((post, i) => {
						return (
							<SimplePost
								key={i}
								post={post}
							/>
						)
					}
					)
					}
				</div>
				{simplePost.length >10 &&
				<div className="btn-show-more">
					<button onClick={this.showMore}>LOAD MORE</button>
				</div>
				}
				{displayFooter}
				<LoginIndex />
				<Cart />
				{/* <Recherche /> */}

			</>)
	}
}
Index.propTypes = {
	getPages    : PropTypes.func.isRequired,
	// getProducts : PropTypes.func.isRequired,
	getProductsOnPage : PropTypes.func.isRequired,
	getOneMoreProduct : PropTypes.func.isRequired,
	auth        : PropTypes.object.isRequired,
	products    : PropTypes.object.isRequired,
	getPostData : PropTypes.func.isRequired,
	currentLange: PropTypes.object.isRequired,
	currencies  : PropTypes.object.isRequired,
}
const mapStateToprops = (state) => ({
	auth        : state.auth,
	products    : state.products,
	posts       : state.postObject,
	pages       : state.pages,
	currentLange: state.translation,
	currencies: state.currencies
})

export default connect(mapStateToprops, { getPages, getPostData, getProductsOnPage, getOneMoreProduct })(Index)
