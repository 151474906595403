import {GET_CATEGORIES_POST,  CATEGORIES_POST_LOADING} from '../actions/types'

const initialState = { 
    categories: null,
    loadingCategories: false
}

export default function (state= initialState, action)  {
    switch (action.type) {
        case CATEGORIES_POST_LOADING:
            return {
                ...state,
                loadingCategories: true
            };
        case GET_CATEGORIES_POST:
            return {
                ...state,
                categories: action.payload,
                loadingCategories: false
            };
        default: 
           return state
    }

}