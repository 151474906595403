import React, { Component } from "react";
import { connect } from "react-redux";
import $ from "jquery";
class Cart extends Component {
  componentDidMount() {
    $(".cart").on("click", function () {
      $("body.body").toggleClass("body-lock");

      $("header .top-header").removeClass("dark");

      $("header").toggleClass("panier-show");

      $("header .top-header-right").removeClass("grise");

      $(this).toggleClass("active");

      $(".panier-content").toggleClass("open");

      $("header").removeClass("menu-show");
      $(".page-login").removeClass("open");

      if ($(".menu").hasClass("active") === true) {
        $(".menu").removeClass("active");
        $("body.body").addClass("body-lock");
      }
      return false;
    });
  }

  render() {
    const { cart } = this.props;
    return (
      <a className="cart">
        <span>
          {cart.cartItems.reduce((acc, item) => {
            return acc + item.quantity;
          }, 0)}
        </span>
      </a>
    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.cart,
});

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item) => {
      dispatch({ type: "ADD", payload: item });
    },
    removeFromCart: (item) => {
      dispatch({ type: "REMOVE", payload: item });
    },
    removeAllFromCart: (item) => {
      dispatch({ type: "REMOVE_ALL", payload: item });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Cart);
