import { CHANGE_LANGUE } from '../actions/types'
const initialState = {
    language: 'en'
}
const translateReducer =( state = initialState, action)=> {
    switch(action.type) {
        case CHANGE_LANGUE :
            return {
                ...state,
                language: action.payload,
            }
        default :
            return state
    }
}
export default translateReducer