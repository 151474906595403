import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import HeaderIndex from "../header/Index";
import Account from "../menu/account";
import FooterIndex from "../footer/FooterSanaHeader";
import Spinner from "../../utils/Spinner";
import Cart from "../lastProduits/Cart";
import OrdersComponent from "./CommandeComponentState";
import TypeFaceOrders from "./TypeFaceOrders";
import { getPages } from "../../../actions/pagesAction";
import { getOrders } from "../../../actions/checkoutsActions";
import Detail from "../../_includes/menu/detail";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import $ from "jquery";

class Inventory extends Component {
  componentDidMount() {
    $("html,body").animate(
      {
        scrollTop: 0,
      },
      "slow"
    );

    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.innerHTML = `
    jQuery(function () {
      $(".menu-my-account").idTabs({});
    });`;

    $("li a.Menu3").addClass("activeMenu");

    document.body.appendChild(s);
    this.props.getOrders();
    this.props.getPages();
    console.log("itemmm", this.props);
  }
  render() {
    const { isAuthenticated, user } = this.props.auth;
    const insideHeader = (
      <div className="my-account col-md-12 relative padding-t-50 clearfix">
        {isAuthenticated ? (
          <>
            <Detail />
            <Account />
          </>
        ) : (
          this.props.history.push("/")
        )}
      </div>
    );

    // Dynamic Footer
    const { checkouts, pages } = this.props;
    const { orders } = checkouts;
    let displayFooter, displayOrders;
    if (
      pages.pageData === undefined ||
      pages.pageData === null ||
      pages.loading
    ) {
    } else {
      let foundFooter = false;

      displayFooter =
        pages &&
        pages.pageData &&
        pages.pageData.map((page, i) => {
          if (pages.slug !== null) {
            let founded = page.slug.indexOf("inventory") > -1;
            if (founded) {
              foundFooter = true;
              return <FooterIndex key={i} footer={page} />;
            }
          }
        });

      if (!foundFooter) {
        displayFooter = <FooterIndex />;
      }
    }
    // Orders
    let productOrder = [];
    let typeFaceOrder = [];
    if (orders === null || checkouts.loading) {
      displayOrders = <Spinner />;
    } else {
      productOrder = orders;
      typeFaceOrder = orders;

      console.log("all typefaces", typeFaceOrder);
    }

    return (
      <div>
        <Helmet>
          <title>Inventory – WK®</title>
          <meta name="Shop" content="Inventory – WK®" />
        </Helmet>
        <div className="headerAccount">
          <HeaderIndex insideHeader={insideHeader} />
        </div>
        <div className="col-md-12 clearfix paiment-lists list-account">
          <div className="col-md-10 container-center tab-list inventory">
            <div className="menu-account">
              <ul className="d-flex-row menu-my-account">
                <li>
                  <a href="#commandes" className="selected">
                    My orders
                  </a>
                </li>
                <li>
                  <a href="#licenses">My licenses</a>
                </li>
                {/*}<li><a href="#code_cadeaux">Code / cartes cadeaux</a></li>*/}
              </ul>
            </div>
          </div>
          {/* Overview */}

          <div class="col-md-12 list-account" id="commandes">
            <div className="top-list col-md-10 container-center">My orders</div>

            <OrdersComponent order={productOrder} />
          </div>

          {/* Licence */}
          <div className="col-md-12 list-account" id="licenses">
            <div className="top-list col-md-10 container-center">
              {" "}
              My licenses{" "}
            </div>
            {typeFaceOrder &&
              typeFaceOrder.slice(0, 100).map((order, i) => {
                return <TypeFaceOrders key={i} order={order} />;
              })}
          </div>

          {/*<div className="col-md-12 list-account no-padd" id="code_cadeaux">
						<div className="flexTop top-list col-md-10 container-center">
							<div className="top-list col-md-8">Code / Cartes cadeaux </div>
							<div className="whithblock2 top-list col-md-4 flexTop gift-code no-padd">
								<input className="inputCode col-md-10" type="text" placeholder="Entrer un code ..." />
								<button className="btn" type="submit">
									<p className="text text--xsmall no-padd">
										Ajouter
									</p>
								</button>
							</div>
						</div>
						<CodeCarteCadeaux />
					</div>*/}
        </div>

        {displayFooter}
        <Cart />
      </div>
    );
  }
}
Inventory.propTypes = {
  auth: PropTypes.object.isRequired,
  getOrders: PropTypes.func.isRequired,
  checkouts: PropTypes.object.isRequired,
  getPages: PropTypes.func.isRequired,
};
const mapStateToprops = (state) => ({
  footerData: state.footerData,
  auth: state.auth,
  checkouts: state.checkouts,
  pages: state.pages,
});
export default connect(mapStateToprops, { getOrders, getPages })(Inventory);
