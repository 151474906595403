import {
  GET_POSTS,
  POST_LOADING,
  GET_CATEGORIES_POST,
  CATEGORIES_POST_LOADING,
} from "./types";
import API, { subUrl } from "../utils/api";

// Categorie post
// let firstCategorie = [];
export const getPostCategories = () => (dispatch) => {
  dispatch(setPostCategoriesLoading());
  API.get(subUrl.postCategories)
    .then((res) => {
      dispatch({
        type: GET_CATEGORIES_POST,
        payload: res.data,
      });
    })
    .catch((error) => console.log(error));
};

// Categorie loading

export const setPostCategoriesLoading = () => {
  return {
    type: CATEGORIES_POST_LOADING,
  };
};

export const getPostInUseData = (data, currentLang) => (dispatch) => {
  let firstCat, resp;
  let lang = "";
  API.get(subUrl.postCategories).then((res) => {
    firstCat = res.data[0];
    resp = [];
    dispatch(setPostLoading());
    if (data.length < 1) {
      if (currentLang !== "en") {
        lang = currentLang;
      }
      dispatch(setPostLoading());

      API.get(subUrl.posts + "?lang=" + lang + "?amount=20")
        .then((res) => {
          dispatch({
            type: GET_POSTS,
            payload: res.data,
          });
        })
        .catch((error) => console.log(error));
    }
    API.get(
      subUrl.posts +
        "?category=" +
        data +
        "&category_by_slug=true&category_and=true"
    )
      .then((res) => {
        resp.push(JSON.stringify(res.data));
        dispatch({
          type: GET_POSTS,
          payload: res.data,
        });
      })
      .catch((error) => console.log(error));
  });
};

export const getPostData = (currentLang) => (dispatch) => {
  let lang = "";
  if (currentLang !== "en") {
    lang = currentLang;
  }
  dispatch(setPostLoading());

  API.get(subUrl.posts + "?lang=" + lang + "?amount=20")
    .then((res) => {
      dispatch({
        type: GET_POSTS,
        payload: res.data,
      });
    })
    .catch((error) => console.log(error));
};

export const setPostLoading = () => {
  return {
    type: POST_LOADING,
  };
};
