import classNames from "classnames";
import $ from 'jquery';
import lodash from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import styled from 'styled-components';
import { getFavorite } from '../../actions/favoriteAction';
import { getPages } from '../../actions/pagesAction';
import { getPostInUseData } from '../../actions/postActions';
import { getProductById, getProducts, getTypeFaceOnPage } from '../../actions/productsActions';
import history from "../../utils/history";
import isEmpty from '../../validation/is-empty';
import SimplePost from '../posts/SimplePost';
import Spinner from '../utils/Spinner';
import FooterIndex from "../_includes/footer/FooterIndex";
import HeaderRelative from "../_includes/header/Indexsanslogo";
import Cart from '../_includes/lastProduits/Cart';
import Modal from '../_includes/lastProduits/modal/Modal';
import OverviewProduct from '../_includes/lastProduits/OverviewProduct';
import ProductPaginate from '../_includes/lastProduits/productPaginate';
import User from '../_includes/login/Login';
import Font from './Font';
import OverviewTypeFace from './OverviewTypeFace';
class TypeFaceDetails extends Component {
	constructor(props) {
		super(props)
		this.state = {
			value: 10,
			currentData: null,
			activePage: 1,
			disabled: false,
			total: '',
			isShowing: false,
			productsRandom: [],
			informationClicked: true,
			storyClicked: false,
			downloadClicked: false,
			relatedClicked: false,
			fontWeight: '',
			fontStyle: '',
			familyFont: '',
			loading: false,
			loadModal: false,
			down: 'none',
			show: 'block',
			isToggleOn: false,
		}
		this.handleSwitcher = this.handleSwitcher.bind(this);
	}

	handleSwitcher() {
		this.setState(state => ({
			isToggleOn: !state.isToggleOn
		}));
	}

	componentDidMount() {
		$('html,body').animate({
			scrollTop: 0
		}, 'slow');
		this.initData();
	}

	initData() {

		var fixmeTop = $('.scroll-top-div');

		const { currencies, currentLange } = this.props;
		const { currencyChecked } = currencies;
		const { language } = currentLange;
		this.props.getPages()
		this.props.getTypeFaceOnPage(3, 1)
		const slug = this.props.match.params.id
		this.props.getProducts(language, currencyChecked.key);
		this.props.getProductById(slug)
		this.props.getPostInUseData(['font-in-use'])


		$(window).scroll(function () {
			var currentScroll = $(window).scrollTop();
			if (currentScroll >= 800) {

				$('.menu-page-details').addClass('dark');
				$('.top-header').addClass('dark');
			} else {
				$('.menu-page-details').removeClass('dark');
				$('.top-header').removeClass('dark');
			}
		});

		$('html,body').animate({
			scrollTop: 0
		}, 'slow');
		$("body").removeClass("body-lock");
		$(".top-header-right").removeClass("grise");
		
		

		

		class Parallax {
			constructor(object) {
				this.object = object;
				this.offset = this.object.offset().top;
				this.object.css("background-position-y", `${this.offset + $(window).scrollTop() / 4}px`)
			} parallax() {
				this.object.css("background-position-y", `${this.offset + $(window).scrollTop() / 4}px`)
			}
		}

		var parallax = new Parallax($('.parallax'));
		$(window).scroll(function () {
			parallax.parallax()
		})
	}

	componentDidUpdate(prev, next) {
		
		if (this.props.match.params.id !== prev.match.params.id) {
			console.log("init data to show : ", this.props, " prev : ", prev)
			window.location.reload()
		}
	}

	findItem = (cart, id) => {
		return cart.filter(cartItem => cartItem.slug === id)
	}
	_displayFavoriteImage(product) {
		if (product !== null) {
			var sourceImage = require('../../assets/images/heart-like.png')
			if (this.props.favorites.favorites.findIndex(item => item.id === product.id) !== -1) {
				sourceImage = require('../../assets/images/heart-dislike.png')
			}
			return (
				<img src={sourceImage} width="17" height="17" alt="welt" />
			)
		} else {
			return <Spinner />
		}

	}
	toIndex = () => {

		let page = (this.props.products.products.filter((product) =>
			product.choice_product === "Typeface"
		))[0]
		if (page !== undefined && page.slug !== undefined) {
			history.push("/shop/detail-typeface/" + page.slug);
			this.initData();
		}
		else {
			this.setState({ disabled: true })
		}
		this.setState({ activePage: 1, disabled: false, productsRandom: [] })
		this.props.getTypeFaceOnPage(2, 1);

	}
	nextProduct = () => {
		const { products } = this.props;
		let total = (products.total / 1) + 1
		let nextProduct = this.state.activePage + 1
		this.setState({ activePage: nextProduct, productsRandom: [] })
		if (total >= nextProduct) {
			this.setState({ disabled: false })
			let page = (this.props.products.products.filter((product) =>
				product.id !== this.props.products.product[0].id && product.choice_product === "Typeface" && product.id < this.props.products.product[0].id
			))[0]
			if (page !== undefined && page.slug !== undefined) {
				history.push("/shop/detail-typeface/" + page.slug);
				this.initData();
			}
			else {
				this.setState({ disabled: true })
			}
			this.props.getTypeFaceOnPage(1, nextProduct);
		}
		else {
			this.setState({ disabled: true })
		}
	}
	randomProducts = () => {
		//random
		let dataproduct = (this.props.products.products.filter((product) =>
			product.id !== this.props.products.product[0].id && product.choice_product === "Typeface"
		));
		let datalength = dataproduct.length;
		let page = [];
		for (var i = 0; i < datalength - 1; i++) {
			page.push(dataproduct.splice(Math.floor(Math.random() * dataproduct.length), 1)[0]);
		}
		//console.log(dataproduct)
		if (dataproduct !== undefined && dataproduct[0].slug !== undefined) {
			history.push("/shop/detail-typeface/" + dataproduct[0].slug);
			this.initData();
		}
		else {
			this.setState({ disabled: true })
		}
	}
	openModalHandler = () => {
		document.body.classList.add("no-sroll")
		this.setState({
			isShowing: true, loadModal: true
		});

		setTimeout(() => {
			this.setState({ loadModal: false })
		}, 1000);

	}
	closeModalHandler = () => {
		this.setState({
			isShowing: false
		});
		document.body.classList.remove("no-sroll")
	}
	onChangeStyle = (event) => {
		this.setState({ familyFont: event.target.value });

		if (event.target.value === 'thin') {
			this.setState({ fontStyle: 'normal', fontWeight: 100 });
		}
		if (event.target.value === 'thin-italic') {
			this.setState({ fontStyle: 'italic', fontWeight: 100 });
		}
		if (event.target.value === 'light') {
			this.setState({ fontStyle: 'normal', fontWeight: 300 });
		}
		if (event.target.value === 'light-italic') {
			this.setState({ fontStyle: 'italic', fontWeight: 300 });
		}
		if (event.target.value === 'regular') {
			this.setState({ fontStyle: 'normal', fontWeight: 400 });
		}
		if (event.target.value === 'regular-italic') {
			this.setState({ fontStyle: 'italic', fontWeight: 400 });
		}
		if (event.target.value === 'medium') {
			this.setState({ fontStyle: 'normal', fontWeight: 500 });
		}
		if (event.target.value === 'bold') {
			this.setState({ fontStyle: 'normal', fontWeight: 700 });
		}
		if (event.target.value === 'bold-italic') {
			this.setState({ fontStyle: 'italic', fontWeight: 700 });
		}
	}
	render() {

		const { products, currentProduct, pages, currencies, posts } = this.props;
		const { currencyChecked, loading } = currencies;
		const simplePost = [];
		let postdata, images, productsItems, productsRandom, displayFooter, ProductsTypeface, rendredPost, rendredDownload = null, intro__option1, intro__author, detaillist, contentdata, intro__option2;
		var fontName = '';
		var fontUrl = ''
		var fontUrl2 = '';
		const group = [];
		let faceOptions = [];
		let faceInCart = [];
		let styles = 0, essential = 0, half = 0;
		let shortDescription = '';
		let description = '';
		let fontPrice, halfPrice, essPrice;
		let check = false, halfcheck = false, esscheck = false, byitemchek = false;
		const paramsID = this.props.match.params.id
		let product = null

		if (pages.pageData === undefined || pages.pageData === null || pages.loading) {
			displayFooter = <Spinner />
		} else {
			let foundFooter = false;
			displayFooter = pages.pageData.map((page, i) => {
				if (pages.slug !== null) {
					let founded = (page.slug.indexOf("typeface") > -1);
					if (founded) {
						foundFooter = true;
						return (
							<FooterIndex
								key={i}
								footer={page}
								page="typeface"
							/>
						)
					}
				}
			})

			if (!foundFooter) {
				displayFooter = <FooterIndex page="typeface" />
			}
		}
		const productInCart = this.findItem(this.props.cart.cartItems, paramsID)[0]
		if (productInCart === undefined) {
			if (products.product !== null && products.product !== undefined) {
				product = products.product[0]
				if (product && product.typefaces !== null)
					product && product.typefaces && product.typefaces.fonts__collections && product.typefaces.fonts__collections.map((font) => {
						font.style.map((style, index) => {
							faceInCart.push({
								group: font.title,
								id: index,
								value: style,
								isChecked: true,
							})
						})
					}

					)
				faceInCart = lodash.groupBy(faceInCart, 'group')
			}
		} else {
			product = productInCart
			faceInCart = lodash.groupBy(productInCart.faceOption, 'group')
		}
		if (products.someProducts === null) {
			productsRandom = <Spinner />;
		} else {
			if (products.someProducts.length > 0) {
				if (this.state.productsRandom.length) {
					productsRandom = this.state.productsRandom.map((product, i) => {
						
						return (
							<ProductPaginate
								key={i}
								products={product}
								addToCart={this.props.addToCart}
								removeFromCart={this.props.removeFromCart}
								getFavorite = {this.props.getFavorite}
								toggleFavorite={this.props.toggleFavorite}
								favorites={this.props.favorites.favorites}
								getCurentProduct={this.props.getCurentProduct}
								openModal={this.openModalHandler}
								cartItem={this.props.cart.cartItems.filter(cartItem => cartItem.id === product.id)[0]}
							/>
						)
					})
				}
				else {
					productsRandom = products.someProducts?.map((product, i) => {
						if (paramsID !== product.slug)
						return (
							<ProductPaginate
								key={i}
								products={product}
								addToCart={this.props.addToCart}
								removeFromCart={this.props.removeFromCart}
								getFavorite = {this.props.getFavorite}
								toggleFavorite={this.props.toggleFavorite}
								favorites={this.props.favorites.favorites}
								getCurentProduct={this.props.getCurentProduct}
								openModal={this.openModalHandler}
								cartItem={this.props.cart.cartItems.filter(cartItem => cartItem.id === product.id)[0]}
							/>
						)
					})
				}
			}
		}
		if (product !== null && product.font_face !== undefined) {
			if (product.typefaces !== null)
				product && product.typefaces && product.typefaces.fonts__collections && product.typefaces.fonts__collections.map((font) => {
					group.push(font.title)
					font.style.map((style, index) => {
						styles = styles + 1
						faceOptions.push({
							group: font.title,
							id: index,
							value: style,
							isChecked: true,
							price: style.price,
							welt_price: (style.price * 100)
						})
					})
				}
				)
			fontPrice = Object.values(faceOptions).reduce((acc, curr) => {
				return acc + parseInt(curr.price, 10)
			}, 0)
			halfPrice = Object.values(faceOptions).reduce((acc, curr) => {
				let numb = 0
				if (curr.value.family.includes('Half Family'))
					numb = numb + parseInt(curr.price, 10)
				return acc + numb

			}, 0)
			essPrice = Object.values(faceOptions).reduce((acc, curr) => {
				let numb = 0
				if (curr.value.family.includes('Essential'))
					numb = numb + parseInt(curr.price, 10)
				return acc + numb

			}, 0)
			faceOptions = lodash.groupBy(faceOptions, 'group')
			group.forEach(group => faceOptions[group].forEach(option => {
				option.value.family.filter((e) => e === 'Essential' && essential++  )

			}))
			group.forEach(group => faceOptions[group].forEach(option => {
				option.value.family.filter((e) => e === 'Half Family' && half++ )

			}))
			let fam_ess = 0;
			let famhal = 0;
			let all = 0;
			let by = 0;
			group.forEach(group =>
				this.props.currentProduct.typeFaceOption !== undefined && this.props.currentProduct.typeFaceOption !== null && this.props.currentProduct.typeFaceOption.length !== 0 && this.props.currentProduct.typeFaceOption[group] !== undefined ? this.props.currentProduct.typeFaceOption[group].forEach(option => {
					check = false
					halfcheck = false
					esscheck = false
					byitemchek = false
					if (option.isChecked) {
						all++;
					}

					if (option.value.family.includes('Essential') && option.isChecked) {
						fam_ess++;
					}

					if (option.value.family.includes('Half Family') && option.isChecked) {
						famhal++;
					}
					if (option.value.family.includes('By Item') && option.isChecked) {
						by++;
					}


				}
				) :
					''
			)

			if (all === product.complete_total) {
				check = true;
				halfcheck = false;
				esscheck = false;
				byitemchek = false;
			}
			else if (product.half_total === famhal && all <= product.half_total) {
				halfcheck = true;
				check = false;
				esscheck = false;
				byitemchek = false;
			}
			else if (product.essential_total === fam_ess && all <= product.essential_total) {
				esscheck = true;
				halfcheck = false;
				check = false;
				byitemchek = false;

			}
			else if (by === 1) {
				esscheck = false;
				halfcheck = false;
				check = false;
				byitemchek = true;

			}
			else {
				halfcheck = false;
				check = false;
				esscheck = false;
				byitemchek = false;

			}

			fontUrl = product.font_face.thin !== undefined && product.font_face.thin.woff.url;
			fontUrl2 = product.font_face.thin !== undefined && product.font_face.thin.woff2.url;
			if (product.typefaces != null) {

				fontName = product.typefaces.font_family_name;
				let mystyles = product && product.typefaces && product.typefaces.fonts__collections && product.typefaces.fonts__collections.map((font => { return (font.style) }));
				mystyles && mystyles.map((element) => {
					//console.log(element.map(styles => { return (styles.file.woff.url) }))
				})

				let url = ""
				group && group.map((group) => {
					faceOptions[group].map((option) => {
						//console.log("optionsssssssss",option)
						if (option.value.file.woff.url !== undefined)
							url = url + `\@font-face {font-family: '${option.value.file.woff.title}'; src:url('${option.value.file.woff.url}')}\n`
					}
					)
				})

				let elem = mystyles && mystyles !== null && mystyles[0][0].file.woff
				var newStyle = document.createElement('style');
				newStyle.appendChild(document.createTextNode(url));
				document.head.appendChild(newStyle);
				var styleClass = document.createElement('style');
				styleClass.appendChild(document.createTextNode("\.customClass {font-family: '" + elem.title + "' !important;\ }\ "));
				document.head.appendChild(styleClass);
			}
		}

		if (product !== null && product.in_use !== undefined) {
			if (posts.post === null || posts.loading) {
				rendredPost = <Spinner />
			}
			else {
				const settingsCaroussel = {
					dots: false,
					slidesToShow: 4,
					infinite: false,
					responsive: [
						{
							breakpoint: 1024,
							settings: {
								slidesToShow: 3,
								infinite: false,
								dots: false
							}
						},
						{
							breakpoint: 768,
							settings: {
								dots: false,
								slidesToShow: 2,
								infinite: false,
							}
						},
						{
							breakpoint: 480,
							settings: {
								dots: false,
								slidesToShow: 1,
								infinite: false,
							}
						}
					]
				};
				const settings = {
					dots: false,
					infinite: true,
					speed: 500,
					slidesToShow: 1,
					slidesToScroll: 1,
					autoplay: false,
				};

				if (product.block_text[0] !== null && product.block_text[0] !== undefined) {
					if (product.block_text[0] !== null && product.block_text[0] !== false) {
						if (product.block_text[0].block_text !== false && product.block_text[0] !== null)
							postdata = product.block_text[0].block_text.map((block_text, i) => {
								return (
									/* ============================ Slider ============================ */
									block_text.acf_fc_layout === "block_slide" ?
										<Components.Slider className="col-md-12" key={i}>
											<div className="img-l-full">

												<Slider {...settings}>
													{block_text.slide.map((image, i) => {
														return (
															<img src={image} width="100%" />
														)
													}
													)}

												</Slider>
											</div>
										</Components.Slider>
										:
										/* ============================ Text full width –– Left justified  ============================ */
										block_text.acf_fc_layout === "content_blocks" ?
											<Components.TextFullWidthLeft className="col-md-10 text text--full-width container-center bg-blanc padding-t-20 padding-b-80 " key={i}>
												<div className="col-md-12 container-center text__content">
													<div dangerouslySetInnerHTML={{
														__html: block_text.content_block !== false && block_text.content_block[0] !== null ? block_text.content_block.map((block, i) => {
															return (
																block.content_block_column
															)
														}
														) : ''
													}}>

													</div>
												</div>
											</Components.TextFullWidthLeft>
											:
											/* ============================ Full width image ============================ */
											block_text.acf_fc_layout == "block_full_image" ?
												<Components.FullWidthImage className="col-md-12" key={i}>
													<div className="img-l-full">
														<img src={block_text.full_image} width="100%" />
													</div>
												</Components.FullWidthImage>
												:
												/* ============================ Publication ============================ */
												block_text.acf_fc_layout == "block_post" ?
													<Components.Publications className="col-md-12 padding-t-b-l-r-8 overflow-h align-center bg-corps">
														{/* Produits items */}
														{simplePost.length > 0 &&
															simplePost.map((post, i) => {
																while (i < 3) {
																	return (
																		<SimplePost key={i} post={post} />
																	)
																}
															})
														}

														{/* Produits items */}
														<div className="nav-between-produits col-md-12">
															<div className="col-md-12 align-center">
																<a className="nav-btn-top" />
																<ul>
																	{/* <li><a className="back-home">Back to index</a></li> */}
																	<li>
																		<Link to={{ pathname: `/newspaper` }}>More articles </Link>
																	</li>
																	{/* <li><a className="next">Next article</a></li> */}
																</ul>
															</div>
														</div>
													</Components.Publications>

													:
													/* ============================ Two columns –– Centered + Colored ============================ */
													block_text.acf_fc_layout == "text_with_color" ?
														<Components.TwoColsCenteredColors className="col-md-12 short-story bloc__2cols--centered" key={i} style={{ backgroundColor: block_text.color }}>
															<div className="col-md-10 container-center no-paddings" style={{ backgroundColor: block_text.color }}>
																<div className="col-md-10 list-account bloc__cols no-paddings" style={{ backgroundColor: block_text.color }}>
																	<div classNames="col-md-10 container-center cols no-paddings" style={{ backgroundColor: block_text.color }}>
																		<div className="col-md-5 float-left col__title">
																			<p style={{ color: block_text.text_color }}>{block_text.titre}</p>
																		</div>
																		<div className="col-md-7 float-left col__content">
																			<p style={{ color: block_text.text_color }}>{block_text.content}</p>
																		</div>
																	</div>
																</div>
															</div>
														</Components.TwoColsCenteredColors>
														:
														/* ============================ Random products preview ============================ */
														block_text.acf_fc_layout == "product_blocks" ?
															<Components.RandomProducts className="last-addition-shop">
																<div className="menu-addition related-produits">
																	<p>You may also like these beautiful products?</p>
																</div>
																<div className="items-last-add">
																	{/* Items */}

																	<Slider {...settingsCaroussel}>
																		{productsItems}
																	</Slider>
																	{/* End Items */}
																</div>
															</Components.RandomProducts>
															:
															''
								)

							})
					}

				}
				if (product.header[0] != null) {
					if (product.header[0].header != null && product.header[0].header != false) {
						if (product.header[0].header.block_option != false)
							contentdata = product.header[0].header.block_option.map((block_option, i) => {
								return (
									<div className="composants product__details">
										<span>{block_option.option}</span>
										{block_option.value}
									</div>
								)

							})

						if (product.header[0].header.block_option_center != false)
							intro__option1 = product.header[0].header.block_option_center.map((block_option_center, i) => {
								return (
									<div className="composants product__details">
										<span>{block_option_center.option}</span>
										{block_option_center.value}
									</div>
								)

							})

						if (product.header[0].header.block_option != false)
							intro__option2 = product.header[0].header.block_option.map((block_option, i) => {
								return (
									<div className="composants product__details">
										<span>{block_option.option}</span>
										{block_option.value}
									</div>
								)

							})

						intro__author = (
							<Components.AuthorInfos className="top-info-ds col-md-12">
								<div className="col-md-12 float-left">
									<div className="col-md-12">
										<div className={`tag fournisseur produit ${product.header[0].type_author != undefined && product.header[0].type_author != false && product.header[0].type_author != null && product.header[0].type_author}`}>
											<img src={product.header[0].image_author != undefined ? product.header[0].image_author != false ? product.header[0].image_author : '' : ''} alt="Welt" />
											<div className="info author__infos">
												<p className="infos__designed-by text text--xsmall">Designed by</p>
												<p className="infos__name text text--xsmall">{product.header != false && product.header[0] && product.header[0].header != null && product.header[0].header.author_information != null && product.header[0].header.author_information.author != null ? product.header[0].header.author_information.author.name : ''}</p>
											</div>
										</div>
										<div className="col-md-12">
											{product.header[0].header.tags != undefined && product.header[0].header.tags != false ? product.header[0].header.tags.map((tag) => {
												return (<>
													<div className="tag">{tag.name}</div>
												</>)
											}) : ''}

										</div>
									</div>
								</div>
							</Components.AuthorInfos>
						)
						detaillist = product.header[0].header.text_grand
					}

				}

				if (product.in_use[0].in_use != null && product.in_use[0].in_use != false)
					rendredPost = posts.post.filter(filtre => product.in_use[0].in_use.includes(filtre.id)).map((post, i) => {
						return (
							<div className="articles col-md-4 padding-l-r-4 float-left">

								<div className="img-l-full">
									<img src={post.featured_image.url} alt={post.featured_image.alt} />
								</div>
								<div className="description">
									<div className="category-link">
										<div className="category">Font in use</div>
									</div>
									<h2 className="title-medium">
										<div dangerouslySetInnerHTML={{ __html: post.excerpt }}></div>
									</h2>
									<div class="list-font-use">
										{
											post.tags.length > 0 &&
											<ul>
												{
													post.tags.map(tag =>
														<li>{tag.name}</li>
													)
												}
											</ul>
										}
									</div>
								</div>

							</div>
						)
					})
			}
		}
		// Download Posts
		if (product != null) {
			if (product.download != false && product.download != null) {
				rendredDownload = product.download.map((download, i) => {
					this.state.down = 'show'
					return (
						<div className="articles col-md-3 padding-l-r-4 float-left">
							<div className="img-l-full">
								<img src={download.preview.url} alt="Welt" />
							</div>

							<div className="description">
								<div className="category-link">
									<div className="category text text--small">PDF</div>
									<a href={download.pdf_file.url} target="blank" download className="text text--small">Download <img src="/images/download.svg" /></a>
								</div>

								<h2 className="title-medium" dangerouslySetInnerHTML={{ __html: download.title }}></h2>
							</div>
						</div>
					)
				})
			}

		}

		return (
			<>

				<header className="col-md-12 relative">
					<div className="img-articles-top">
						<div className={`img parallax typeface ${product && product.featured_image && product.featured_image.url === false ? "false" : ''}`} style={{ backgroundImage: product === null ? null : `url(${product.featured_image.url})` }}>
							{product === null ? null : <img src={product.featured_image.url} alt="Welt" style={{ opacity: 0 }} />}
							<div className="release-info d-flex-column-c here">
								<div className="container-center col-md-10">
								</div>
							</div>
						</div>
					</div>
					<div className={`col-md-12 menu-page-details brTypeFace ${product === null ? null : product.header_color} ${product && product.featured_image && product.featured_image.url === false ? "false" : ''}`}>
						<div className="breadcrumbs">
							<div className="col-md-6 float-left bgNoir">
								<ul>
									<li>
										{/* <Link to={{ pathname: `/`, }}><img src="/images/welt-breadcrumb-noir.svg" alt="Welt" /></Link> */}
										<Link to={{ pathname: `/typeface`, }}>type.WELTKERN®</Link>
									</li>
									<li>
										<Link to={{ pathname: '/shop', }}>Shop</Link>
									</li>
									<li>
										<Link to={{ pathname: '/shop/typefaces', }}>Typefaces</Link>
									</li>
									{product === null ?
										null :
										<li>
											<Link to={{ pathname: `/shop/detail-typeface/${paramsID}`, }} dangerouslySetInnerHTML={{ __html: product.name }}></Link>
										</li>
									}
								</ul>
							</div>
							<div className="col-md-6 float-left bgBlanc">
							</div>
						</div>
					</div>
				</header>
				<HeaderRelative col={product === null ? null : product.header_color} dark = {product && product.featured_image && product.featured_image.url === false ? "false" : 'true'} />
				{/* ============================ Fin 'HEADER'' ============================ */}
				{ product !== null &&
					<OverviewTypeFace
						product={product}
						addToCart={this.props.addToCart}
						cart={this.props.cart}
						addRangeValueToReducer={this.props.addRangeValueToReducer}
						getFavorite = {this.props.getFavorite}
						toggleFavorite={this.props.toggleFavorite}
						favorites={this.props.favorites.favorites}
						removeFromCart={this.props.removeFromCart}
						currencyChecked={currencyChecked}
						dark = {product && product.featured_image && product.featured_image.url === false ? "false" : ''}
						cartItem={this.props.cart.cartItems.filter(cartItem => cartItem.id === product.id)[0]}
						currentOption={this.props.currentProduct.typeFaceOption}
						currentFamilyPrice={this.props.currentProduct.familyPrice}
						auth={this.props.auth.isAuthenticated}
						family={check ? "Complete Family" : halfcheck ? 'Half Family' : esscheck ? 'Essential' : 'not-selected'}
					/>
				}

				<Details.Content className="col-md-12 clearfix ">
					<Details.Navbar className="col-md-10 container-center tab-list details__navbar">
						<div className="menu-account">
							<ul className="d-flex-row menu-my-account uppercase">
								<li style={product === null ? { display: this.state.down } : product.header != false && product.header != null && product.header[0].display != false ? { display: this.state.show } : { display: this.state.down }}><a href="#specificities">About</a></li>
								<li><a href="#styles" class={product === null ? { display: this.state.down } : product.header != false && product.header != null && product.header[0].display != false ? '' : 'selected'}>Styles</a></li>
								<li><a href="#layout">Layout</a></li>
								<li style={product === null ? { display: this.state.down } : product.download != false && product.download != null && product.header[0].display_download != false ? { display: this.state.show } : { display: this.state.down }}><a href="#trial-Specimen">Download/Specimen</a></li>
								<li style={product === null ? { display: this.state.down } : product.in_use != false && product.in_use != null & product.in_use[0].display != false ? { display: this.state.show } : { display: this.state.down }}><a href="#in-use">In use</a></li>
							</ul>
						</div>
					</Details.Navbar>



					{/* ============================ Début Onglet 'ABOUT' ============================ */}
					{product != null && product.header != false && product.header != null && product.header[0].display != false ?
						<Details.About className="col-md-12 short-story details--about" id="specificities">
							<div className="col-md-10 container-center bg-blanc about__intro">
								<div className="intro__author col-md-6">
									{intro__author}
								</div>
								<div className="intro__option1 col-md-6">
									<div className="col-md-6">
										{intro__option1}
									</div>
									<div className="col-md-6">
										{intro__option2}
									</div>
								</div>
								<div class="col-md-12">
									<div class="container-center list-account details-about">
										<div class="top-list col-md-12 container-center">
											{product != null ?
												<div className="detailText" dangerouslySetInnerHTML={{ __html: product.header[0].header.text_grand }}></div>
												:
												''
											}
										</div>
									</div>
								</div>
							</div>
							{/* -------------- Content -------------- */}
							{postdata}
						</Details.About> :
						''}
					{/* ============================ Fin Onglet 'ABOUT' ============================ */}

					{/* ============================ Début Onglet 'STYLES' ============================ */}
					<Details.Styles className="col-md-12 list-account" id="styles" style={product === null ? { display: this.state.down } : product.header != false && product.header != null && product.header[0].display != false ? { display: 'none' } : { display: 'block' }}>
						<Details.StylesIntro className="top-list col-md-10 container-center">
							<ul className="col-md-6 float-left">
								<li>{group.length} Collections</li>
								<li className="text text--medium-grey">{styles} Styles</li>
							</ul>
							<div className="col-md-6 float-left font-style">
								<div className="col-md-8">
									<div className="content-style">
										<p className="text text--medium-grey">
											<input type="text" placeholder="write here" className="text text--medium-grey" id="myInput" />
										</p>
									</div>
								</div>
								<div className="elts-style">
									<div className="control-font col-md-12">
										{/* Style Font */}
										<div className="col-md-12 overflow-h flex">
											<div className="color-theme-switcher">
												<label class="switch">
													<input type="checkbox" onClick={() => this.handleSwitcher()} />
													<span class="slider round"></span>
												</label>
											</div>
											<div className="align-text-transform">
												<div className="align">
													<div className="text-transform styles-transform">
														<a href="#0" className="initial active">Aa</a>
														<a href="#0" className="uppercase">AA</a>
														<a href="#0" className="lowercase">aa</a>
													</div>
												</div>
											</div>
											<div className="font-feature feature-transform">
												{product && product.typefaces && product.typefaces.font_feature && product.typefaces.font_feature[0] ? product.typefaces.font_feature.map((font) => {
													return (<>
														<a href="#0" className={font.post_title}>{font.post_title}</a>
													</>)
												}) : ''}
											</div>
										</div>
									</div>
								</div>
							</div>
						</Details.StylesIntro>
						<Details.StylesOverview className={`col-md-12 bg-blanc overflow-h padding-12 ${this.state.isToggleOn ? "theme-switch-dark" : ""}`}>
							<div className="theme-switch-dark--colors">
								<div className="col-md-1 float-left">
									<p className="text text--xsmall">Overview</p>
								</div>
								<div className="col-md-10 float-left">
									<div className="col-md-6 float-left">
										{group.map((group) => {
											return (<>
												<div className="col-md-12 float-left styles-collections">
													{group}
													<div className="list-collections resultat" >
														{faceOptions[group].map((option) => {
															return (<>
																<p className="result" style={{ fontFamily: option.value.file.woff.title }} >{group} {option.value.name} </p>
															</>)

														})}
													</div>
												</div>
											</>
											)
										})
										}
									</div>
								</div>
							</div>
						</Details.StylesOverview>
						<Details.StylesFamily className="col-md-12 overflow-h padding-l-r-12">
							<div className="list-font-collections details__content">
								<ul className="list" style={{ fontFamily: fontName }}>
									{product != null && product.typefaces != null && product.typefaces.display_pack.complete &&
									<li className="face-style list__bloc">
										<div className="col-md-12 specific-font">
											<div className="col-md-1 family__styles">
												<p className="text text--xsmall">{styles} styles</p>
											</div>
											<div className="lab for-select col-md-10 float-left">
												<div className="leftside col-md-6 float-left">
													<ul className="family__pack">
														<li className="text text--medium">Complete family</li>
														<li className="text text--medium text--medium-grey">{styles} Styles</li>
													</ul>
												</div>
												<div className="rightside col-md-6 float-right">
													<div className="font-select">
														<p className="text text--xsmall text--light-grey"><span className="text text--small"> CHF </span>25,00 per style</p>
													</div>
												</div>
											</div>
											<div className="col-md-1 for-select font-check flex">
												<div className="my">
													<p className="text text--xsmall prix">
														<span className="text text--small">{currencyChecked.key} </span>
														{product != null && product.typefaces && product.typefaces.complete_family_price && product.typefaces.complete_family_price} <br />
														<span className="text text--small text--light-grey"> ou </span>
														{(product != null && product.typefaces && product.typefaces.complete_family_price && product.typefaces.complete_family_price * 100)}W
                                                    </p>
													<label for="complete_family" class="list-typeface">
														<label for="complete_family" className={'complete_family' + ' ' + classNames({
															'checked': check
														})} />
													</label>
												</div>
												{product != null && product.multiplier != 0 ?
													<>
														{/* <div className="img">
															<img src="/images/pastille-weltx2.svg" alt="Welt" />
														</div> */}
													</> : ''}

											</div>
										</div>
									</li>
									}
									{product != null && product.typefaces != null && product.typefaces.display_pack.half &&	
									<li className="face-style list__bloc">
										<div className="col-md-12 specific-font">
											<div className="col-md-1 family__styles">
												<p className="text text--xsmall">{half} styles</p>
											</div>
											<div className="lab for-select col-md-10 float-left">
												<div className="leftside col-md-6 float-left">
													<ul className="family__pack">
														<li className="text text--medium">Half-family</li>
														<li className="text text--medium text--medium-grey">{half} Styles</li>
													</ul>
												</div>
												<div className="rightside col-md-6 float-right">
													<div className="font-select">
														<p className="text text--xsmall text--light-grey"><span className="text text--small">CHF </span>35,00 per style</p>
													</div>
												</div>
											</div>
											<div className="col-md-1 for-select font-check flex">
												<div className="my">
													<p className="text text--xsmall prix">
														<span className="text text--small">{currencyChecked.key} </span>
														{product != null && product.typefaces && product.typefaces.half_family_price && product.typefaces.half_family_price} <br />
														<span className="text text--small text--light-grey">ou </span>
														{(product != null && product.typefaces && product.typefaces.half_family_price && product.typefaces.half_family_price * 100)}W
                                                    </p>
													<label for="half" class="list-typeface">
														<label for="half" className={'half' + ' ' + classNames({
															'checked': halfcheck
														})} />
													</label>
												</div>
											</div>
										</div>
									</li>
									}
									{product != null && product.typefaces != null && product.typefaces.display_pack.essential &&
									<li className="face-style list__bloc">
										<div className="col-md-12 specific-font">
											<div className="col-md-1 family__styles">
												<p className="text text--xsmall">{essential} styles</p>
											</div>
											<div className="lab for-select col-md-10 float-left">
												<div className="leftside col-md-6 float-left">
													<ul className="family__pack">
														<li className="text text--medium">Essential</li>
														<li className="text text--medium text--medium-grey">{essential} Styles</li>
													</ul>
												</div>
												<div className="rightside col-md-6 float-right">
													<div className="font-select">
														<p className="text text--xsmall text--light-grey"><span className="text text--small">CHF </span>45,00 per style</p>
													</div>
												</div>
											</div>
											<div className="col-md-1 for-select font-check flex">
												<div className="my">
													<p className="text text--xsmall prix">
														<span className="text text--small">{currencyChecked.key} </span>
														{product != null && product.typefaces && product.typefaces.essential_price && product.typefaces.essential_price} <br />
														<span className="text text--small text--light-grey">ou </span>
														{(product != null && product.typefaces && product.typefaces.essential_price && product.typefaces.essential_price * 100)}W
                                                    </p>
													<label for="essentials" class="list-typeface">
														<label for="essentials" className={'essentials' + ' ' + classNames({
															'checked': esscheck
														})} />
													</label>

												</div>
											</div>
										</div>
									</li>
									}
								</ul>
							</div >
						</Details.StylesFamily>

						<Details.StylesCollection className="list-font-collections">
							<div className="col-md-10 list-collections__name">
								<p>{fontName}</p>
							</div>
							{
								group.map((group => {
									return (
										<>
											<div className="collection__name col-md-10">
												<p>{group}</p>
											</div>
											{faceOptions[group].map((option) => {
												return (
													<>
														<ul className="list-collections__fonts" >
															<li className="face-style list-collections__font">
																<div className="col-md-1 float-left font__name">
																	<p className="text text--xsmall">{group}</p>
																	<p className="text text--xsmall">{option.value.name}</p>
																</div>
																<div className="col-md-10 float-left font__example" contentEditable="true" suppressContentEditableWarning={true} style={{ fontFamily: option.value.file.woff.title }}>
																	<p className="text text--big" style={{ fontFamily: option.value.file.woff.title }}>{option.value.long_text_for_style}</p>
																</div>
																<div className="col-md-1 float-left align-right for-select font__select">
																	<label for={option.value.name.toLowerCase().replace(/ /g, '_') + "_" + group} className="list-typeface sign">
																		<label for={option.value.name.toLowerCase().replace(/ /g, '_') + "_" + group} className={option.value.name.toLowerCase().replace(/ /g, '_') + "_" + group + ' ' + classNames({
																			'checked': this.props.currentProduct.typeFaceOption != undefined && this.props.currentProduct.typeFaceOption != null && this.props.currentProduct.typeFaceOption.length != 0 && this.props.currentProduct.typeFaceOption[group] != undefined ? this.props.currentProduct.typeFaceOption[group][option.id].isChecked : true
																		})}
																		/>
																		<div>
																			<p className="text text--xsmall prix">
																				<span className="text text--small">{currencyChecked.key} </span>{option.price}<br />
																				<span className="text text--small text--medium-grey">ou</span> {option.welt_price}W
                                                                </p>
																			<span className="selected text text--xsmall">Selected</span>
																		</div>
																	</label>
																</div>
															</li>
														</ul>
													</>
												)
											})
											}
										</>)
								}))
							}
						</Details.StylesCollection>
					</Details.Styles>
					{/* ============================ Fin Onglet 'STYLES' ============================ */}

					{/* ============================ Début Onglet 'LAYOUT' ============================ */}
					<Font product={product} fontName={fontName} group={group} faceOptions={faceOptions} />
					{/* ============================ Fin Onglet 'LAYOUT' ============================ */}

					{/* ============================ Début Onglet 'DOWNLOAD/SPECIMEN' ============================ */}
					<div className="col-md-12 list-account" id="trial-Specimen">
						<div className="top-list col-md-10 container-center">
							Download
                            </div>
						<div className="col-md-12 padding-t-b-l-r-8 overflow-h align-center bg-corps">
							{/* Produits items */}
							{rendredDownload}
							{/* End Produits items */}

						</div>
					</div>

					{/* ============================ Fin Onglet 'DOWNLOAD/SPECIMEN' ============================ */}

					{/* ============================ Début Onglet 'IN USE' ============================ */}
					<div className="col-md-12 list-account" id="in-use">
						<div className="col-md-10 container-center bg-blanc">
							<div className="top-info-ds col-md-12">
								<div className="col-md-6 float-left">
									<div className="col-md-12">
										<div className="tag fournisseur">
											<div className="info no-profil">Tag</div>
										</div>
									</div>
									<div className="col-md-12">
										{product != null && product.header != false && product.header[0].tags_author ? product.header[0].tags_author.map((tag) => <div className="tag">{tag.name}</div>) : ''}
									</div>
								</div>
							</div>
						</div>
						{/* Produits */}
						<div className="col-md-12 padding-t-b-l-r-8 overflow-h align-center bg-corps">
							{/* Produits items */}
							{rendredPost}
							{/* End Produits items */}
						</div>
						{/* Produits */}
					</div>
					{/* ============================ Fin Onglet 'IN USE' ============================ */}
				</Details.Content>
				{/* End Derniers produits ajoutés */}
				<div className="nav-between-produits col-md-12">
					<div className="col-md-12 align-center">
						<a className="nav-btn-top" />
						<ul>
							<li><a href="/shop/typefaces" className="back-home">Back to index</a></li>
							<li><a onClick={this.randomProducts} className="random">Random product</a></li>
							<li>{this.state.disabled ? <a style={{ cursor: 'no-drop' }} className="next">Next product</a> : <a onClick={this.nextProduct} className="next">Next product</a>}</li>
						</ul>
					</div>
				</div>
				{/* Derniers produits ajoutés */}
				<div className="last-addition-shop">
					<div className="menu-addition related-produits">
						<p>You may also like these beautiful products?</p>
					</div>
				</div>
				<div className="col-md-12 padding-t-b-l-r-8 overflow-h align-center view-produits">
					{/* Produits items */}
					{productsRandom}
					{/* End Produits items */}
				</div>
				<Modal
					className="modal"
					show={this.state.isShowing}
					close={this.closeModalHandler}>
					{/* <div className="modal-header">
                        <h3>title</h3>
                    </div> */}
					<div className="modal-body clearfix quick-purchase">
						<span className="close-modal-btn" onClick={this.closeModalHandler}>×</span>
						{/* characteristics Poduct */}
						{(!isEmpty(currentProduct.currentProduct) && !this.state.loadModal) ?
							<OverviewProduct
								show={this.state.isShowing}
								currentProduct={currentProduct.currentProduct}
								addToCart={this.props.addToCart}
								addRangeValueToReducer={this.props.addRangeValueToReducer}
								cart={this.props.cart}
								getFavorite = {this.props.getFavorite}
								toggleFavorite={this.props.toggleFavorite}
								favorites={this.props.favorites.favorites}
								removeFromCart={this.props.removeFromCart}
								cartItem={this.props.cart.cartItems.filter(cartItem => cartItem.id === currentProduct.currentProduct.id)[0]}
								currencyChecked={this.props.currencies.currencyChecked}

							/>
							: <Spinner />
						}


					</div>
				</Modal>

				{displayFooter}
				<Cart />
				<User />


			</>)
	}
}

const mapStateToProps = (state) => ({
	cart: state.cart,
	products: state.products,
	favorites: state.favorites,
	pages: state.pages,
	currentProduct: state.currentProduct,
	currencies: state.currencies,
	currentLange: state.translation,
	posts: state.postObject,
	auth: state.auth,
	users: state.users



})


const mapDispatchToProps = (dispatch) => {
	return {
		addToCart: (item) => {
			dispatch({ type: 'ADD', payload: item })
		},
		addRangeValueToReducer: (item) => {
			dispatch({ type: 'GET_RANGE_VALUE', payload: item })
		},
		removeFromCart: (item) => {
			dispatch({ type: 'REMOVE', payload: item })
		},
		getCurentProduct: (item) => {
			dispatch({ type: 'GET_CURRENT_PRODUCT', payload: item })
		},

		getProducts: () => dispatch(getProducts()),
		getPostInUseData: () => dispatch(getPostInUseData(['font-in-use'])),
		getProductById: (id) => dispatch(getProductById(id)),
		getPages: () => dispatch(getPages()),
		getTypeFaceOnPage: (perPage, paramater) => dispatch(getTypeFaceOnPage(perPage, paramater)),
		getFavorite: () => dispatch(getFavorite()),
	}
}

const Details = {
	Content: styled.div`
    font-family: Lausanne600;
    `,
	Navbar: styled.div`
    height: 50px;
    .menu-account {
        ul {
            li {
                a {
                    display: flex;
                    justify-content: center;
                    padding-left: 0;
                    padding: 0 5px;
                }
            }
        }
    }
    `,
	/* ================================== Onglet 'ABOUT' ==================================*/
	About: styled.div`
    .about__intro { padding: 0; }
    .intro__author {
        float: left;
    }

    .intro__option1 {
        float: right;
        display: flex;
        padding: 14px 20px 14px 20px;
        .product__details {
            span {
                padding-right: 5px;
                color: #D8D8D8;
            }
        }
    }
    `,
	/* ================================== Onglet 'STYLES' ==================================*/
	Styles: styled.div``,
	StylesIntro: styled.div`
    .control-font { font-family: 'Lausanne400'; }
    .flex {
        display: flex;
        justify-content: space-between;
        padding-top: 6px;
        gap: 5px;
    }
    .align-text-transform {
        .align {
            padding-top: 0 !important;
            .text-transform {
                display: flex;
                flex-wrap: wrap;
                row-gap: 5px;
                a {
                    margin-right : 0;
                }
            }
        }
    }
    .color-theme-switcher {
        .switch {
            position: relative;
            display: inline-block;
            width: 72px;
            height: 36px;

            input {
                opacity: 0;
                width: 0;
                height: 0;
                &:checked + .slider {
                    background-color: none;
                    border: 1px solid black;
                    &:before {
                        -webkit-transform: translateX(36px);
                        -ms-transform: translateX(36px);
                        transform: translateX(36px);
                    }
                }

                &:focus + .slider { box-shadow: 0 0 1px #2196F3; }
            }

            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: none;
                border: 1px solid black;
                -webkit-transition: .4s;
                transition: .4s;

                &.round  {
                    border-radius: 36px;
                    &:before {
                        border-radius: 50%;
                    }
                }

                &:before {
                    position: absolute;
                    content: "";
                    height: 100%;
                    width: 34px;
                    left: 0;
                    bottom: 0;
                    background-color: #EBEBEB;
                    -webkit-transition: .4s;
                    transition: .4s;
                }
            }
        }
    }

    `,

	StylesOverview: styled.div`
    padding-bottom: 50px;
    min-height: 450px;

    &.theme-switch-dark{
        background: #141414;
        color: white !important;
        .theme-switch-dark--colors {
            div, ul, li, p  {
                color: white !important;
            }
        }
    }
    `,

	StylesFamily: styled.div`
    .details__content {
        padding: 4px 0;
        .list__bloc:first-child { height: 155px; }
        .list__bloc:nth-child(2) { height: 100px; }
        .list__bloc { font-family: Lausanne600; }

        .specific-font {
            padding: 0 5px 5px 5px;
            .col-md-10 { padding: 0; }
            .flex {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                padding-bottom: 10px;
                .my {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                }
            }
            .font-check {
                float: right;
                text-align: right;
            }

            .leftside,
            .rightside { height: 100%; }

            .rightside {
                text-align: right;
            }
        }

        .family__styles { 
            float: left;
            p { line-height: 25px; }
        }

        .font-select { line-height: 25px; }
        .font-select, .flex { margin-top: 3px; }

        .specific-font,
        .lab {height: 100%;}
    }
    `,

	StylesCollection: styled.div`
    .collection__name {
        margin: 0 auto;
        clear: both;
        padding-top: 40px;
    }
    .list-collections__name { margin: 0 auto; }
    .list-collections__fonts {
        .list-collections__font {
            clear: both;
            &:last-child {
                .font__example { 
                    margin-bottom: 0;
                }
            }
        }
        .font__example {
            padding: 0 0 12px 12px;
            margin: 3px 0;
            p { white-space: nowrap; }
        }

        .font__select {
            font-family: Lausanne600;
            label {
                &.sign {
                    display: flex;
                    flex-direction: row-reverse;
                    flex-wrap: wrap;
                    label {
                        display: flex;
                        align-self: flex-start;
                        margin-left: 10px;
                        margin-bottom: 3px;
                    }
                }
            }
        }
    }
    `,
	/* ================================== Onglet 'LAYOUT' ==================================*/
	Layout: styled.div`
    .layout__content {
        background: #F7F7F7;

        .editable-font {
            opacity: 0;
            &:hover { opacity: 1; }
            margin-top: 30px;
        }

        .flex-reverse {
            display: flex;
            flex-direction: column;
        }
    }
    .remove { float: left; }
    .content-style {
        margin: 0 auto;
        background: inherit;
		overflow: hidden auto;
    }
    .order {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-right: 12px;
    }
    .render {
        justify-content: space-between;
        width: 100%;
        display: flex;
    }
    `,
}

const Components = {
	Slider: styled.div``,
	TextFullWidthLeft: styled.div`
    &.text--full-width {
        padding: 20px 0 80px 0;
        .text__content { padding: 0 20px; }
        p {
            font-size: 36px;
            line-height: 39px;
        }
    }
    `,
	FullWidthImage: styled.div`
    .img-l-full {
        img {
            height: auto;
        }
    }
    `,
	Publications: styled.div``,
	TwoColsCenteredColors: styled.div`
    .no-paddings { padding: 0;}
    .bloc__cols { margin: 0 auto; }
    `,

	RandomProducts: styled.div``,
	AuthorInfos: styled.div`
    .author__infos {
        text-align: center;
        .infos__designed-by { color: #595959; }
        .infos__name { color: white; }
    }
    `,
}
export default connect(mapStateToProps, mapDispatchToProps)(TypeFaceDetails)
