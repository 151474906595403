/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    "elementType": "geometry",
    "stylers": [{
      "color": "#1F1F1F"
    }]
  }
   , {
     "elementType": "labels.icon",
     "stylers": [{
       "visibility": "off"
     }]
   },
  // , {
  //   "elementType": "labels.icon",
  //   "stylers": [{
  //     "visibility": "on"
  //   }]
  // }, 
  // {
  //   "elementType": "labels.icon",
  //   "stylers": [{
  //     "visibility": "on"
  //   }]
  // }, 

  {
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#4D4D4D",
      "font-size":"6px"
    }]
  }, 
  
  //{
  //   "elementType": "labels.text.stroke",
  //   "stylers": [{
  //     "color": "#fff"
  //   }]
  // }, {
  //   "featureType": "administrative.land_parcel",
  //   "elementType": "labels.text.fill",
  //   "stylers": [{
  //     "color": "#3475B1"
  //   }]
  //}, 
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [{
      "color": "#000000"
    }]
  }, 
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [{
      "color": "#4D4D4D"
    }]
  }, 

 {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#000000"
    }]
  },

  // {
  //   "featureType": "road",
  //   "elementType": "geometry",
  //   "stylers": [{
  //     "color": "#ffffff"
  //   }]
  // },
  {
        "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#4D4D4D",
      "font-size": "5px"
    }]
  }

  //{
  //   "featureType": "poi",
  //   "elementType": "labels.text.fill",
  //   "stylers": [{
  //     "color": "#757575",
  //     "visibility": "off"
  //   }]
  // }, {
  //   "featureType": "poi.park",
  //   "elementType": "geometry",
  //   "stylers": [{
  //     "color": "#e5e5e5"
  //   }]
  // }, {
  //   "featureType": "poi.park",
  //   "elementType": "labels.text.fill",
  //   "stylers": [{
  //     "color": "#9e9e9e"
  //   }]
  // }, {
  //   "featureType": "road",
  //   "elementType": "geometry",
  //   "stylers": [{
  //     "color": "#ffffff"
  //   }]
  // }, {
  //   "featureType": "road.arterial",
  //   "elementType": "labels.text.fill",
  //   "stylers": [{
  //     "color": "#3475B1"
  //   }]
  // }, {
  //   "featureType": "road.highway",
  //   "elementType": "geometry",
  //   "stylers": [{
  //     "color": "#FFFFFF"
  //   }]
  // }, {
  //   "featureType": "road.highway",
  //   "elementType": "labels.text.fill",
  //   "stylers": [{
  //     "color": "#3475B1"
  //   }]
  // }, {
  //   "featureType": "road.local",
  //   "elementType": "labels.text.fill",
  //   "stylers": [{
  //     "color": "#9e9e9e"
  //   }]
  // }, {
  //   "featureType": "transit.line",
  //   "elementType": "geometry",
  //   "stylers": [{
  //     "color": "#e5e5e5"
  //   }]
  // }, {
  //   "featureType": "transit.station",
  //   "elementType": "geometry",
  //   "stylers": [{
  //     "color": "#eeeeee"
  //   }]
  // }, {
  //   "featureType": "water",
  //   "elementType": "geometry",
  //   "stylers": [{
  //     "color": "#CFE9FE"
  //   }]
  // }, {
  //   "featureType": "water",
  //   "elementType": "labels.text.fill",
  //   "stylers": [{
  //     "color": "#3475B1"
  //   }]
  // }
];

