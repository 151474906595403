import React, { Component } from "react";
import { connect } from "react-redux";
import { getCurrencyData } from "../../../actions/currencyAction";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { sort } from "../../../utils/helpers";
import styled from "styled-components";
import paypal from "../../../assets/images/paypal.svg";
import carte from "../../../assets/images/carte-credit.svg";
import PayPalBtn from "./PayPalBtn";
import { confirmAlert } from "react-confirm-alert";
import { payOrder } from "../../../actions/checkoutsActions";
import { PropTypes } from "prop-types";

const InjectedCheckoutForm = () => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <CheckoutForm stripe={stripe} elements={elements} />
    )}
  </ElementsConsumer>
);
class CheckoutStripe extends Component {
  state = {
    showPaypal: false,
  };

  showPaypalButtons = () => {
    this.setState({ showPaypal: true });
  };
  paymentHandler = (details, data) => {
    /** Here you can call your backend API
			endpoint and update the database */

    const payOrder = {
      payment_method: "paypal",
      order_id: this.props.orders.sendedOrder.order_id,
      payment_token: details.id,
      status: details.status,
    };
    if (details.status == "COMPLETED") {
      this.props.payOrder(payOrder);
    }

    confirmAlert({
      title: "Commande effectué",
      message: "Votre commande .......",
      buttons: [
        {
          label: "Ok",
          onClick: () => {
            this.props.history.push("/account/inventaire");
          },
        },
      ],
    });
  };
  render() {
    const { cart, currencyChecked, orders, auth } = this.props;
    const { isAuthenticated, user } = auth;
    const stripePromise = loadStripe(orders.sendedOrder.stripe_publishable_key);
    let orderItems;
    orderItems = orders.sendedOrder.line_items.map((item, i) => {
      return (
        <tr style={{ border: "1px solid #ddd", textAlign: "center" }} key={i}>
          <td>
            <center
              style={{ overflow: "hidden", maxWidth: "100%" }}
              className="thumbnail-checkout"
            >
              <img src={item.image.url} />
            </center>
          </td>
          <td>
            <span style={{ display: "none", overflow: "hidden", fontSize: 0 }}>
              Produit
            </span>
            {item.name}
          </td>
          <td>
            <span style={{ display: "none", overflow: "hidden", fontSize: 0 }}>
              Quantité
            </span>
            {item.quantity}
          </td>
          <td style={{ textAlign: "center" }}>
            <span style={{ display: "none", overflow: "hidden", fontSize: 0 }}>
              Price
            </span>
            {orders.sendedOrder.currency} {item.subtotal}
          </td>
          <td style={{ textAlign: "center" }}>
            <span style={{ display: "none", overflow: "hidden", fontSize: 0 }}>
              Paid Point
            </span>{" "}
            {item.paid_point}
          </td>
        </tr>
      );
    });

    return (
      <CheckoutPage.Content className="panier-contribution panier-content orders">
        <div className="col-md-10">
          {!isAuthenticated ? (
            ""
          ) : (
            <button
              className="cheekout prev"
              onClick={this.props.history.goBack}
            >
              Précédent
            </button>
          )}
          <div className="col-md-12 adresse-paiment container-center">
            <div className="adresse">
              <div className="clearfix">
                <div className="connect-content">
                  <div className="nom-account">
                    <p>Hello,</p>
                    <div className="nom">{user.user_display_name}</div>
                    <div className="status silver">Silver</div>
                  </div>
                </div>
              </div>
              <div className="connect-content">
                <div className="moyen">
                  <p>Moyen de paiements </p>
                  <div className="liste-methode">
                    <div className="form-submit">
                      <div className="button-paie">
                        {this.state.showPaypal ? (
                          <PayPalBtn
                            clientId={orders.sendedOrder.paypal_publishable_key}
                            currency={currencyChecked.key}
                            amount={orders.sendedOrder.total}
                            onSuccess={this.paymentHandler}
                          />
                        ) : (
                          <button
                            type="submit"
                            className="cheekout"
                            onClick={this.showPaypalButtons}
                          >
                            PayPal
                          </button>
                        )}
                      </div>
                      <div className="image">
                        <img src={paypal} />
                      </div>
                    </div>
                    <div className="form-submit">
                      <div className="button-paie">
                        <button
                          id="credit-card"
                          type="submit"
                          className="cheekout"
                        >
                          Carte crédit
                        </button>
                      </div>
                      <div className="image">
                        <img src={carte} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Elements stripe={stripePromise}>
                <InjectedCheckoutForm />
              </Elements>
            </div>

            <CheckoutPage.Products className="produits content-panier">
              <div className="overview">
                <p>Récapitulatif de la commande</p>
                <div className="recap">
                  <div className="col-md-12">
                    {/* Listes produits */}
                    {sort(orders.sendedOrder.line_items).map((item, i) => {
                      return (
                        <div className="list-produit content" key={i}>
                          <div className="images img-l-full">
                            <img src={item.image.url} alt="Welt" />
                          </div>
                          <div className="container d-flex-column-s-b">
                            <div className="clearfix wraper-info">
                              <div className="information">
                                <h3
                                  className="card-title"
                                  dangerouslySetInnerHTML={{
                                    __html: item.name,
                                  }}
                                ></h3>
                                {item.choice_product === "Typeface" ? (
                                  <>
                                    <p class="product__pack text text--xsmall">
                                      {item.family}
                                    </p>
                                  </>
                                ) : (
                                  <span className="short-text">
                                    {item.quantity}x
                                  </span>
                                )}
                              </div>
                              <div className="prix">
                                <div className="price-items clearfix">
                                  {item.choice_product === "Typeface" &&
                                  item.licenceChecked == "Trial version" ? (
                                    <p className="text">FREE</p>
                                  ) : (
                                    <>
                                      <span>{currencyChecked.key}</span>
                                      <span>
                                        <p className="text text--xsmall price-virgule">
                                          {" "}
                                          {item.subtotal}{" "}
                                        </p>
                                      </span>
                                    </>
                                  )}
                                </div>

                                {item.choice_product === "Typeface" &&
                                item.licenceChecked == "Trial version" ? (
                                  ""
                                ) : (
                                  <div className="price-items clearfix">
                                    {<span>+{item.paid_point}W</span>}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="feature-info"></div>
                            {item.choice_product === "Typeface" ? (
                              <p className="text text--xsmall license__choice">
                                {item.licenceChecked}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-md-12">
                    <div className="bloc-livraison">
                      Livraison offerte*{" "}
                      <span>
                        <img src="/images/checked.svg" />
                      </span>
                    </div>
                    <div className="bloc-livraison">
                      Bonus{" "}
                      <span>
                        <img src="/images/checked.svg" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="total-paiement clearfix">
                <div className="total">
                  <span>Total</span>
                </div>
                <div className="prix">
                  <span className="price-virgule">
                    {orders.sendedOrder.total}
                  </span>
                  <span>{orders.sendedOrder.currency} </span>
                </div>
                {/* {this.props.cart.cartItems.reduce((acc,current) => {
							  let pointValue = current.options.only_welt_point ? current.pointPrice.value * current.quantity : current.pointPrice.value
							  return acc + pointValue 
					  		}, 0)}W selected */}

                <div className="place-order">
                  <div className="text">
                    <p>
                      In placing your order, you are confirming that you have
                      read and agree to our Terms and Conditions. Please also
                      see our Privacy Policy and our Cookies Policy.
                    </p>
                  </div>
                </div>
              </div>
            </CheckoutPage.Products>
            {/* Produits */}
          </div>
        </div>
      </CheckoutPage.Content>
    );
  }
}

CheckoutStripe.protoTypes = {
  payORder: PropTypes.func.isRequired,
};

const mapStateToprops = (state) => ({
  currencies: state.currencies,
  auth: state.auth,
  currencyChecked: state.currencies.currencyChecked,
  cart: state.cart,
  orders: state.checkouts,
});
const mapDispatchToProps = (dispatch) => {
  return {
    initializeCarte: () => dispatch({ type: "INIALIZE_CART" }),
    getCurrencyData: () => dispatch(getCurrencyData()),
  };
};

const CheckoutPage = {
  Content: styled.div`
    &.panier-contribution
      .adresse-paiment
      .adresse
      .connect-content
      .identification
      .form-submit
      button {
      background: none !important;
      border: 1px solid black !important;
    }
    .container-center {
      /* height: calc(100vh - 36px); */
    }
    .adresse {
      height: 100%;
    }
    background: #ebebeb;
  `,

  Products: styled.div`
    height: 100%;
  `,
};

export default connect(mapStateToprops, { payOrder, mapDispatchToProps })(
  CheckoutStripe
);
