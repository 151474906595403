import React, { Component } from "react";

export class Conditions extends Component {
  constructor() {
    super();
    this.state = {
      acceptCondition: false,
    };
  }
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, onChange } = this.props;
    return (
      <>
        <div className="clearboth register__terms-n-conditions">
          <div className="col-md-12">
            <h4>4.Terms and conditions</h4>
          </div>
          <div className="col-md-12">
            <div className="terms__desc col-md-12">
              <div className="desc__content">
                <p className="text text--small">
                  The customer loyalty programme CLUB.WELTKERN® (“CLUB.WK®”)
                  rewards the loyalty of its members as customers. CLUB.WK®
                  offers members the opportunity of earning or redeeming W
                  points.
                </p>
                <p className="text text--small">
                  A number of conditions apply to earning and redeeming W points
                  and the general operation of CLUB.WELTKERN®, which we have
                  summarised for you here.
                </p>

                <p className="text text--small">
                  1 Membership
                  <br />
                  1.1 Persons eligible for membership
                  <br />
                  Those persons eligible for membership are limited to
                  individual natural persons who are at least two years of age
                  and who are resident in a country in which CLUB.WELTKERN® is
                  offered.
                  <br />
                  The place of residence is deemed to be the actual physical
                  home (primary residence) of the member. Details of the person
                  and the residence must be submitted accurately and truthfully
                  and upon request proof must be provided to one of the
                  operators.
                </p>
                <p className="text text--small">
                  There is no legal entitlement to membership of CLUB.WELTKERN®.
                  The WELTKERN SÀRL may refuse admission to participate in
                  CLUB.WELTKERN® without providing any reasons.
                </p>

                <p className="text text--small">
                  1.2 Start of membership
                  <br />
                  Membership starts when an individual account is opened at the
                  customer’s request. If the applicant is already a member of
                  CLUB.WELTKERN®, a new application to open another personal
                  account is invalid, i.e., only one account per person can be
                  opened and held. To notify the new member of the opening of
                  his/her account he/she will receive a provisional
                  CLUB.WELTKERN® card number.
                </p>
                <p className="text text--small">
                  1.2.1 Temporary member accounts
                  <br />
                  The provisional CLUB.WELTKERN® card number entitles the holder
                  to earn W Points for a period of six months. The application
                  to open the mileage account must be fully completed by the
                  customer within this period. Mileage accounts with missing or
                  incomplete personal data will be considered “temporary
                  accounts” and will be closed within six months of the first W
                  Points being credited to the temporary card number.
                </p>

                <p className="text text--small">
                  1.3 Permanent member accounts
                  <br />
                  Once the member has collected a minimum of one award mile,
                  he/she becomes a REGULAR member and enjoys the benefits of
                  this first level.
                </p>

                <p className="text text--small">
                  1.4 Membership status
                  <br />
                  CLUB.WELTKERN® allows the member to obtain a higher status
                  which grants privileges. The respective status is generally
                  governed by the number of W Points Status earned within a
                  specified period. After the specified period has elapsed, the
                  counting of W Points Status starts again. Different benefits
                  are associated with each status. More information on the
                  qualification criteria, the time duration of a status and its
                  associated benefits are set out in on the CLUB.WELTKERN® page
                  (club.weltkern.com). Benefits associated with a status can
                  also be offered by the CLUB.WELTKERN® partners. The nature,
                  extent and duration of these benefits are published in the
                  CLUB.WELTKERN® page (club.weltkern.com).
                </p>

                <p className="text text--small">
                  2 W Points
                  <br />
                  2.1 General
                  <br />
                  The basis of the calculation for the CLUB.WELTKERN® programme
                  is the number of W Points credited to the member’s mileage
                  account. W Points can be used only for the purposes expressly
                  specified in the Terms and conditions of participation,
                  additional programme documents or the CLUB.WELTKERN® page
                  (club.weltkern.com).
                </p>

                <p className="text text--small">
                  2.1.1 Types of W Points
                  <br />
                  There are different types of W Points which a member can earn
                  in CLUB.WELTKERN®. The different categories are W Points Prime
                  and W Points Status. Awards can be obtained in CLUB.WELTKERN®
                  with W Points Prime. W Points Status determine your status
                  within CLUB.WELTKERN®. “W Points” as defined in these Terms
                  and conditions of participation are all types of
                  CLUB.WELTKERN® W Points, unless specifically stated otherwise
                  below.
                </p>

                <p className="text text--small">
                  2.1.2 Transferability of W Points and trading W Points
                  <br />W Points and mileage accounts are not transferable to
                  third parties. The sale, exchange, offering for auction or any
                  other transfer of W Points to a third party is prohibited.
                  Negotiating for the purchase or sale of W Points, the purchase
                  of W Points from members or a third party, and the
                  unauthorised use of W Points are also prohibited. Any
                  provisions to the contrary will be explicitly published on the
                  CLUB.WELTKERN® page (club.weltkern.com).
                </p>

                <p className="text text--small">
                  2.2 Account balance
                  <br />
                  Members can check the current balance in their mileage account
                  via their personal online mileage account by logging into
                  their WELTKERN® account. In addition, members who actively use
                  CLUB.WELTKERN® to a specified extent will receive regular
                  information about their current account balance, providing the
                  member has registered to receive CLUB.WELTKERN® newsletters.
                  The sending of this information may be discontinued should
                  CLUB.WELTKERN® generally provide access to the mileage account
                  overview exclusively in the online mileage account. Duplicate
                  or replacement account statements cannot be provided.
                </p>

                <p className="text text--small">
                  2.3 Earning W Points
                  <br />
                  2.3.1 General conditions of earning W Points
                  <br />
                  It is possible to earn W Points as soon as membership
                  commences. W Points can only be credited to the member’s
                  individual mileage account for services the member has used
                  personally. According to the procedure in paragraph 2.3.2, W
                  Points can be credited once the order is placed and confirmed
                  and/or after validation of the action that allows to
                  accumulate points. Members can also earn W Points for services
                  provided by certain CLUB.WELTKERN® partners.
                </p>

                <p className="text text--small">
                  2.3.2 Crediting procedure
                  <br />
                  On purchases or actions made on www.weltkern.com, W points are
                  automatically credited to the member’s account once the order
                  is placed and confirmed and/or after validation of the action
                  that allows to accumulate points.
                </p>

                <p className="text text--small">
                  W Points not automatically credited can be credited to the
                  member’s account within six months after the service has been
                  used. In this case, the member must provide CLUB.WELTKERN with
                  complete documentation (e.g. original email confirmation,
                  invoice…). Letters and documents sent to CLUB.WELTKERN® are
                  scanned, digitally archived and are then destroyed after being
                  scanned. There is no entitlement to the return of the original
                  letters and documents submitted.
                </p>
                <p className="text text--small">
                  If a mileage credit is made despite there being a reason for
                  an exclusion or a credit has been wrongly issued for other
                  reasons or there has been a misuse by the member, CLUB.WK®
                  reserve the right to transfer back or withhold the relevant
                  credit.
                </p>
                <p className="text text--small">
                  2.4 Redeeming W Points
                  <br />
                  2.4.1 General provisions for redeeming W Points
                  <br />
                  Members may redeem their W Points Prime for awards as soon as
                  their mileage account has a sufficient balance. Award offers
                  and the number of W Points Prime required in each case will be
                  published next to the product or service on WELTKERN®. W
                  Points Status cannot be redeemed for awards. W Points cannot
                  be converted into cash to be paid out to the member.
                </p>

                <p className="text text--small">
                  2.4.4 Other awards
                  <br />
                  Other awards, the terms and conditions of use and other
                  possible uses for W Points will be published separately in the
                  current CLUB.WELTKERN® page.
                </p>

                <p className="text text--small">
                  2.4.6 Availability and special conditions for awards
                  <br />
                  The availability of awards may vary. Individual awards may not
                  be available at certain times. Awards cannot be combined with
                  certain discounted products/services.
                </p>

                <p className="text text--small">
                  2.4.7 Improper use
                  <br />A member is acting abusively within the meaning of these
                  Terms and conditions of participation if by their actions they
                  fulfil the conditions within paragraph 2.1.3 (Transfer/trading
                  of W Points).
                </p>

                <p className="text text--small">
                  2.5 Expiry of W Points Prime
                  <br />
                  If W Points Prime are not redeemed for an award in the mileage
                  account within 36 months of the event (start of the flight in
                  question, start of the hotel stay, start of car rental etc.),
                  they expire by the end of the next quarter, unless a longer
                  deadline is announced through the CLUB.WELTKERN® communication
                  channels.
                </p>

                <p className="text text--small">
                  The date and amount of the expired W Points will be indicated
                  separately in the CLUB.WELTKERN® account information or in the
                  member’s individual online mileage account at least one
                  quarter before the expiry date concerned.
                </p>

                <p className="text text--small">
                  2.6 Taxes, surcharges and service charges
                  <br />
                  All additional costs such as taxes, fees, shipping, surcharges
                  must be borne by the member and will be billed via their
                  credit card. Information about the amount of taxes, fees,
                  surcharges and any service charges due can be obtained from
                  the CLUB.WELTKERN® Support.
                </p>

                <p className="text text--small">
                  3 Breach of Terms and conditions of participation, account
                  blocking and termination of the agreement
                  <br />
                  3.1 Termination, blocking and exclusion from programme
                  membership
                  <br />
                  3.1.1 Ordinary cancellation
                  <br />
                  The member may duly terminate the contractual relationship in
                  writing at any time without observing a notice period.
                </p>

                <p className="text text--small">
                  4 Other
                  <br />
                  4.1 Liability
                  <br />
                  The following applies to loss or damage incurred by members in
                  connection with their membership which is caused by an
                  operator, a joint operator or their respective agents: in the
                  case of intentional or gross negligence and if a guarantee
                  exists, the liability is unlimited. Liability in the event of
                  injury to life, body or health is also unlimited in cases of
                  minor negligence. In the event of a minor negligent breach of
                  essential contractual duties, liability is limited to the
                  property damage and financial loss attributable to this to the
                  extent of the foreseeable loss or damage as it typically
                  occurs. A fundamental contractual obligation is the obligation
                  which must be met for the correct execution of the agreement
                  where compliance can be relied upon by the member as a rule.
                  Any further liability for compensation is excluded.
                </p>

                <p className="text text--small">
                  4.2 Data protection
                  <br />
                  The personal details relating to the member’s membership will
                  only be collected, processed and used for the operation of
                  CLUB.WELTKERN®. More information about the operation of the
                  CLUB.WELTKERN® programme and the collection of data can be
                  found at www.weltkern.com/support
                </p>
                <p className="text text--small">
                  These details include the data provided by the member at
                  registration and during their membership (“master data”), as
                  well as data coming in to the operators, joint operators or
                  CLUB.WELTKERN® partner companies in connection with their
                  membership (particularly with earning and redeeming W Points)
                  (“programme data”).
                </p>

                <p className="text text--small">
                  Personal data may be forwarded to joint operators for the
                  administration, further development and marketing of
                  CLUB.WELTKERN® as their own programme, if
                </p>

                <p className="text text--small">As at 03/2021</p>
              </div>
            </div>
            <div className="informations__keyboard col-md-12">
              <div className="form-check-conditions">
                <input
                  onClick={() => {
                    this.setState({
                      acceptCondition: this.state.acceptCondition
                        ? false
                        : true,
                    });
                  }}
                  type="checkbox"
                  name="accept_conditons"
                  id="accept_conditons"
                />
                <label for="accept_conditons">I agree</label>
              </div>
            </div>
          </div>
        </div>
        <div className="clearboth register__confirmation">
          <div className="col-md-12 float-right">
            <div className="informations__keyboard col-md-12">
              <div className="form-wrapper form__submit">
                <button
                  color="secondary"
                  class="float-left"
                  variant="contained"
                  onClick={this.back}
                >
                  Back
                </button>
                <button
                  disabled={!this.state.acceptCondition}
                  type="submit"
                  className={`btnForm1 float-right ${
                    !this.state.acceptCondition && "btnRegister"
                  }`}
                >
                  Create my account
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Conditions;
