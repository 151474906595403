import {PAGES_LOADING, GET_PAGES, FAQ_LOADING, GET_FAQS} from '../actions/types'

const initialState = { 
    pageData: null,
    loading: false,
    faqData: null,
    faqLoading: false
}

export default function (state= initialState, action)  {
    switch (action.type) {
        case PAGES_LOADING:
            return {
                ...state,
                loading: true
            };
        case GET_PAGES:
            return {
                ...state,
                pageData: action.payload,
                loading: false
            };
        case FAQ_LOADING:
            return {
                ...state,
                faqLoading: true
            };
        case GET_FAQS:
            return {
                ...state,
                faqData: action.payload,
                faqLoading: false
            };
        default: 
           return state
    }

}