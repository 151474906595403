import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Spinner from '../../utils/Spinner';
import { getCustomer } from '../../../actions/userActions'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';

class MenuDetail extends Component {
	constructor() {
		super()
		this.state = {

		}
	}
	componentDidMount() {
		this.props.getCustomer()
	}

	render() {
		const { users } = this.props;
		let displayUser;

		
			displayUser = (
				<div className="col-md-9 float-left">
					<div className="name UserInfos"><NavLink to="/support">Support</NavLink></div>
				</div>
			)
		
		return (
			displayUser
		)
	}
}
MenuDetail.propTypes = {
	getCustomer: PropTypes.func.isRequired,
}
const mapStateToprops = (state) => ({
	users: state.users
})

export default connect(mapStateToprops, { getCustomer })(MenuDetail) 