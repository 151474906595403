import { GET_CUSTOMER } from './types'
import API, { subUrl } from '../utils/api'
export const getCustomer = () => dispatch => {
	API
		.get(subUrl.customer)
		.then(res => {
			dispatch({
				type: GET_CUSTOMER,
				payload: res.data
			})
		}
		)
		.catch(error => console.log(error))
}