import {TOGGLE_FAVORITE, FAVORITES_LOADING } from './types'
import API, {subUrl} from '../utils/api'

export const getFavorite = () => dispatch => {
    dispatch(setFavoritesLoading());
    API
        .get(subUrl.getFavorit)
        .then(res =>
            {
                dispatch({
                type: TOGGLE_FAVORITE,
                payload: res.data
            })
        }
        )
        .catch(error => console.log(error))

};

export const setFavoritesLoading = () => {
    return {
        type:  FAVORITES_LOADING
    };
};
