import React, { useEffect, useState } from "react";
import HeaderIndex from "../header/Index";
import API, { subUrl } from "../../../utils/api";
import Moment from "react-moment";
import Cart from "../../_includes/lastProduits/Cart";
import User from "../login/Login";
import WKch from "../../../assets/images/WK_ch.svg";
import WKeu from "../../../assets/images/WK_eu.svg";
import WKworld from "../../../assets/images/WK_world.svg";
import $ from "jquery";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

const now = () => {
  const newDate = new Date();
  return newDate;
};

const DetailProduct = (props, { title }) => {
  const estimation = (date) => {
    const newDate = new Date();
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    newDate.setDate(newDate.getDate() + parseInt(date));
    return newDate;
  };

  const [order, SetOrder] = useState(null);
  const id = props.match.params.id;
  useEffect(() => {
    API.get(subUrl.getOrder + "/" + id)
      .then((res) =>
      res && res.data &&
       SetOrder(res.data)
       )
      .catch((error) => alert(error.response.data.message));
  },[id]);
  console.log(order)

  $(".price-virgule").text(function () {
    return $(this).text().replace(".", ",");
  });

  $("header").addClass("Black");

  return (
    <>
      <Helmet>
        <title>Order details – WK®</title>
        <meta name="Chechout" content="Page Chechout de welt." />
      </Helmet>
      <HeaderIndex />
      <div
        className="col-md-12 list-account topboxdetail"
        id="historique_des_transactions"
      >
        <div class="back-details"><a class="back-inventory" href="/account/inventory"><p>Back</p></a></div>
        <div className="top-list detail col-md-10 container-center">
        
          <div class="col-lg-3 firstChild"></div>
          <div class="col-lg-3">
            <span>PRODUCT(S)</span>
          </div>
          <div class="col-lg-3">
            <span>INFORMATIONS </span>
          </div>
          <div class="col-lg-3">
            <span>QUANTITY / PRICE / BALANCE</span>
          </div>
        </div>
        <div className="content page-view-more col-md-10 container-center detail">
          {order &&
            order.line_items &&
            order.line_items.map((items) => {
              console.log("hhhhh",items)
              return (
                <>
                  <div className="bloc-view-all">
                    <div className="img-view-more col-md-2-2">
                      <Link refresh="true"
                        className="img-l-full"
                        to={{
                          pathname: items.product_type == "Typeface" ? `/shop/detail-typeface/${items.slug}` : `/shop/detail-product/${items.slug}`,
                          items
                        }}>
                      <img
                        className="vignette"
                        src={items.galery && items.galery != false && items.galery != null ? items.galery : items.image.url}
                        alt="imgwelt"
                      />
                      </Link>
                    </div>
                    {
                      window.innerWidth > 768 ?
                      <div className="col-md-10 epCfKl">
                      <div className="col-md-6 leftside">
                        <div className="product__name col-md-12">
                          <h3
                            className="name"
                            dangerouslySetInnerHTML={{ __html: items.name }}
                          ></h3>
                        </div>
                        <div className="product__details nom-prix-statut col-md-12 bottom--left">
                          <div className="prix-statut">
                            <div className="reward col-md-3">
                              <p className="text text--xsmall">Reward</p>
                            </div>
                            <div className="statut col-md-6">
                              <div className="col-md-12">
                                <span>Prime</span>
                                <span className="money">+{items.points.prime}W</span>
                              </div>
                              <div className="col-md-12 colorStatut">
                                <span>Status</span>
                                <span className="money">+{items.points.status}W</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="rightside view-more  col-md-6">
                        <div className="product__infos informations col-md-6">
                          {items.product_type == "Physical" &&
                          <div className="col-md-12 delivery">
                            <div class="delivery__national delivery2">
                              <div className="icon-pays">
                              {items.estimation_de_livraison && items.estimation_de_livraison.length > 0 && items.estimation_de_livraison.map((pays) => {
                                return(
                                pays === "Switzerland" ? 
                                  <div>
                                    <div class="icon">
                                      <img src={WKch} />
                                    </div>

                                    <p className="dateCreate">
                                      {/* <Moment format="MMM DD YYYY">
                                      {items.date_created}
                                    </Moment> */}
                                      <Moment format="MMM DD">{now()}</Moment>-
                                      <Moment format="MMM DD">
                                        {estimation(
                                          pays.nombre_de_jours
                                        )}
                                      </Moment>
                                    </p>
                                  </div>
                                 : 
                                pays === "Europe" ? 
                                  <div>
                                    <div class="icon">
                                      <img src={WKeu} />
                                    </div>
                                    <p className="dateCreate">
                                      <Moment format="MMM DD">{now()}</Moment>-
                                      <Moment format="MMM DD">
                                        {estimation(
                                          pays.nombre_de_jours
                                        )}
                                      </Moment>
                                    </p>
                                  </div>
                                :

                                pays === "Other" ? 
                                  <div>
                                    <div class="icon">
                                      <img src={WKworld} />
                                    </div>
                                    <p className="dateCreate">
                                      <Moment format="MMM DD">{now()}</Moment>-
                                      <Moment format="MMM DD">
                                        {estimation(
                                          pays.nombre_de_jours
                                        )}
                                      </Moment>
                                    </p>
                                  </div>
                                 : 
                                  "")
                                

                              })
                              }
                               
                              </div>
                            </div>
                          </div>
                          }
                        </div>

                        <div className="product__qty-price-balance col-md-6">
                          <div className="product__qty qty">
                            <p class="text text--xsmall pi">{items.quantity}</p>
                          </div>
                          <div className="product__price col-md-12 bottom--right">
                            <div className="price prix col-md-6">
                              <sup class="text text--small">{order.currency}</sup>
                              <p class="price-virgule">{parseFloat(items.subtotal - (parseFloat(items.paid_point) /100)).toFixed(2)}</p>
                            </div>
                            <div class="price--welt prix-total col-md-6">
                              <span>+{items.paid_point}W</span>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>:
                    <div className="col-md-10 epCfKl">
                      <div className="product__name col-md-12">
                        <h3
                          className="name"
                          dangerouslySetInnerHTML={{ __html: items.name }}
                        ></h3>
                      </div>
                      <div className="reward_container">
                        <span>Reward</span>
                        <div className="product__qty qty">
                          <p class="text pi">{items.quantity}x</p>
                        </div>
                        <div className="price prix reward_price">
                          <sup class="text text--small">{order.currency}</sup>
                          <p class="price-virgule">{parseFloat(items.subtotal - (parseFloat(items.paid_point) /100)).toFixed(2)}</p>
                        </div>
                      </div>
                      <div className="reward_container">
                        <div>
                          <span>Prime</span>
                          <span className="money colorStatut">+{items.points.prime}W</span>
                        </div>
                        <div>
                          <span>Status</span>
                          <span className="money colorStatut">+{items.points.status}W</span>
                        </div>
                        <div class="price--welt prix-total">
                          <span>+{items.paid_point}W</span>
                        </div>
                      </div>
                    </div>
                  
                    }
                </div>
                  
                </>
              );
            })}
            {order && order.bonus != 0 &&
                  <div className="list col-md-12">
                    <div className="col-md-2-2">
                      <p>Bonus</p>
                    </div>
                    <div className="col-md-10 float-right align-right flex">
                      <div className="col-md-6">
                        <div className="col-md-12">
                          <div className="col-md-9">
                            <p>+{order.bonus}W</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <img src="/images/checked.svg" />
                      </div>
                    </div>
                  </div>
                  }
                  {order && order.shipping != 0 &&
                  <div className="list col-md-12">
                    <div className="col-md-2-2">
                      <p>Delivery</p>
                    </div>
                    <div className="col-md-10 float-right align-right flex">
                      <div className="col-md-6">
                        <div className="col-md-12">
                          <div className="col-md-9">
                            <p>{order.currency} {parseFloat(order.shipping).toFixed(2)}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <img src="/images/checked.svg" />
                      </div>
                    </div>
                  </div>
                  }


                  <div className="total-view">
                    <div className="total-price">
                      <p>Total</p>
                      <p className="prix-chf">{order && order.currency}</p>
                      <p className="price-virgule prix">{order && order.total}</p>
                      <p>-{order && order.total_points}W</p>
                    </div>
                  </div>
        </div>
      </div>
      <Cart />
      <User />
    </>
  );
};
export default DetailProduct;
