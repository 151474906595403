
import { GET_PRODUCTS, GET_PRODUCTS_PAGE, ADD_PRODUCT_ON_PRODUCTS_PAGE, GET_PRODUCT_BY_ID, PRODUCT_LOADING } from './types'
import API, { subUrl } from '../utils/api'

let typeFaceId = 46
export const getProducts = (currentLang, currencyChecked) => dispatch => {
	dispatch(setProductsLoading());
	let lang = ''
	if (currentLang !== 'en') {
		lang = currentLang
	}
	API
		.get(subUrl.products + '?lang=' + lang + '&ccy=' + currencyChecked + '&amount=1000 &order=ASC &orderby=all')

		.then(res => {
			dispatch({
				type: GET_PRODUCTS,
				payload: res.data
			})
		}
		)
		.catch(error => console.log(error))
}
export const getProductsOnPage = (perPage, paramater, order, typeface, search, orderb, brand, people, price, welt, tag, spe) => dispatch => {
	console.log( perPage, paramater, order, typeface, search, orderb, brand, people, price, welt, tag, spe)
	if (order === undefined)
		order = ''
	if (orderb === undefined)
	orderb = 'desc'
	dispatch(setProductsLoading());
	console.log("jsonnnn", tag)
	if(price === undefined)
	price={min: 0, max: 0}
	if(welt === undefined)
	welt={min: 0, max: 0}
	console.log(price.min)
	
	if( search != undefined)
	API
		.get(subUrl.categories)
		.then(res => {
			if (order === undefined)
				order = ''
				
			typeFaceId = res.data.filter(categorie => categorie.slug === typeface)[0]?.id
			
			if(typeFaceId === undefined)
		API
		.get(subUrl.productsOnPage + perPage + '&page=' + paramater + '&orderby=' + order + "&search=" + search + "&order=desc" + '&brand=' + brand + '&people=' + people + '&pricemin=' + price.min + '&pricemax=' + price.max + '&weltmax='+ welt.max + '&weltmin='+ welt.min + '&tags='+ tag + '&specials='+ spe)
		.then(res => {
			dispatch({
				type: GET_PRODUCTS_PAGE,
				payload: res
			})
		}
		)
		.catch(error => console.log(error))
		else
		API
		.get(subUrl.productsOnPage + perPage + '&category=' + typeFaceId + "&search=" + search + '&page=' + paramater + '&orderby=' + order +  "&order=desc" + '&brand=' + brand + '&people=' + people + '&pricemin=' + price.min + '&pricemax=' + price.max + '&weltmax='+ welt.max + '&weltmin='+ welt.min + '&tags='+ tag + '&specials='+ spe)
		.then(res => {
			dispatch({
				type: GET_PRODUCTS_PAGE,
				payload: res
			})
		}
		)
		.catch(error => console.log(error))
		})
		.catch(error => console.log(error))
	/*  */
	else if( typeface == undefined || typeface == "Product")
	API
		.get(subUrl.categories)
		.then(res => {
			if (order === undefined)
				order = ''
				
			typeFaceId = res.data.filter(categorie => categorie.slug === typeface)[0]?.id
		if(search === undefined)
		search = ''
		if(typeFaceId === undefined)
		API
		.get(subUrl.productsOnPage + perPage + '&page=' + paramater + '&orderby=' + order + "&search=" + search + "&order=desc" + '&brand=' + brand + '&people=' + people + '&pricemin=' + price.min + '&pricemax=' + price.max + '&weltmax='+ welt.max + '&weltmin='+ welt.min + '&tags='+ tag + '&specials='+ spe)
		.then(res => {
			dispatch({
				type: GET_PRODUCTS_PAGE,
				payload: res
			})
		}
		)
		.catch(error => console.log(error))
		else
		API
		.get(subUrl.productsOnPage + perPage + '&category=' + typeFaceId + '&page=' + paramater + '&orderby=' + order + "&search=" + search + "&order=desc" + '&brand=' + brand + '&people=' + people + '&pricemin=' + price.min + '&pricemax=' + price.max + '&weltmax='+ welt.max + '&weltmin='+ welt.min + '&tags='+ tag + '&specials='+ spe)
		.then(res => {
			dispatch({
				type: GET_PRODUCTS_PAGE,
				payload: res
			})
		}
		)
		.catch(error => console.log(error))
		})
		.catch(error => console.log(error))
	
	else
	API
		.get(subUrl.categories)
		.then(res => {
			if (order === undefined)
				order = ''
			typeFaceId = res.data.filter(categorie => categorie.slug === typeface)[0].id
			API
				.get(subUrl.productsOnPage + perPage + '&page=' + paramater + '&category=' + typeFaceId + '&orderby=' + order+ "&order=" +orderb+ '&brand=' + brand + '&people=' + people + '&pricemin=' + price.min + '&pricemax=' + price.max + '&weltmax='+ welt.max + '&weltmin='+ welt.min + '&tags='+ tag + '&specials='+ spe)
				.then(res => {
					dispatch({
						type: GET_PRODUCTS_PAGE,
						payload: res
					})
				}
				)
		})
		.catch(error => console.log(error))
}
export const getOneMoreProduct = (products, perPage, paramater, order, typeface, search, orderb, brand, people, price, welt, tag, spe) => dispatch => {
	console.log(products, perPage, paramater, order, typeface, search, orderb, brand, people, price, welt, tag, spe)
	if (order === undefined)
		order = ''
	if (orderb === undefined)
	orderb = 'desc'
	dispatch(setProductsLoading());
	if(price === undefined)
	price={min: 0, max: 0}
	if(welt === undefined)
	welt={min: 0, max: 0}
	
	if( search != undefined)
	API
		.get(subUrl.productsOnPage + perPage + '&page=' + paramater + '&orderby=' + order + "&search=" + search + "&order=desc" + '&brand=' + brand + '&people=' + people + '&pricemin=' + price.min + '&pricemax=' + price.max + '&weltmax='+ welt.max + '&weltmin='+ welt.min + '&tags='+ tag + '&specials='+ spe)
		.then(res => {
			console.log("", res.data[0])
			res && res.data && res.data[0] ? res.data = [...products, res.data] : res.data = products;
			dispatch({
				type: ADD_PRODUCT_ON_PRODUCTS_PAGE,
				payload: res
			})
		}
		)
		.catch(error => console.log(error))
	else if( typeface == undefined || typeface == "Product")
	API
		.get(subUrl.productsOnPage + perPage + '&page=' + paramater + '&orderby=' + order+ "&order=" +orderb + '&brand=' + brand + '&people=' + people + '&pricemin=' + price.min + '&pricemax=' + price.max + '&weltmax='+ welt.max + '&weltmin='+ welt.min + '&tags='+ tag + '&specials='+ spe)
		.then(res => {
			console.log("", res.data)
			res && res.data && res.data[0] ? res.data = [...products, ...res.data] : res.data = products;
			console.log("resssssssssss",res)
			dispatch({
				type: ADD_PRODUCT_ON_PRODUCTS_PAGE,
				payload: res
			})
		}
		)
		.catch(error => console.log(error))
	else
	API
		.get(subUrl.categories)
		.then(res => {
			if (order === undefined)
				order = ''
			typeFaceId = res.data.filter(categorie => categorie.slug === typeface)[0].id
			API
				.get(subUrl.productsOnPage + perPage + '&page=' + paramater + '&category=' + typeFaceId + '&orderby=' + order+ "&order=" +orderb+ '&brand=' + brand + '&people=' + people + '&pricemin=' + price.min + '&pricemax=' + price.max + '&weltmax='+ welt.max + '&weltmin='+ welt.min + '&tags='+ tag + '&specials='+ spe)
				.then(res => {
					console.log("resssss", res.data)
					res && res.data && res.data[0] ? res.data = [...products, ...res.data] : res.data = products;
					dispatch({
						type: GET_PRODUCTS_PAGE,
						payload: res
					})
				}
				)
		})
		.catch(error => console.log(error))
}
export const getTypeFaceOnPage = (perPage, paramater, order) => dispatch => {
	API
		.get(subUrl.categories)
		.then(res => {
			if (order === undefined)
				order = ''
			typeFaceId = res.data.filter(categorie => categorie.slug === 'typefaces')[0].id
			API
				.get(subUrl.productsOnPage + perPage + '&page=' + paramater + '&category=' + typeFaceId + '&orderby=' + order)
				.then(res => {
					dispatch({
						type: GET_PRODUCTS_PAGE,
						payload: res
					})
				}
				)
		})
		.catch(error => console.log(error))
	dispatch(setProductsLoading());
}

export const getBookOnPage = (perPage, paramater, order) => dispatch => {
	API
		.get(subUrl.categories)
		.then(res => {
			if (order === undefined)
				order = ''
			typeFaceId = res.data.filter(categorie => categorie.slug === 'book')[0].id
			API
				.get(subUrl.productsOnPage + perPage + '&page=' + paramater + '&category=' + typeFaceId + '&orderby=' + order)
				.then(res => {
					dispatch({
						type: GET_PRODUCTS_PAGE,
						payload: res
					})
				}
				)
		})
		.catch(error => console.log(error))
	dispatch(setProductsLoading());
}

export const getFurnitureOnPage = (perPage, paramater, order) => dispatch => {
	API
		.get(subUrl.categories)
		.then(res => {
			if (order === undefined)
				order = ''
			typeFaceId = res.data.filter(categorie => categorie.slug === 'furnitures')[0].id
			API
				.get(subUrl.productsOnPage + perPage + '&page=' + paramater + '&category=' + typeFaceId + '&orderby=' + order)
				.then(res => {
					dispatch({
						type: GET_PRODUCTS_PAGE,
						payload: res
					})
				}
				)
		})
		.catch(error => console.log(error))
	dispatch(setProductsLoading());
}

export const getGoodiesOnPage = (perPage, paramater, order) => dispatch => {
	API
		.get(subUrl.categories)
		.then(res => {
			if (order === undefined)
				order = ''
			typeFaceId = res.data.filter(categorie => categorie.slug === 'goodies')[0].id
			API
				.get(subUrl.productsOnPage + perPage + '&page=' + paramater + '&category=' + typeFaceId + '&orderby=' + order)
				.then(res => {
					dispatch({
						type: GET_PRODUCTS_PAGE,
						payload: res
					})
				}
				)
		})
		.catch(error => console.log(error))
	dispatch(setProductsLoading());
}
export const getProductById = (slug, currentLang) => dispatch => {
	dispatch(setProductsLoading());
	let lang = ''
	if (currentLang !== 'en') {
		lang = currentLang
	}
	API
		.get(subUrl.products + '?slug=' + slug + '&lang=' + lang)
		.then(res => {
			dispatch({
				type: GET_PRODUCT_BY_ID,
				payload: res.data
			})
		})
		.catch(error => console.log(error))
}

export const setProductsLoading = () => {
	return {
		type: PRODUCT_LOADING
	};
};





