import React, { Component } from 'react';
import $ from 'jquery'
import { connect } from 'react-redux'
import { getProducts } from '../../../actions/productsActions'
import Autosuggest from 'react-autosuggest'
import { BrowserRouter as Link } from "react-router-dom";


class Recherche extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: '',
			suggestions: []
		};
	}
	componentDidMount() {
		const { currencies, currentLange } = this.props;
		const { currencyChecked } = currencies;
		const { language } = currentLange;
		//this.props.getProducts(language, currencyChecked.key);
	}
	escapeRegexCharacters = (str) => {
		return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
	}

	getSuggestions = (value) => {
		const { products } = this.props;
		const escapedValue = this.escapeRegexCharacters(value.trim());
		if (escapedValue === '') {
			return [];
		}

		const regex = new RegExp('\\b' + escapedValue, 'i');

		return products.products.filter(person => regex.test(this.getSuggestionValue(person)));
	}

	getSuggestionValue = (suggestion) => {
		return `${suggestion.title} ${suggestion.slug}`;
	}

	renderSuggestion = (suggestion) => {
		return (
			<div className="articles col-md-3 padding-l-r-4 in-Search" >
				<div className="img-l-full">
					<Link to={{
						// pathname: `/shop/detail-product/${suggestion.id}`,
						pathname: suggestion.typeface ? `/shop/detail-typeface/${suggestion.slug}` : `/shop/detail-product/${suggestion.slug}`,
					}}
					>
						<img src={suggestion.featured_image.url} alt={suggestion.featured_image.alt} />
					</Link>
				</div>
				<div className="description">
					<div className="category-price">
						<div className="category">{suggestion.categories[0].name}</div>
						<div className="price">CHF {suggestion.price}</div>
					</div>
					<div className="category-price">
						<div className="category">{suggestion.name}</div>
						<div className="price">{suggestion.welt}W</div>
					</div>
				</div>
			</div>
		)
	}

	onChange = (event, { newValue, method }) => {
		this.setState({
			value: newValue
		});
	};

	onSuggestionsFetchRequested = ({ value }) => {
		this.setState({
			suggestions: this.getSuggestions(value)
		});
	};

	onSuggestionsClearRequested = () => {
		this.setState({
			suggestions: []
		});
	};
	cancel = () => {
		$(".popup-recherche").removeClass("open");
		return false;
	}
	render() {
		const { products } = this.props;
		let displayproduct;
		const { value, suggestions } = this.state;
		const inputProps = {
			placeholder: "Please, just ask…",
			value,
			onChange: this.onChange
		};
		if (this.state.suggestions.length > 0) {
			displayproduct = this.state.suggestions.map((product, i) => {
				return (
					<div className="articles col-md-3 padding-l-r-4 float-left" key={i}>
						<a className="lien-produits" />
						<div className="img-l-full">
							<img src={product.featured_image.url} alt={product.featured_image.alt} />
						</div>
						<div className="description">
							<div className="category-price">
								<div className="category">{product.categories[0].name}</div>
								<div className="price">CHF {product.variant? parseFloat(product.variant[0].price).toFixed(2) : product.price}</div>
							</div>
							<div className="category-price">
								<div className="category">{product.name.replace("<br>","")}</div>
								<div className="price">{product.welt}W</div>
							</div>
						</div>
					</div>
				)
			})
		}

		return (
			<>
				<div className="popup-recherche" id="popup-recherche">
					<img src="images/cancel-48.png" onClick={this.cancel} />
					<div className="col-md-10 container-center padding-t-50">
						<h2 className="title-big">How can we help you ?</h2>
						<form >
							<div className="clearfix">
								<Autosuggest
									suggestions={suggestions}
									onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
									onSuggestionsClearRequested={this.onSuggestionsClearRequested}
									renderSuggestion={this.renderSuggestion}
									inputProps={inputProps}
								/>
							</div>
						</form>
					</div>
					<div className="col-md-12 padding-t-b-l-r-8 overflow-h align-center result-search">

					</div>
				</div>

			</>
		)
	}
}
const mapStateToProps = (state) => {
	return ({
		cart: state.cart,
		products: state.products,
		favorites: state.favorites,
		currentProduct: state.currentProduct,
		currencies: state.currencies,
		currentLange: state.translation
	})
}
const mapDispatchToProps = (dispatch) => {
	return {
		addToCart: (item) => {
			dispatch({ type: 'ADD', payload: item })
		},
		removeFromCart: (item) => {
			dispatch({ type: 'REMOVE', payload: item })
		},
		getCurentProduct: (item) => {
			dispatch({ type: 'GET_CURRENT_PRODUCT', payload: item })
		},
		// getProducts: (language, currencyChecked) => dispatch(getProducts(language, currencyChecked)),

		toggleFavorite: (item) => {
			dispatch({ type: 'TOGGLE_FAVORITE', payload: item })
		},

	}
}
export default connect(mapStateToProps, mapDispatchToProps)(Recherche)