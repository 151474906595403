import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import $ from "jquery";
//import API, { subUrl } from "../../../utils/api";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class HeaderPage extends Component {
  constructor() {
    super();
    this.state = {
      id: null,
      type: "#",
    };
  }
  componentDidMount() {
    class Parallax {
      constructor(object) {
        this.object = object;
        this.offset = this.object.offset().top;
        this.object.css(
          "background-position-y",
          `${this.offset + $(window).scrollTop() / 4}px`
        );
      }
      parallax() {
        this.object.css(
          "background-position-y",
          `${this.offset + $(window).scrollTop() / 4}px`
        );
      }
    }

    var parallax = new Parallax($(".parallax"));
    $(window).scroll(function () {
      parallax.parallax();
    });

    // if(this.props.header && this.props.header !== undefined && this.props.header && this.props.header.link){
    // 	if(this.props.header.link.interne.post_type === "post"){
    // 		this.setState({id : this?.props?._produit?.slug})
    // 	}
    // 	else if(this.props.header.link.interne.post_type === "product"){
    // 		this.setState({id : this?.props?._produit?.slug})
    // 		this.setState({type : this?.props?._produit?.choice_product})
    // 	}
    // }
  }

  render() {
    let header = this.props.header;
    let scroll = this.props.scroll;
    let slug = "";
    let choice_product = "";
    let custom_url = true;
    let linkAcrtion = "";
    if (this?.props?.header?.external_url?.post_url) {
      slug = this?.props?.header?.external_url?.post_url?.post_name;
      choice_product = this?.props?.header?.external_url?.product_type;
      linkAcrtion =
        choice_product &&
        choice_product !== "#" &&
        choice_product !== null &&
        choice_product !== undefined &&
        choice_product === "Typeface"
          ? "/shop/detail-typeface/"
          : "/shop/detail-product/";
      custom_url = false;
    }
    console.log("action type", this.props.header);
    //linkAcrtion = choice_product && choice_product !== '#' && choice_product !== null && choice_product !== undefined && choice_product === "Typeface" ? '/shop/detail-typeface/' : '/shop/detail-product/'
    return (
      <div className="img-home-top">
        <div
          className="img parallax"
          style={{
            backgroundImage:
              header.header !== undefined
                ? `url(${header.header.background_image.url})`
                : 'url("images/img-home-welt.jpg")',
          }}
        >
          {header.header !== undefined && (
            <img
              src={header.header.background_image.url}
              alt="Welt"
              style={{ opacity: 0 }}
            />
          )}
          <div className="release-info d-flex-column-c here">
            <div className="container-center col-md-10">
              {header.header !== undefined ? (
                <h2>
                  {custom_url ? (
                    <a
                      style={{ color: header.header.title_color }}
                      href={header.external_url}
                      dangerouslySetInnerHTML={{ __html: header.header.title }}
                    ></a>
                  ) : (
                    <Link
                      style={{ color: header.header.title_color }}
                      to={{
                        pathname: /* header.link && header.link.interne.post_type === "post" ? `/newspaper/${slug}`: */ `${linkAcrtion}${slug}`,
                      }}
                      dangerouslySetInnerHTML={{ __html: header.header.title }}
                    ></Link>
                  )}
                </h2>
              ) : (
                <h2>
                  Moët x Weltanschauung <br />
                  Camouflage 2015
                </h2>
              )}
            </div>
          </div>
        </div>
        <div className="bottom-header">
          <div className="scroll-bottom">
            <a href="#0" className={scroll !== undefined && scroll}>
              <FormattedMessage
                id="home.Scroll"
                defaultMessage="SCROLL TO DISCOVER"
              />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderPage;
