import $ from 'jquery';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resetUser } from '../../../actions/authActions';
import Card from '../../_includes/lastProduits/Cart';
import HeaderIindex from '../header/Indexsanslogo';
import User from '../login/Login';


class ForgetPassword extends Component {
	constructor() {
		super();
		this.state = {
			email: ''
		}
		this.onChange = this.onChange.bind(this)
		this.onSubmit = this.onSubmit.bind(this)
	}
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	onSubmit = (e) => {
		e.preventDefault();
		const newUser = {
			username: this.state.email
		}
		this.props.resetUser(newUser)
		this.props.history.push('/');
	}
	componentDidMount(){
		$('html,body').animate({
      scrollTop: 0
    }, 'slow');
		$(".top-header-right").addClass("bgno");
		$(".cart,.account, .account-offine,.menu").on("click" ,function(){
				$(".top-header").toggleClass("dark");
		});
		
	}

	render() {
		return (
			<>
				<div className="reset-password">
					<HeaderIindex />
					<div className="simple-form">
					<div className="back" style={{marginTop: "5px"}}><a onClick={
					this.props.history.goBack}><p>Back</p></a></div>
						<div className="label">Reset password</div>
						<form onSubmit={this.onSubmit} className="identification">
							<div className="form-text">
								<input type="text" placeholder="Email" name="email" onChange={this.onChange} />
							</div>
							<div className="form-submit">
								<button type="submit" >Reset password</button>
							</div>
						</form>
					</div>
				</div>
				<Card />
				<User />
			</>
		);
	}
}

ForgetPassword.protoTypes = {
	resetUser: PropTypes.func.isRequired
}
const mapStateToProps = (state) => ({
	auth: state.auth,
})
export default connect(mapStateToProps, { resetUser })(ForgetPassword)